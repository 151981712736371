<template>
    <img :src="src" :class="iClass" :style="[iWidth ? `width: ${iWidth}` : '', iMaxHeight ? `max-height: ${iMaxHeight}` : ''].join('; ')" />
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import t from '@/tool';

import imgLoader from '@/assets/images/loader.svg';
import imgBroken from '@/assets/images/broken.svg';

export default {
    props: {
        iSrc: {
            type: String,
            require: true,
            default: null
        },
        iWidth: {
            type: String,
            default: null
        },
        iMaxHeight: {
            type: String,
            default: null
        },
        iClass: {
            type: String,
            default: ''
        },
        oSize: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            src: imgLoader
        };
    },
    watch: {
        iSrc() {
            this.refresh();
        }
    },
    created() {
        this.refresh();
    },
    methods: {
        refresh() {
            this.src = imgLoader;
            const image = new Image();
            if (this.oSize) {
                this.$delete(this.oSize, 'width');
                this.$delete(this.oSize, 'height');
            }
            image.onload = () => {
                this.src = this.iSrc;
                if (this.oSize) {
                    this.$set(this.oSize, 'width', image.width);
                    this.$set(this.oSize, 'height', image.height);
                }
            };
            image.onerror = () => {
                // console.log('NOK');
                this.src = imgBroken;
            };
            image.src = this.iSrc;
        }
    }
};
</script>
