import _ from 'lodash';
import dayjs from 'dayjs';

export default {
    state: {
        client: false,
    },
    mutations: {
        setClient: (state, payload) => {
            if (payload !== false && !_.isPlainObject(payload)) {
                return console.log('error setClient is not object');
            }
            state.client = payload;
        },
    },
    getters: {
        getClientID: (state) => () => state.client?.clientID ?? false,

        getClientPlanID: (state) => () => state.client?.meta?.plan ?? false,
        getClientNextPlanID: (state) => () => state.client?.meta?.nextPlan ?? null,
        getClientValidUntil: (state) => () => state.client?.meta?.validUntil ?? false,
        getClientMax: (state) => (max) => state.client?.meta?.[`max${_.upperFirst(max)}`] ?? 0,

        isClientValidUntil: (state) => () => !!state.client?.meta?.validUntil && +dayjs(state.client.meta.validUntil) > +dayjs(),
        isClientPlanCanceled: (state) => () => !!state.client?.meta?.planCanceledAt && +dayjs(state.client.meta.validUntil) > +dayjs(),
    },
};
