<template>
    <div>
        <b-modal id="modal-edit-subscription" :title="$t('components.modal-subscription.header-detail_subscription')" size="lg" hide-header-close>
            <b-form-group :label="$t('components.modal-subscription.label-client_name')">
                <b-form-input :value="_.get(form, '_client.clientName', '')" disabled />
            </b-form-group>

            <b-form-group :label="$t('components.modal-subscription.label-client_email')">
                <b-form-input :value="_.get(form, '_user.email', '')" disabled />
            </b-form-group>

            <hr />

            <b-form-group :label="$t('components.modal-subscription.label-stripe-email')">
                <b-form-input :value="_.get(form, '_client.meta.stripe.customer.email', '')" disabled />
            </b-form-group>

            <b-form-group :label="$t('components.modal-subscription.label-stripe-status')">
                <b-form-input :value="_.get(form, 'data.status', '')" disabled />
            </b-form-group>

            <b-form-group :label="$t('components.modal-subscription.label-stripe-created_at')">
                <b-form-input :value="(form.createdAt && getDateFormat(form.createdAt)) || ''" disabled />
            </b-form-group>

            <b-form-group :label="$t('components.modal-subscription.label-stripe-ended_at')">
                <b-form-input :value="(_.get(form, 'data.ended_at') && getDateFormat(form.data.ended_at * 1000)) || ''" disabled />
            </b-form-group>

            <b-form-group :label="$t('components.modal-subscription.label-stripe-current_period_start_at')">
                <b-form-input :value="(_.get(form, 'data.current_period_start') && getDateFormat(form.data.current_period_start * 1000)) || ''" disabled />
            </b-form-group>

            <b-form-group :label="$t('components.modal-subscription.label-stripe-current_period_end_at')">
                <b-form-input :value="(_.get(form, 'data.current_period_end') && getDateFormat(form.data.current_period_end * 1000)) || ''" disabled />
            </b-form-group>
            <hr />

            <b-form-group :label="$t('components.modal-subscription.label-stripe-plan_amount')">
                <b-form-input :value="(_.get(form, 'data.plan.amount') || 0) / 100" disabled />
            </b-form-group>
            <b-form-group :label="$t('components.modal-subscription.label-stripe-plan_interval')">
                <b-form-input :value="_.get(form, 'data.plan.interval') || ''" disabled />
            </b-form-group>

            <hr />

            <b-form-group :label="$t('components.modal-subscription.label-stripe-cancel_at_period_end')">
                <b-form-input :value="(_.get(form, 'data.cancel_at_period_end') && getDateFormat(form.data.cancel_at_period_end * 1000)) || ''" disabled />
            </b-form-group>

            <b-form-group :label="$t('components.modal-subscription.label-stripe-canceled_at')">
                <b-form-input :value="(_.get(form, 'data.canceled_at') && getDateFormat(form.data.canceled_at * 1000)) || ''" disabled />
            </b-form-group>

            <b-form-group :label="$t('components.modal-subscription.label-stripe-customer-id')">
                <b-form-input :value="_.get(form, 'data.customer')" disabled />
            </b-form-group>

            <b-form-group :label="$t('components.modal-subscription.label-stripe-subscription-id')">
                <b-form-input :value="form.subscriptionID" disabled />
            </b-form-group>

            <b-form-group :label="$t('components.modal-subscription.label-stripe-plan-id')">
                <b-form-input :value="_.get(form, 'data.plan.id')" disabled />
            </b-form-group>

            <b-form-group :label="$t('components.modal-subscription.label-stripe-product-id')">
                <b-form-input :value="_.get(form, 'data.plan.product')" disabled />
            </b-form-group>

            <!-- <div>
                <span
                    v-html="
                        JSON.stringify(form, null, 2)
                            .replace(/ /g, '&nbsp;')
                            .replace(/\n/g, '<br />')
                    "
                />
            </div> -->

            <template slot="modal-footer" slot-scope="{ cancel }">
                <b-button @click="cancel()">{{ $t('globals.button.cancel') }}</b-button>
                <!-- <b-button :disabled="processing || !isValidateEdit" variant="info" @click.prevent="editSubscription">
                    {{ $t('globals.button.save') }}
                    <i v-if="processing" class="fa fa-spinner fa-spin fa-fw ml-2" />
                </b-button> -->
            </template>
        </b-modal>

        <b-modal id="modal-cancel-subscription" :title="$t('globals.modal.warning')" hide-header-close>
            <p
                v-if="form._client"
                class="my-4"
                v-html="$t('components.modal-subscription.cancel-description-clientname', { clientName: `<b class=&quot;text-info&quot;>${_.get(form, '_client.clientName', '(no nane)')}</b>` })"
            />
            <p v-else class="my-4" v-html="$t('components.modal-subscription.cancel-description')" />
            <template slot="modal-footer" slot-scope="{ cancel }">
                <b-button @click="cancel()">{{ $t('components.modal-subscription.button-dont_cancel') }}</b-button>
                <b-button :disabled="processing" variant="danger" @click.prevent="cancelSubscription">
                    {{ $t('components.modal-subscription.button-cancel_subscription') }}
                    <i v-if="processing" class="fa fa-spinner fa-spin fa-fw ml-2" />
                </b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import moment from 'moment';

export default {
    data() {
        return {
            modal: false,
            processing: false,
            plans: this.$store.getters.getPlans().map((p) => {
                p.value = p.id;
                p.text = this.$t(`pages.pricing.plan-${p.id}`); // refresh i18n in the computed if it is different
                return p;
            }),
            durations: [
                { value: 'month', text: this.$t('pages.payment.checkbox-month') },
                { value: 'year', text: this.$t('pages.payment.checkbox-year') },
                { value: 'none', text: this.$t('pages.payment.checkbox-none') },
            ],
            form: {},
        };
    },

    methods: {
        getDateFormat: (date) => moment(date).format('YYYY.MM.DD HH:mm'),
        // buttonDisableSubscription(subscription) {
        //     const form = { ...subscription };
        //     form.disabledAt = !form.disabledAt;

        //     subscription._processing = true;
        //     axios
        //         .put(`/api/superadmin/subscription/${form.clientID}`, form)
        //         .then(resp => {
        //             subscription._processing = false;
        //             this.$bvModal.hide('modal-subscription');
        //             this.$emit('editedSubscription', resp.data.subscription);
        //         })
        //         .catch(e => {
        //             subscription._processing = false;
        //             this.$bvToast.toast(e.toString(), {
        //                 title: this.$t('globals.toast.error'),
        //                 variant: 'danger',
        //                 solid: true
        //             });
        //         });
        // },

        buttonEditSubscription(subscription) {
            this.form = { ...subscription };
            this.$bvModal.show('modal-edit-subscription');
        },

        buttonCancelSubscription(subscription) {
            this.form = { ...subscription };
            this.$bvModal.show('modal-cancel-subscription');
        },
        async cancelSubscription() {
            if (!_.get(this, 'form.subscriptionID')) {
                return;
            }
            if (!this.$store.getters.isMeRole('superadmin')) {
              return
            }
            this.processing = true;
            try {
                // const resp = await axios.delete(`/api${this.$store.getters.isMeRole('superadmin') ? `/superadmin` : '/stripe'}/subscription/${this.form.subscriptionID}`);
                const resp = await axios.post(`/api/superadmin/client/${this.form._client.clientID}/subscription/cancel`);
                this.$bvModal.hide('modal-cancel-subscription');
                this.$emit('canceledSubscription', resp.data.subscription);
            } catch (e) {
                this.$bvToast.toast(e.toString(), {
                    title: this.$t('globals.toast.error'),
                    variant: 'danger',
                    solid: true,
                });
            }
            this.processing = false;
        },
    },
};
</script>
