<template>
    <img :src="src" :class="iClass" :style="[iWidth ? `width: ${iWidth}` : '', iMaxHeight ? `max-height: ${iMaxHeight}` : ''].join('; ')" />
</template>

<script>
// import _ from 'lodash';
// import axios from 'axios';
// import t from '@/tool';

import 'intersection-observer';

import imgLazy from '@/assets/images/lazy.svg';
import imgLoader from '@/assets/images/loader.svg';
import imgBroken from '@/assets/images/broken.svg';

export default {
    props: {
        iSrc: {
            type: String,
            require: true,
            default: null
        },
        iWidth: {
            type: String,
            default: null
        },
        iMaxHeight: {
            type: String,
            default: null
        },
        iClass: {
            type: String,
            default: ''
        },
        oSize: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            src: imgLazy,
            observer: null
        };
    },
    watch: {
        iSrc() {
            this.reload();
        }
    },
    mounted() {
        // console.log('mount', this.observer);
        this.reload();
    },
    destroyed() {
        if (this.observer) {
            this.observer.disconnect();
        }
    },
    methods: {
        reload() {
            if (this.observer) {
                this.observer.disconnect();
            }
            // if ('IntersectionObserver' in window) {
            //     console.log('no IntersectionObserver');
            //     return;
            // }
            this.observer = new IntersectionObserver(entries => {
                const image = entries[0];
                // console.log('isIntersecting', image);
                if (image.isIntersecting) {
                    this.observer.disconnect();
                    this.refresh();
                }
            });
            this.observer.observe(this.$el);
        },
        refresh() {
            this.src = imgLoader;
            const image = new Image();
            if (this.oSize) {
                this.$delete(this.oSize, 'width');
                this.$delete(this.oSize, 'height');
            }
            image.onload = () => {
                this.src = this.iSrc;
                if (this.oSize) {
                    this.$set(this.oSize, 'width', image.width);
                    this.$set(this.oSize, 'height', image.height);
                }
            };
            image.onerror = () => {
                // console.log('NOK');
                this.src = imgBroken;
            };
            image.src = this.iSrc;
        }
    }
};
</script>
