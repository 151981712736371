<template>
    <div>
        <h1 class="mt-4">{{ $t('pages.projects.title') }}</h1>

        <div v-if="loading" class="mb-3 mt-4 oggy-loading">
            <b-button>
                {{ $t('globals.button.loading') }}
                <i class="fa fa-spinner fa-spin fa-fw ml-2" />
            </b-button>
        </div>

        <div v-if="!loading" class="mb-3">
            <b-row class="mb-2 mt-4">
                <b-col cols="9">
                    <span
                        v-b-tooltip.top
                        class="d-inline-block"
                        :title="isRequireVerifyMail ? $t('pages.projects.tooltip-noverification') : isNoLongerValid || isLimitProject ? $t('pages.projects.tooltip-limited') : ''"
                    >
                        <b-button
                            v-if="$store.getters.isMeRole(['admin', 'user'])"
                            :disabled="isRequireVerify"
                            variant="primary"
                            class="ml-2"
                            @click="$refs.modalProject.buttonAddProject({ check: true, start: true })"
                        >
                            <i class="fa fa-plus mr-2" />
                            {{ $t('pages.projects.button-add_project') }}
                        </b-button>
                    </span>
                </b-col>
                <b-col cols="3">
                    <b-input-group class="oggy-search mb-3">
                        <b-form-input v-model="search" trim :placeholder="$t('globals.input.placeholder-search')" />
                        <b-input-group-append>
                            <b-input-group-text><i class="fa fa-search text-secondary" /></b-input-group-text>
                        </b-input-group-append>
                    </b-input-group>
                </b-col>
            </b-row>

            <b-pagination
                v-if="projects.length > perPage"
                v-model="curPage"
                class="oggy-pagination"
                :total-rows="filteredProjects.length"
                :per-page="perPage"
                align="center"
                :first-text="$t('globals.pagination.first')"
                :prev-text="$t('globals.pagination.prev')"
                :next-text="$t('globals.pagination.next')"
                :last-text="$t('globals.pagination.last')"
                limit="10"
            />

            <b-table class="oggy-table" fixed sort-icon-left :per-page="perPage" :current-page="curPage" :items="filteredProjects" :fields="fields">
                <template v-slot:head(projectName)>
                    {{ $t('pages.projects.th-project_name') }}
                </template>
                <template v-slot:cell(projectName)="p">
                    <div class="text-truncate">
                        <div v-if="isRequireVerifyMail || isNoLongerValid || p.item._index >= limitProject">
                            <span class="opacity-50" :class="{ 'text-strike': p.item._index >= limitProject }">
                                {{ p.item.projectName }}
                            </span>
                        </div>
                        <router-link v-else :to="`/project/${p.item.projectID}/dashboard`">
                            {{ p.item.projectName }}
                            <!--<i class="fa fa-arrow-circle-o-right ml-2" />-->
                        </router-link>
                    </div>
                </template>

                <template v-slot:head(sitemap)>
                    {{ $t('pages.projects.th-sitemap') }}
                </template>
                <template v-slot:cell(sitemap)="p">
                    <div class="text-truncate">
                        {{ p.item.sitemap }}
                    </div>
                </template>

                <template v-slot:head(crawler)>
                    {{ $t('pages.projects.th-crawler') }}
                </template>

                <template v-slot:head(status)>
                    <div class="text-right">
                        {{ $t('pages.projects.th-status') }}
                    </div>
                </template>
                <template v-slot:cell(status)="p">
                    <div class="text-right">
                        <div v-if="p.item._statuses" class="small">
                            <div v-if="p.item._statuses.ready">Ready: {{ p.item._statuses.ready }}</div>
                            <div v-if="p.item._statuses.wait">Wait: {{ p.item._statuses.wait }}</div>
                            <div v-if="p.item._statuses.error">Error: {{ p.item._statuses.error }}</div>
                        </div>
                    </div>
                </template>

                <template v-slot:head(actions)>
                    <div class="text-right">
                        {{ $t('pages.projects.th-actions') }}
                    </div>
                </template>
                <template v-slot:cell(actions)="p">
                    <div class="text-right">
                        <div v-if="_.get(p.item, '_statuses.processing')">
                            <b-progress v-if="!p.item._statuses.max" height="5px" class="mt-2 progress-begin" variant="secondary" :value="100" :max="100" animated />
                            <b-progress v-if="p.item._statuses.max" height="5px" class="mt-2" variant="secondary" :value="p.item._statuses.value" :max="p.item._statuses.max" animated />
                        </div>
                        <div v-else>
                            <b-button
                                class="ml-1"
                                variant="text"
                                :disabled="isRequireVerifyMail || isNoLongerValid || p.item._index >= limitProject"
                                @click="$refs.modalProject.buttonEditProject({ project: p.item, check: true })"
                            >
                                <i class="fa fa-edit" />
                            </b-button>
                            <b-button :disabled="isRequireVerifyMail" class="ml-1" variant="text" @click="$refs.modalProject.buttonDeleteProject({ project: p.item })">
                                <i class="fa fa-trash" />
                            </b-button>
                        </div>
                    </div>
                </template>
            </b-table>

            <b-pagination
                v-if="projects.length > perPage"
                v-model="curPage"
                class="oggy-pagination"
                :total-rows="filteredProjects.length"
                :per-page="perPage"
                align="center"
                :first-text="$t('globals.pagination.first')"
                :prev-text="$t('globals.pagination.prev')"
                :next-text="$t('globals.pagination.next')"
                :last-text="$t('globals.pagination.last')"
                limit="10"
            />
        </div>

        <ModalProject ref="modalProject" @editedProject="onEditedProject" @addedProject="onAddedProject" @deletedProject="onDeletedProject" />
    </div>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import t from '@/tool';

import ModalProject from '@/components/modalProject.vue';

export default {
    components: {
        ModalProject
    },
    data() {
        return {
            loading: false,
            statusing: false,
            timeout: null,
            timeInterval: 30,
            statuses: {},
            search: '',
            curPage: 1,
            perPage: 20,
            fields: [
                { key: 'projectName', sortable: true, sortDirection: 'asc' },
                { key: 'sitemap', sortable: true, sortDirection: 'asc' },
                { key: 'crawler', sortable: true, sortDirection: 'asc', thStyle: 'width: 6rem' }
            ],
            projects: []
        };
    },
    computed: {
        limitProject() {
            return this.$store.getters.getClientMax('projects');
        },
        isLimitProject() {
            return this.limitProject <= this.projects.length;
        },
        isNoLongerValid() {
            return !this.$store.getters.isClientValidUntil();
        },
        isRequireVerifyMail() {
            return this.$store.getters.isMeRequireVerify();
        },
        isRequireVerify() {
            return this.isRequireVerifyMail || this.isNoLongerValid || this.isLimitProject;
        },
        filteredProjects() {
            const search = t.replaceSearch(this.search);
            return this.projects.filter(p => `${p.projectName || ''}\t${p.sitemap || ''}`.match(search));
        }
    },
    created() {
        if (this.$store.getters.isMeRole(['admin'])) {
            this.fields.push({ key: 'status', thStyle: 'width: 9rem' });
        }
        if (this.$store.getters.isMeRole(['admin', 'user'])) {
            this.fields.push({ key: 'actions', thStyle: 'width: 9rem' });
        }
        this.loading = true;
        axios.get(`/api/projects`).then(
            response => {
                this.loading = false;
                this.projects = response.data.projects;
                this.refreshIndex();
                this.loading2 = true;
                this.progressStatus();
            },
            () => {
                this.loading = false;
            }
        );

        this.timeout = setInterval(() => {
            if (--this.timeInterval === 0) {
                this.progressStatus();
            }
        }, 500);
    },
    destroyed() {
        if (this.timeout) {
            clearInterval(this.timeout);
            this.timeout = null;
        }
    },
    methods: {
        refreshIndex() {
            this.projects
                .sort((a, b) => (a.projectID > b.projectID ? 1 : -1))
                .map((p, i) => {
                    p._index = i;
                });
        },

        buttonGoHome() {
            this.$router.push('/');
        },

        progressStatus() {
            if (!this.timeout) {
                return;
            }
            if (this.statusing) {
                this.timeInterval = 2;
                return;
            }
            this.statusing = true;
            axios.post(`/api/statuses`).then(
                resp => {
                    this.statusing = false;
                    this.loading2 = false;
                    this.timeInterval = 30;
                    const statuses = _.get(resp, 'data.statuses', {});
                    _.each(statuses, p => {
                        p.count = _.get(p, 'count', {});
                        _.unset(p.count, 'deleted');
                        p.ready = _.get(p.count, 'ready', 0);
                        p.wait = _.max(_.get(p.count, 'wait', 0), 0);
                        p.max = 0;
                        p.error = 0;
                        _.each(p.count, (s, status) => {
                            p.max += s;
                            if (_.indexOf(['ready', 'wait', 'limit', 'stop', 'deleted'], status) === -1) {
                                p.error += s;
                            }
                        });
                        p.value = p.ready;
                    });
                    _.each(this.projects, p => {
                        if (statuses[p.projectID]) {
                            this.$set(p, '_statuses', statuses[p.projectID]);
                            if (statuses[p.projectID].processing) {
                                this.timeInterval = 2;
                            }
                        } else {
                            this.$delete(p, '_statuses');
                        }
                    });
                },
                err => {
                    console.log('err', err);
                    this.statusing = false;
                    this.timeInterval = 5;
                }
            );
        },

        onAddedProject(project) {
            this.projects.push({ ...project });
            this.refreshIndex();
            this.$router.push(`/project/${project.projectID}/sites`);
        },

        onEditedProject(project) {
            const index = _.findIndex(this.projects, { projectID: project.projectID });
            this.$set(this.projects, index, { ...this.projects[index], ...project });
        },

        onDeletedProject(project) {
            this.$delete(this.projects, _.findIndex(this.projects, { projectID: project.projectID }));
            this.refreshIndex();
        }
    }
};
</script>
