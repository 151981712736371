<template>
    <div>
        <h1 class="mt-4">
            {{ _.get(project, 'projectName', '...') }}
        </h1>

        <div v-if="status.loading" class="mb-3 mt-4 oggy-loading">
            <b-button>
                {{ $t('globals.button.loading') }}
                <i class="fa fa-spinner fa-spin fa-fw ml-2" />
            </b-button>
        </div>

        <div v-if="!status.loading" class="mb-3">
            <b-progress v-if="pagescan.max" height="5px" class="mb-2" :max="pagescan.max" :animated="!status.finished">
                <b-progress-bar :value="pagescan.ready" :variant="status.finished ? 'primary' : 'secondary'" />
                <b-progress-bar :value="pagescan.error" :variant="status.finished ? 'danger' : 'danger'" />
            </b-progress>

            <b-progress v-if="!pagescan.max && status.processing" height="5px" class="mb-2 progress-begin" variant="secondary" :value="100" :max="100" animated />
            <div v-if="!pagescan.max && !status.processing" style="padding-top: 5px;" class="mb-2" />

            <b-row class="mb-2">
                <b-col cols="9">
                    <b-button
                        v-if="$store.getters.isMeRole(['admin', 'user'])"
                        variant="primary"
                        :disabled="status.loading2 || status.processing"
                        @click="$refs.modalProject.buttonEditProject({ project, check: true })"
                    >
                        <i class="fa fa-edit mr-2" />
                        {{ $t('pages.sites.button-edit') }}
                    </b-button>
                    <b-button
                        v-if="$store.getters.isMeRole(['admin', 'user'])"
                        variant="info"
                        class="ml-2"
                        :disabled="status.loading2 || status.processing || isRequireVerify"
                        @click="buttonCrawlerSites"
                    >
                        {{ $t('pages.sites.button-update') }}
                        <i v-if="status.processing" class="fa fa-spinner fa-spin fa-fw ml-2" />
                    </b-button>
                    <b-button v-if="$store.getters.isMeRole(['admin', 'user']) && status.processing2" variant="danger" class="ml-2" :disabled="status.stopping" @click="buttonStopSites">
                        {{ $t('pages.sites.button-stop') }}
                    </b-button>
                    <span v-if="_.get(project, 'sitemap')">
                        <i class="fa fa-angle-right ml-2 mr-2" />
                        {{ project.sitemap }}
                        <b-badge variant="info">{{ project.crawler }}</b-badge>
                    </span>
                </b-col>
                <b-col cols="3">
                    <b-input-group class="oggy-search mb-3">
                        <b-form-input v-model="search" trim :placeholder="$t('pages.sites.placeholder-search')" />
                        <b-input-group-append>
                            <b-input-group-text><i class="fa fa-search text-secondary" /></b-input-group-text>
                        </b-input-group-append>
                    </b-input-group>
                </b-col>
            </b-row>

            <b-row class="mb-2">
                <b-col cols="3">
                    <b-form-checkbox-group v-model="selectedStatus">
                        <p class="mb-0">
                            <b-form-checkbox class="mr-2" value="ready">
                                <span class="cursor-pointer" @click.prevent="clickSelectedStatus('ready')">
                                    {{ $t('pages.sites.text-ready') }} ({{ sites.filter(s => 'ready' === s.status).length }})
                                </span>
                            </b-form-checkbox>
                            <span>{{ $t('pages.sites.text-all') }}: {{ sites.filter(s => 'deleted' !== s.status).length }}</span>
                        </p>
                        <p class="mb-0">
                            <b-form-checkbox class="mr-2" value="wait">
                                <span class="cursor-pointer" @click.prevent="clickSelectedStatus('wait')">{{ $t('pages.sites.text-wait') }} ({{ sites.filter(s => 'wait' === s.status).length }})</span>
                            </b-form-checkbox>
                        </p>
                        <p class="mb-0">
                            <b-form-checkbox class="mr-2" value="error">
                                <span class="cursor-pointer" @click.prevent="clickSelectedStatus('error')">
                                    {{ $t('pages.sites.text-error') }} ({{
                                        sites.filter(s => 'ready' !== s.status && 'wait' !== s.status && 'deleted' !== s.status && 'stop' !== s.status && 'limit' !== s.status).length
                                    }})
                                </span>
                            </b-form-checkbox>
                        </p>
                        <p v-if="sites.filter(s => 'stop' === s.status).length" class="mb-0">
                            <b-form-checkbox class="mr-2" value="stop">
                                <span class="cursor-pointer" @click.prevent="clickSelectedStatus('stop')">{{ $t('pages.sites.text-stop') }} ({{ sites.filter(s => 'stop' === s.status).length }})</span>
                            </b-form-checkbox>
                        </p>
                        <p v-if="sites.filter(s => 'limit' === s.status).length" class="mb-0">
                            <b-form-checkbox class="mr-2" value="limit">
                                <span class="cursor-pointer" @click.prevent="clickSelectedStatus('limit')">
                                    {{ $t('pages.sites.text-limit') }} ({{ sites.filter(s => 'limit' === s.status).length }})
                                </span>
                            </b-form-checkbox>
                        </p>
                    </b-form-checkbox-group>
                </b-col>

                <b-col cols="6">
                    <b-pagination
                        v-model="curPage"
                        class="oggy-pagination mb-0 mt-4"
                        :total-rows="filteredSites.length"
                        :per-page="selectedLayout === 'grid' ? perPage2 : perPage"
                        align="center"
                        :first-text="$t('globals.pagination.first')"
                        :prev-text="$t('globals.pagination.prev')"
                        :next-text="$t('globals.pagination.next')"
                        :last-text="$t('globals.pagination.last')"
                        limit="10"
                    />
                </b-col>

                <b-col cols="3" class="text-right">
                    <b-form-group class="text-left">
                        <p class="mb-0">
                            <b-form-checkbox v-model="selectedNoImages" switch value="noImages">
                                <span class="cursor-pointer">
                                    <i class="fa fa fa-window-close-o mr-1" />
                                    {{ $t('pages.sites.text-noimages') }}
                                </span>
                            </b-form-checkbox>
                        </p>
                    </b-form-group>

                    <b-form-radio-group v-model="selectedLayout" class="text-left mb-2">
                        <b-form-radio class="mr-3" value="table">
                            <span class="cursor-pointer">
                                <i class="fa fa-th-list mr-1" />
                                {{ $t('pages.sites.text-table') }}
                            </span>
                        </b-form-radio>
                        <b-form-radio value="grid">
                            <span class="cursor-pointer">
                                <i class="fa fa-th mr-1" />
                                {{ $t('pages.sites.text-grid') }}
                            </span>
                        </b-form-radio>
                    </b-form-radio-group>

                    <b-form-radio-group v-if="selectedLayout === 'grid'" v-model="selectedLayoutGrid" class="text-left">
                        <p class="mb-0">
                            <b-form-radio class="mr-3" value="facebook">
                                <span class="cursor-pointer">{{ $t('pages.sites.text-facebook') }}</span>
                            </b-form-radio>
                        </p>
                        <p class="mb-0">
                            <b-form-radio value="twitter">
                                <span class="cursor-pointer">{{ $t('pages.sites.text-twitter') }}</span>
                            </b-form-radio>
                        </p>
                    </b-form-radio-group>

                    <b-form-checkbox-group v-if="selectedLayout === 'table'" v-model="selectedLayoutTable" class="text-left">
                        <p class="mb-0">
                            <b-form-checkbox class="mr-2" value="opengraph">
                                <span class="cursor-pointer" @click.prevent="clickSelectedProp('opengraph')">{{ $t('pages.sites.text-opengraph') }}</span>
                            </b-form-checkbox>
                        </p>
                        <p class="mb-0">
                            <b-form-checkbox class="mr-2" value="twitter">
                                <span class="cursor-pointer" @click.prevent="clickSelectedProp('twitter')">{{ $t('pages.sites.text-twitter') }}</span>
                            </b-form-checkbox>
                        </p>
                    </b-form-checkbox-group>

                    <b-button v-if="selectedLayout === 'table'" variant="primary" size="sm" @click="buttonToggleAllDetails">
                        <i :class="`fa fa-${allDetails ? 'expand' : 'compress'} mr-1`" />
                        {{ $t(`pages.sites.button-${allDetails ? 'open' : 'close'}_all_details`) }}
                    </b-button>
                </b-col>
            </b-row>

            <div v-if="selectedLayout === 'table'">
                <LayoutSitesTable
                    :per-page="perPage"
                    :cur-page="curPage"
                    :filtered-sites="filteredSites"
                    :selected-layout-table="selectedLayoutTable"
                    :button-preview-og="buttonPreviewOg"
                    :button-update-sitemap="buttonUpdateSitemap"
                />
            </div>

            <div v-if="selectedLayout === 'grid'">
                <LayoutSitesGrid
                    :per-page="perPage2"
                    :cur-page="curPage"
                    :filtered-sites="filteredSites"
                    :selected-layout-grid="selectedLayoutGrid"
                    :button-preview-og="buttonPreviewOg"
                    :button-update-sitemap="buttonUpdateSitemap"
                />
            </div>

            <b-pagination
                v-if="filteredSites.length > perPage"
                v-model="curPage"
                class="oggy-pagination"
                :total-rows="filteredSites.length"
                :per-page="selectedLayout === 'grid' ? perPage2 : perPage"
                align="center"
                :first-text="$t('globals.pagination.first')"
                :prev-text="$t('globals.pagination.prev')"
                :next-text="$t('globals.pagination.next')"
                :last-text="$t('globals.pagination.last')"
                limit="10"
            />
        </div>

        <b-modal id="modal-crawler-sites" :title="$t('globals.modal.warning')" hide-header-close>
            <p class="mt-2" v-html="$t('pages.sites.modal-update.description1', { sitemap: `<b class=&quot;text-info&quot;>${project.sitemap}</b>` })" />
            <p>{{ $t('pages.sites.modal-update.description2') }}</p>

            <template slot="modal-footer" slot-scope="{ cancel }">
                <b-button @click="cancel()">{{ $t('globals.button.cancel') }}</b-button>
                <b-button :disabled="status.processing" variant="danger" @click="crawlerSites">
                    {{ $t('pages.sites.modal-update.button-update') }}
                    <i v-if="status.processing" class="fa fa-spinner fa-spin fa-fw ml-2" />
                </b-button>
            </template>
        </b-modal>

        <b-modal id="modal-crawler-warning" size="lg" :title="$t('globals.modal.warning')" hide-header-close>
            <p class="mt-2" v-html="$t('pages.sites.toast-ready-listempty', { sitemap: `<b class=&quot;text-info&quot;>${project.sitemap}</b>` })" />
            <p class="mt-2">{{ $t('pages.sites.toast-ready-listerrors') }}</p>
            <ul>
                <li v-for="(log, index) in logs" :key="index">
                    <p>
                        <span v-if="log.error === 'notmatches'">Filter does not match url:</span>
                        <span v-if="log.error === 'file'">This url is the file:</span>
                        <span v-if="log.error === 'long'">This url is too long:</span>
                        <br />
                        <code>{{ log.url }}</code>
                    </p>
                </li>
                <li v-if="logs.length >= 10">...</li>
            </ul>

            <template slot="modal-footer" slot-scope="{ ok }">
                <b-button @click="ok()">{{ $t('globals.button.ok') }}</b-button>
            </template>
        </b-modal>

        <ModalProject ref="modalProject" @editedProject="onEditedProject" />

        <ModalPreviewOg ref="modalPreviewOg" />
    </div>
</template>

<style scoped>
.card {
    background-color: #aaa1;
}
.tooltip {
    font-size: 80%;
}
</style>

<script>
import _ from 'lodash';
import axios from 'axios';
import t from '@/tool';

import ModalPreviewOg from '@/components/modalPreviewOg.vue';
import LayoutSitesGrid from '@/components/layoutSitesGrid.vue';
import LayoutSitesTable from '@/components/layoutSitesTable.vue';
import ModalProject from '@/components/modalProject.vue';

export default {
    components: {
        ModalPreviewOg,
        ModalProject,
        LayoutSitesGrid,
        LayoutSitesTable
    },
    props: {
        project: {
            type: Object,
            required: true
        },
        sites: {
            type: Array,
            required: true
        },
        status: {
            type: Object,
            required: true
        },
        pagescan: {
            type: Object,
            required: true
        },
        logs: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            allDetails: false,
            timeInterval: 30,
            timeout: null,
            search: '',
            selectedStatus: ['ready', 'wait', 'error', 'stop', 'limit'],
            selectedNoImages: false,
            selectedLayout: 'table', //['table', 'grid'],
            selectedLayoutTable: ['opengraph'], //, 'twitter'], //, 'itemprop'],
            selectedLayoutGrid: 'facebook', //['facebook', 'twitter'],
            curPage: 1,
            perPage: 20,
            perPage2: 63
        };
    },
    computed: {
        isNoLongerValid() {
            return !this.$store.getters.isClientValidUntil();
        },
        isRequireVerifyMail() {
            return this.$store.getters.isMeRequireVerify();
        },
        isRequireVerify() {
            return this.isRequireVerifyMail || this.isNoLongerValid;
        },
        filteredSites() {
            const search = t.replaceSearch(this.search);
            const isSelectedTable = this.selectedLayout === 'table';
            const isSelectedTableOpenGraph = _.indexOf(this.selectedLayoutTable, 'opengraph') !== -1;
            const isSelectedTableTwitter = _.indexOf(this.selectedLayoutTable, 'twitter') !== -1;
            const isSelectedGrid = this.selectedLayout === 'grid';
            const isSelectedGridFacebook = this.selectedLayoutGrid === 'facebook';
            const isSelectedGridTwitter = this.selectedLayoutGrid === 'twitter';
            return this.sites
                .filter(s => s.status !== 'deleted')
                .filter(s => _.indexOf(this.selectedStatus, s.status) !== -1 || (_.indexOf(this.selectedStatus, 'error') !== -1 && _.indexOf(['ready', 'wait', 'limit', 'stop'], s.status) === -1))
                .filter(
                    // filer no images
                    s =>
                        !this.selectedNoImages ||
                        (isSelectedTable && ((isSelectedTableOpenGraph && _.get(s, '_metas.og.noimages')) || (isSelectedTableTwitter && _.get(s, '_metas.tw.noimages')))) ||
                        (isSelectedGrid && ((isSelectedGridFacebook && _.get(s, '_metas.og.noimages')) || (isSelectedGridTwitter && _.get(s, '_metas.tw.noimages'))))
                )
                .filter(s => `${s.url || ''}`.match(search));
        },
        emailLimited() {
            if (this.email.length > 8) {
                return this.email.substring(0, 8) + '...';
            } else {
                return this.email;
            }
        }
    },

    methods: {
        buttonGoProject() {
            this.$router.push(`/projects`);
        },
        buttonPreviewOg(s) {
            return this.$refs.modalPreviewOg.buttonPreview(s);
        },

        clickSelectedStatus(select) {
            this.selectedStatus = [select];
        },

        clickSelectedProp(select) {
            this.selectedLayoutTable = [select];
        },

        buttonStopSites() {
            this.status.stopping = true;
            axios
                .post(`/api/project/${this.$route.params.projectID}/stop`)
                .then(response => {
                    if (!_.get(response, 'data.success')) {
                        return this.$bvToast.toast(_.get(response, 'data.msg', 'unknown'), {
                            title: this.$t('globals.toast.warning'),
                            variant: 'warning',
                            solid: true
                        });
                    }
                    this.$bvToast.toast(this.$t('pages.sites.toast-stopping', { count: response.data.count }), {
                        title: this.$t('globals.toast.info'),
                        variant: 'info',
                        solid: true
                    });
                })
                .catch(e => {
                    this.status.stopping = false;
                    this.$bvToast.toast(e.toString(), {
                        title: this.$t('globals.toast.error'),
                        variant: 'danger',
                        solid: true
                    });
                });
        },

        buttonCrawlerSites() {
            this.$bvModal.show('modal-crawler-sites');
        },

        crawlerSites(bvModalEvt) {
            bvModalEvt.preventDefault();

            if (!_.get(this, 'project.projectID')) {
                return;
            }
            this.status.processing = true;
            this.status.crawlering = true;
            axios
                .post(`/api/project/${this.$route.params.projectID}/crawler`, this.project)
                .then(resp => {
                    this.status.crawlering = false;
                    if (!_.get(resp, 'data.success')) {
                        this.status.processing = false;
                        this.timeInterval = 30;
                        let message = _.get(resp, 'data.msg', 'unknown');
                        if (message === 'http_302') {
                            //(message === 'http_301' || message === 'http_302') && resp.data.location) {
                            message = 'http_301';
                        }
                        this.$bvModal.hide('modal-crawler-sites');
                        this.$refs.modalProject.buttonEditProject({ project: this.project, check: true });
                        return this.$bvToast.toast(this.$t(`pages.sites.net-${message}`, { url: resp.data.url, redirect: resp.data.location }), {
                            title: this.$t('globals.toast.warning'),
                            variant: 'warning',
                            solid: true
                        });
                    }
                    this.timeInterval = 2;
                    this.status.processing2 = true;
                    this.status.stopping = false;
                    this.$bvModal.hide('modal-crawler-sites');
                    this.$bvToast.toast(this.$t('pages.sites.toast-updating'), {
                        title: this.$t('globals.toast.info'),
                        variant: 'info',
                        solid: true
                    });
                    this.selectedStatus = ['ready', 'wait', 'error', 'stop', 'limit'];
                })
                .catch(e => {
                    this.status.crawlering = false;
                    this.status.processing = false;
                    this.status.processing2 = false;
                    this.timeInterval = 30;
                    this.$bvToast.toast(e.toString(), {
                        title: this.$t('globals.toast.error'),
                        variant: 'danger',
                        solid: true
                    });
                });
        },

        buttonUpdateSitemap(site) {
            this.$set(site, '_processing', true);
            axios.post(`/api/project/${this.$route.params.projectID}/site/${site.siteID}/update`, this.project).then(
                resp => {
                    if (!_.get(resp, 'data.success')) {
                        this.$delete(site, '_processing');
                        this.status.processing = false;
                        let message = _.get(resp, 'data.msg', 'unknown');
                        return this.$bvToast.toast(this.$t(`pages.sites.net-${message}`, { url: resp.data.url, redirect: resp.data.location }), {
                            title: this.$t('globals.toast.warning'),
                            variant: 'warning',
                            solid: true
                        });
                    }
                    this.$delete(site, '_processing');
                    const i = _.findIndex(this.sites, { siteID: site.siteID });
                    if (i !== -1) {
                        if (_.get(resp.data.site, 'status') === 'ready') {
                            site._showDetails = true;
                        }
                        this.$set(this.sites, i, { ...site, ...resp.data.site });
                        this._refreshSite(this.sites[i]);
                        this._refreshUniqScore();
                        this._refreshScoreAll(this.sites[i]);
                        this.$bvToast.toast(
                            this.$t(
                                `pages.sites.toast-status${
                                    _.indexOf(['ready', 'limit', 'no html', 'file', '301', '302', '303', '401', '404'], resp.data.site.status) !== -1 ? `-${resp.data.site.status}` : ''
                                }`,
                                { status: resp.data.site.status, url: site.url, redirect: _.get(resp.data.site, 'meta.headers.location', '[unknown]') }
                            ),
                            {
                                title: this.$t(`globals.toast.${resp.data.site.status === 'ready' ? 'success' : 'error'}`),
                                variant: resp.data.site.status === 'ready' ? 'info' : 'warning',
                                solid: true
                            }
                        );
                    }
                },
                e => {
                    this.$delete(site, '_processing');
                    this.status.processing = false;
                    this.$bvToast.toast(e.toString(), {
                        title: this.$t('globals.toast.error'),
                        variant: 'danger',
                        solid: true
                    });
                }
            );
        },

        buttonToggleAllDetails() {
            this.allDetails = !this.allDetails;
            if (this.allDetails) {
                _.each(this.sites, s => {
                    s._showDetails = false;
                });
            } else {
                _.each(this.sites, s => {
                    s._showDetails = true;
                });
            }
        },

        onEditedProject(project) {
            this.project = { ...project };
        }
    }
};
</script>
