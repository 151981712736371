import _ from 'lodash';
import axios from 'axios';

_.hasnU = (a, b) => _.has(a, b) && !_.isUndefined(a[b]);

const roleAccess = {
    superadmin: {},
    admin: {},
    user: {},
    guest: {},
    public: {}
};

export default {
    state: {
        user: false,
        dev: false
    },
    mutations: {
        setMe: (state, payload) => {
            // console.log('setMe1');
            if (payload !== false && !_.isPlainObject(payload)) {
                return console.log('error setMe is not object');
            }
            state.user = payload;
            // console.log('setMe2');
        },
        setDev: (state, payload) => {
            state.dev = !!payload;
        }
    },

    actions: {
        commitMe: ({ commit }, data) => {
            // console.log('setcommit1');
            if (_.hasnU(data, 'me')) commit('setMe', data.me);
            if (_.hasnU(data, 'client')) commit('setClient', data.client);
            if (_.hasnU(data, 'plans')) commit('setPlans', data.plans);
            if (_.hasnU(data, 'token')) commit('setToken', data.token);
            if (_.hasnU(data, 'locale')) commit('setLocale', data.locale);
            // console.log('setcommit2');
            return Promise.resolve();
        },
        initMe: ({ dispatch }) => {
            return axios
                .get(`/api/auth/me`)
                .then(resp => {
                    return dispatch('commitMe', resp.data);
                })
                .catch(error => {
                    console.log('error', error);
                });
        }
    },

    getters: {
        todoPlan: state => !!state.dev,

        isMeIniting: state => () => state.user === null,

        getMeUserID: state => () => _.get(state, 'user.userID', false),

        getMeRole: state => () => _.get(state, 'user.role', false),

        isMeRequireVerify: state => () => _.get(state, 'user.role') && _.get(state, 'user.meta.requireVerify'),

        isMeRole: state => (roles = true) => {
            const role = _.get(state, 'user.role', null);

            if (roles === true && role) {
                return true;
            } else if (roles === false && role === null) {
                return true;
            } else if (_.isString(roles) && roles === role) {
                return true;
            } else if (_.isArray(roles) && _.indexOf(roles, role) !== -1) {
                return true;
            }
            return false;
        },

        isMeAccess: state => (access = 'null') => {
            const role = _.get(state, 'user.role') || (_.get(state, 'user.userID') ? 'guest' : 'public');
            return !!_.get(roleAccess, [role, access]);
        },

        isMeDev: state => () => {
            return !!_.get(state, 'user.meta.developer');
        },

        isDev: state => () => {
            return !!state.dev;
        }
    }
};
