import _ from 'lodash';

import t from '../tool';
import i18n from '../i18n';

const _locale = _.get(t.cookie(), 'locale', process.env.VUE_APP_I18N_LOCALE || 'en');
i18n.locale = _locale;

export default {
    state: {
        locale: _locale
    },
    mutations: {
        setLocale: (state, payload) => {
            if (!_.isString(payload)) {
                return console.log('error setLocale is not string');
            }
            state.locale = payload;
            i18n.locale = payload;
            let cookie = t.cookie();
            _.set(cookie, 'locale', payload);
            t.cookie(cookie);
        }
    }
};
