import _ from 'lodash';
import Vue from 'vue';
import VueRouter from 'vue-router';

import store from './store';

import Home from './views/home.vue';

import A_Signup from './views/auth/signup.vue';
import A_Login from './views/auth/signin.vue';
import A_Logout from './views/auth/signout.vue';
import A_PasswordReset from './views/auth/passwordreset.vue';
import A_PasswordChange from './views/auth/passwordchange.vue';
import A_VerifyEmail from './views/auth/verifyemail.vue';

import Profile from './views/profile.vue';
import Projects from './views/projects.vue';
import Project from './views/project/index.vue';
import Dashboard from './views/project/dashboard.vue';
import Sites from './views/project/sites.vue';
import Users from './views/users.vue';
// import Pricing from './views/plan/pricing.vue';
import Plan from './views/plan/plan.vue';

import PlanRetrieve from './views/plan/retrieve.vue';

const L_Impressum = () => import(/* webpackChunkName: "footer" */ './views/legal/Impressum.vue');
const L_AGB = () => import(/* webpackChunkName: "footer" */ './views/legal/AGB.vue');
const L_Datenschutz = () => import(/* webpackChunkName: "footer" */ './views/legal/Datenschutz.vue');
const L_Nutzungsbedingungen = () => import(/* webpackChunkName: "footer" */ './views/legal/Nutzungsbedingungen.vue');

const S_Clients = () => import(/* webpackChunkName: "superadmin" */ './views/superadmin/clients.vue');
const S_Users = () => import(/* webpackChunkName: "superadmin" */ './views/superadmin/users.vue');
const S_Subscriptions = () => import(/* webpackChunkName: "superadmin" */ './views/superadmin/subscriptions.vue');
const S_Invoices = () => import(/* webpackChunkName: "superadmin" */ './views/superadmin/invoices.vue');
const S_ClientProjects = () => import(/* webpackChunkName: "superadmin" */ './views/superadmin/clientProjects.vue');
const S_ClientUsers = () => import(/* webpackChunkName: "superadmin" */ './views/superadmin/clientUsers.vue');
const S_ClientInvoices = () => import(/* webpackChunkName: "superadmin" */ './views/superadmin/clientInvoices.vue');

const D_Translator = () => import('./views/superadmin/translator.vue');

Vue.use(VueRouter);

const noSuperAdmin = ['guest', 'user', 'admin'];

const routes = [
    { path: '/', component: Home },
    { path: '/registration', component: A_Signup },
    { path: '/login', component: A_Login },
    { path: '/logout', component: A_Logout },
    { path: '/forgotpassword', component: A_PasswordReset },
    { path: '/changepassword/:jwt', component: A_PasswordChange },
    { path: '/changepassword', component: A_PasswordChange },
    { path: '/verifyemail/:jwt', component: A_VerifyEmail },
    { path: '/verifyemail', component: A_VerifyEmail },

    { path: '/superadmin/clients', component: S_Clients, meta: { requireRole: 'superadmin' } },
    { path: '/superadmin/users', component: S_Users, meta: { requireRole: 'superadmin' } },
    { path: '/superadmin/subscriptions', component: S_Subscriptions, meta: { requireRole: 'superadmin' } },
    { path: '/superadmin/invoices', component: S_Invoices, meta: { requireRole: 'superadmin' } },
    { path: '/superadmin/client/:clientID/projects', component: S_ClientProjects, meta: { requireRole: 'superadmin' } },
    { path: '/superadmin/client/:clientID/users', component: S_ClientUsers, meta: { requireRole: 'superadmin' } },
    { path: '/superadmin/client/:clientID/invoices', component: S_ClientInvoices, meta: { requireRole: 'superadmin' } },

    { path: '/profile', component: Profile, meta: { requireRole: true } },
    { path: '/projects', component: Projects, meta: { requireRole: noSuperAdmin } },
    {
        path: '/project/:projectID/',
        component: Project,
        children: [
            { path: 'dashboard', component: Dashboard },
            { path: 'sites', component: Sites },
        ],
        meta: {
            requireRole: noSuperAdmin,
        },
    },
    { path: '/users', component: Users, meta: { requireRole: 'admin' } },
    // { path: '/pricing', component: Pricing },
    { path: '/plan', component: Plan, meta: { requireRole: 'admin' } },
    { path: '/plan/retrieve', component: PlanRetrieve, meta: { requireRole: 'admin' } },

    { path: '/dev/translator', component: D_Translator, meta: { requireRole: 'superadmin' } },

    { path: '/legal/datenschutz', component: L_Datenschutz },
    { path: '/legal/impressum', component: L_Impressum },
    { path: '/legal/agb', component: L_AGB },
    { path: '/legal/nutzungsbedingungen', component: L_Nutzungsbedingungen },

    { path: '*', redirect: '/' },
];

const router = new VueRouter({
    routes,
    mode: 'history',
    scrollBehavior() {
        return { x: 0, y: 0 };
    },
});

router.beforeEach((to, from, next) => {
    const requireRole = to.matched.some((r) => _.get(r, 'meta.requireRole', false));
    const role = store.getters.getMeRole();
    if (role === false && requireRole !== false) {
        return next({
            path: from.path === '/pricing' ? '/registration' : '/login',
            query: { redirect: to.fullPath },
        });
    } else if (role !== false) {
        if (requireRole === false || requireRole === true) {
            return next();
        } else if (_.isArray(requireRole) && _.indexOf(requireRole, role) !== -1) {
            return next();
        } else if (_.isString(requireRole) && role === requireRole) {
            return next();
        }
        return next({
            path: '/login',
            query: { redirect: to.fullPath },
        });
    }
    return next();
});

export default router;
