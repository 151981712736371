<template>
    <div>
        <h1 class="mt-4">{{ _.get(project, 'projectName', '...') }} - {{ $t('pages.dashboard.title') }}</h1>

        <div v-if="status.loading" class="mb-3 mt-4 oggy-loading">
            <b-button>
                {{ $t('globals.button.loading') }}
                <i class="fa fa-spinner fa-spin fa-fw ml-2" />
            </b-button>
        </div>

        <div v-if="!status.loading" class="mb-3 text-center">
            <b-row class="mt-4 ">
                <b-col>
                    <h3>{{ $t('pages.dashboard.text-opengraph_score') }}</h3>
                </b-col>
            </b-row>

            <b-row>
                <b-col class="text-left">
                    OG:title
                    <b-row>
                        <b-col cols="10">
                            <b-progress :max="getMax('og', 'title')" :variant="getVariant('og', 'title')">
                                <b-progress-bar :value="getValue('og', 'title')" :label="getPercent('og', 'title')"></b-progress-bar>
                            </b-progress>
                        </b-col>
                        <b-col cols="2">{{ getPercent('og', 'title') }}</b-col>
                    </b-row>
                    OG:description
                    <b-row>
                        <b-col cols="10">
                            <b-progress :max="getMax('og', 'description')" :variant="getVariant('og', 'description')">
                                <b-progress-bar :value="getValue('og', 'description')" :label="getPercent('og', 'description')"></b-progress-bar>
                            </b-progress>
                        </b-col>
                        <b-col cols="2">{{ getPercent('og', 'description') }}</b-col>
                    </b-row>
                </b-col>
                <b-col>
                    <div>
                        <PieDashboard :i-value="getValueSum('og')" :i-max="getMaxSum('og')" i-color="#179" style="max-height: 7.5rem" />
                    </div>
                </b-col>
                <b-col class="text-left aaa">
                    OG:url
                    <b-row>
                        <b-col cols="10">
                            <b-progress :max="getMax('og', 'url')" :variant="getVariant('og', 'url')">
                                <b-progress-bar :value="getValue('og', 'url')" :label="getPercent('og', 'url')"></b-progress-bar>
                            </b-progress>
                        </b-col>
                        <b-col cols="2">{{ getPercent('og', 'url') }}</b-col>
                    </b-row>
                    OG:type
                    <b-row>
                        <b-col cols="10">
                            <b-progress :max="getMax('og', 'type')" :variant="getVariant('og', 'type')">
                                <b-progress-bar :value="getValue('og', 'type')" :label="getPercent('og', 'type')"></b-progress-bar>
                            </b-progress>
                        </b-col>
                        <b-col cols="2">{{ getPercent('og', 'type') }}</b-col>
                    </b-row>
                    OG:image
                    <b-row>
                        <b-col cols="10">
                            <b-progress :max="getMax('og', 'image')" :variant="getVariant('og', 'image')">
                                <b-progress-bar :value="getValue('og', 'image')" :label="getPercent('og', 'image')"></b-progress-bar>
                            </b-progress>
                        </b-col>
                        <b-col cols="2">{{ getPercent('og', 'image') }}</b-col>
                    </b-row>
                </b-col>
            </b-row>

            <b-row class="mt-5">
                <b-col>
                    <h3>
                        <b-link :to="`/project/${$route.params.projectID}/sites`">{{ $t('pages.dashboard.text-page_scan') }}</b-link>
                    </h3>
                    <b-progress :max="sites.filter(s => 'deleted' !== s.status).length" show-value height="1.25rem">
                        <b-progress-bar :value="sites.filter(s => 'ready' === s.status).length" variant="bar1">ready</b-progress-bar>
                        <b-progress-bar
                            :value="sites.filter(s => 'ready' !== s.status && 'wait' !== s.status && 'deleted' !== s.status && 'stop' !== s.status && 'limit' !== s.status).length || 0"
                            variant="bar4"
                        >
                            error
                        </b-progress-bar>
                        <b-progress-bar :value="sites.filter(s => 'wait' === s.status).length" variant="bar2" :animated="status.processing">wait</b-progress-bar>
                        <b-progress-bar :value="sites.filter(s => 'stop' === s.status).length" variant="bar3">stop</b-progress-bar>
                        <b-progress-bar :value="sites.filter(s => 'limit' === s.status).length" variant="bar3">limit</b-progress-bar>
                    </b-progress>
                </b-col>
            </b-row>

            <b-row class="mt-5">
                <b-col>
                    <h3>{{ $t('pages.dashboard.text-twitter_score') }}</h3>
                </b-col>
            </b-row>

            <b-row>
                <b-col class="text-left">
                    Twitter:title
                    <b-row>
                        <b-col cols="10">
                            <b-progress :max="getMax('tw', 'title')" :variant="getVariant('tw', 'title')">
                                <b-progress-bar :value="getValue('tw', 'title')" :label="getPercent('tw', 'title')"></b-progress-bar>
                            </b-progress>
                        </b-col>
                        <b-col cols="2">{{ getPercent('tw', 'title') }}</b-col>
                    </b-row>
                    Twitter:description
                    <b-row>
                        <b-col cols="10">
                            <b-progress :max="getMax('tw', 'description')" :variant="getVariant('tw', 'description')">
                                <b-progress-bar :value="getValue('tw', 'description')" :label="getPercent('tw', 'description')"></b-progress-bar>
                            </b-progress>
                        </b-col>
                        <b-col cols="2">{{ getPercent('tw', 'description') }}</b-col>
                    </b-row>
                </b-col>
                <b-col>
                    <div>
                        <PieDashboard :i-value="getValueSum('tw')" :i-max="getMaxSum('tw')" i-color="#179" style="max-height: 7.5rem" />
                    </div>
                </b-col>
                <b-col class="text-left">
                    <!-- Twitter:url
                    <b-row>
                        <b-col cols="10">
                            <b-progress :value="getValue('tw, 'score.url')" :max="getMax('tw, 'score.url')" show-progress variant="bar1"></b-progress>
                        </b-col>
                        <b-col cols="2">{{ getPercent('tw', 'url') }}</b-col>
                    </b-row> -->
                    Twitter:type
                    <b-row>
                        <b-col cols="10">
                            <b-progress :max="getMax('tw', 'type')" :variant="getVariant('tw', 'type')">
                                <b-progress-bar :value="getValue('tw', 'type')" :label="getPercent('tw', 'type')"></b-progress-bar>
                            </b-progress>
                        </b-col>
                        <b-col cols="2">{{ getPercent('tw', 'type') }}</b-col>
                    </b-row>
                    Twitter:image
                    <b-row>
                        <b-col cols="10">
                            <b-progress :max="getMax('tw', 'image')" :variant="getVariant('tw', 'image')">
                                <b-progress-bar :value="getValue('tw', 'image')" :label="getPercent('tw', 'image')"></b-progress-bar>
                            </b-progress>
                        </b-col>
                        <b-col cols="2">{{ getPercent('tw', 'image') }}</b-col>
                    </b-row>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<style lang="scss" scoped>
$color1: #10739e;
$color2: #1d977d;
$color3: #f2931e;
$color4: #ae4132;

/deep/ .progress-bar {
    &.bg-bar1 {
        background-color: $color1;
    }
    &.bg-bar2 {
        background-color: $color2;
    }
    &.bg-bar3 {
        background-color: $color3;
    }
    &.bg-bar4 {
        background-color: $color4;
    }
}
</style>

<script>
import _ from 'lodash';
// import axios from 'axios';
// import t from '@/tool';

import PieDashboard from '@/components/pieDashboard.vue';

export default {
    components: {
        PieDashboard
    },
    props: {
        project: {
            type: Object,
            required: true
        },
        sites: {
            type: Array,
            required: true
        },
        status: {
            type: Object,
            required: true
        },
        pagescan: {
            type: Object,
            required: true
        },
        uniqScore: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            scores: {
                og: {},
                tw: {}
            }
        };
    },
    created() {
        console.log('sites', this.sites.length);
    },
    methods: {
        getValue(o, p) {
            return _.get(this.uniqScore, [o, 'score', p, 'value'], null);
        },
        getMax(o, p) {
            return _.get(this.uniqScore, [o, 'score', p, 'max'], null);
        },
        getScale(o, p) {
            const max = this.getMax(o, p);
            return max ? this.getValue(o, p) / max : null;
        },
        getVariant(o, p) {
            const scale = this.getScale(o, p);
            if (scale >= 0.8) {
                return 'bar1';
            } else if (scale >= 0.5) {
                return 'bar2';
            } else if (scale >= 0.3) {
                return 'bar3';
            }
            return 'bar4';
        },
        getPercent(o, p) {
            const scale = this.getScale(o, p);
            return _.isNumber(scale) ? (scale * 100).toFixed(1) + '%' : null;
        },
        getValueSum(o) {
            return this.getValue(o, 'title') + this.getValue(o, 'title') + this.getValue(o, 'description') + this.getValue(o, 'url') + this.getValue(o, 'type') + this.getValue(o, 'image');
        },
        getMaxSum(o) {
            return this.getMax(o, 'title') + this.getMax(o, 'title') + this.getMax(o, 'description') + this.getMax(o, 'url') + this.getMax(o, 'type') + this.getMax(o, 'image');
        }
    }
};
</script>
