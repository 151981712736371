<template>
    <div>
        <b-row>
            <b-col offset-md="4" md="4">
                <b-card class="mt-5">
                    <h1>{{ $t('pages.passwordreset.title') }}</h1>

                    <b-form v-if="!sent" class="oggy-form" @keydown.enter.prevent="submit">
                        <b-form-group :label="$t('components.modal-user.label-email')" label-for="email-text">
                            <b-form-input id="email-text" v-model="form.email" type="email" trim :state="validationEmailComputed" :placeholder="$t('components.modal-user.placeholder-email')" />
                            <b-form-invalid-feedback :state="validationEmailComputed">{{ $t('components.modal-user.validation-email') }}</b-form-invalid-feedback>
                        </b-form-group>

                        <b-form-group class="text-right oggy-loading">
                            <b-button :disabled="processing" variant="primary" @click="submit">
                                {{ $t('pages.passwordreset.button-reset') }}
                                <i v-if="!processing" class="fa fa-key ml-2" />
                                <i v-if="processing" class="fa fa-spinner fa-spin fa-fw ml-2" />
                            </b-button>
                        </b-form-group>
                    </b-form>
                    <div v-else class="pt-3" v-html="$t('pages.passwordreset.text-sent', { email: `<b>${form.email}</b>` })" />
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<style lang="scss" scoped>
@import '@/assets/scss/_variables';
</style>

<script>
import _ from 'lodash';
import axios from 'axios';
import t from '@/tool';

export default {
    data() {
        return {
            processing: false,
            sent: false,
            form: {
                _state: null,
                email: ''
            }
        };
    },
    computed: {
        validationEmailComputed() {
            return this.validationEmail() || this.form._state;
        },
        validationSubmitComputed() {
            return this.validationSubmit();
        }
    },
    created() {
        if (this.$store.getters.isMeRole()) {
            return this.$router.push('/');
        }
    },
    methods: {
        validationEmail() {
            const email = _.get(this, 'form.email', '');
            const len = _.size(email);
            return 5 <= len && len <= 30 && t.validationEmail(email);
        },
        validationSubmit() {
            this.form._state = false;
            return this.validationEmail();
        },

        submit() {
            if (this.processing) {
                return;
            }

            if (!this.validationSubmit()) {
                return;
            }

            this.processing = true;
            axios
                .post(`/api/auth/passwordreset`, this.form)
                .then(resp => {
                    this.processing = false;
                    if (!_.get(resp, 'data.success')) {
                        let message = _.get(resp, 'data.msg', 'unknown');
                        return this.$bvToast.toast(this.$t(`pages.passwordreset.net-${message}`, { email: resp.data.email }), {
                            title: this.$t('globals.toast.warning'),
                            variant: 'warning',
                            solid: true
                        });
                        // return this.$bvToast.toast(this.$t('pages.signin.toast-' + _.get(resp, 'data.msg', 'unknown')), {
                        //     title: this.$t('globals.toast.warning'),
                        //     variant: 'warning',
                        //     solid: true
                        //     // noAutoHide : true
                        // });
                    }
                    this.sent = true;
                    // todo
                })
                .catch(e => {
                    this.processing = false;
                    this.$bvToast.toast(e.toString(), {
                        title: this.$t('globals.toast.error'),
                        variant: 'danger',
                        solid: true
                    });
                });
        }
    }
};
</script>
