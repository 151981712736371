import _ from 'lodash';
import axios from 'axios';

import t from '../tool';

const _token = _.get(t.cookie(), 'token', false);
if (_token) {
    axios.defaults.headers.common['x-oggy-token'] = _token;
}

export default {
    state: {
        token: _token
    },
    mutations: {
        setToken: (state, payload) => {
            if (payload !== false && !_.isString(payload)) {
                return console.log('error setToken is not string');
            }
            if (payload === false) {
                state.token = false;
                delete axios.defaults.headers.common['x-oggy-token'];
                // state.me.commit('setMe', false);
                // store.commit('setClient', false);
            } else {
                state.token = payload;
                axios.defaults.headers.common['x-oggy-token'] = state.token;
            }
            let cookie = t.cookie();
            _.set(cookie, 'token', payload);
            t.cookie(cookie);
        }
    },
    getters: {
        isToken: state => () => !!state.token
    }
};
