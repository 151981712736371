<template>
    <div>
        <!-- {{ $router }} -->
        <router-view :project="project" :sites="sites" :uniq-score="uniqScore" :logs="logs" :pagescan="pagescan" :status="status" />
    </div>
</template>

<style scoped>
.card {
    background-color: #aaa1;
}
.tooltip {
    font-size: 80%;
}
</style>

<script>
import _ from 'lodash';
import axios from 'axios';

export default {
    data() {
        return {
            status: {
                statusing: false,
                crawlering: false,
                processing: false,
                processing2: false,
                stopping: false,
                finished: false,
                loading: false,
                loading2: false,
                refreshing: false
            },
            // allDetails: false,
            timeInterval: 30,
            pagescan: {
                wait: 0,
                ready: 0,
                stop: 0,
                error: 0,
                limit: 0,
                value: 0,
                max: 0
            },
            timeout: null,
            lastAt: '2019-01-01T00:00:00.000Z',
            project: {},

            logs: [],
            sites: [],
            // site: {},
            uniqScore: {}
        };
    },
    created() {
        this.status.statusing = true;
        this.refreshSites().then(
            () => {
                this.status.statusing = false;
                this.status.statusing2 = true;
                this.timeInterval = 2;
                this.progressStatus();
            },
            () => {
                this.status.statusing = false;
            }
        );

        this.timeout = setInterval(() => {
            if (--this.timeInterval === 0) {
                this.progressStatus();
            }
        }, 500);
    },
    destroyed() {
        if (this.timeout) {
            clearInterval(this.timeout);
            this.timeout = null;
        }
    },
    methods: {
        _refreshScoreImage(s, prop, o) {
            let score = 0;
            let max = 2.0;
            const values = {
                'og:image': [[200, 200], [1200, 630], 8 * 1024 * 1024],
                'twitter:image': [[120, 120], [876, 438], 1 * 1024 * 1024]
            };
            const c = s._metas[o];
            const metas = c.images.filter(p => p.c && (p.p === prop || (o === 'tw' && p.p === `${prop}:src`)));
            c.noimages = false;
            if (metas.length === 0) {
                score += 0.0;
                c.msgs.push(`+0.0 ${prop}_missing`);
                c.noimages = true;
            } else if (metas.length) {
                score += max;
                c.msgs.push(`+2.0 ${prop}_ok`);
                const main = metas[0];
                if (metas.filter(p => p.c === main.c).length > 1) {
                    score += -0.2;
                    c.msgs.push(`-0.2 ${prop}_double`);
                }
                if (this.uniqScore[o].image[main.c] !== 1) {
                    if (this.uniqScore[o].image[main.c] > 2) {
                        score += -1.0;
                        c.msgs.push(`-1.0 ${prop}_duplicate_more`);
                    } else {
                        score += -0.4;
                        c.msgs.push(`-0.4 ${prop}_duplicate`);
                    }
                }
                if (prop === 'og:image') {
                    const width = _.get(main, 'image.width', _.get(main, 'width'), null);
                    const height = _.get(main, 'image.height', _.get(main, 'height'), null);
                    const filesize = _.get(main, 'image.filesize', null);
                    if (filesize === null || filesize > values[prop][2]) {
                        score += -2.0;
                        c.msgs.push(`-2.0 ${prop}_filesize_max`);
                    } else if (width === null || height === null) {
                        score += -2.0;
                        c.msgs.push(`-2.0 ${prop}_widthheight_null`);
                    } else if (width < 200 || height < 200) {
                        score += -1.8;
                        c.msgs.push(`-1.8 ${prop}_widthheight_min`);
                    } else if ((width < 800 && width >= height) || (height < 800 && height >= width)) {
                        // } else if (width < 800 || height < 800) {
                        score += -1.5;
                        c.msgs.push(`-1.5 ${prop}_widthheight_small`);
                    } else if ((width < 1100 && width >= height) || (height < 1100 && height >= width)) {
                        score += -1;
                        c.msgs.push(`-1 ${prop}_widthheight_medium`);
                    } else if (height > width) {
                        score += -0.4;
                        c.msgs.push(`-0.4 ${prop}_widthheight_vertical`);
                    }
                } else {
                    const width = _.get(main, 'image.width', _.get(main, 'width'), null);
                    const height = _.get(main, 'image.height', _.get(main, 'height'), null);
                    const filesize = _.get(main, 'image.filesize', null);
                    if (width === null || height === null || width < values[prop][0][0] || height < values[prop][0][1] || width > values[prop][1][0] || height > values[prop][1][1]) {
                        score += -0.2;
                        c.msgs.push(`-0.2 ${prop}_resize`);
                    } else if (filesize === null || filesize > values[prop][2]) {
                        score += -0.2;
                        c.msgs.push(`-0.2 ${prop}_filesize`);
                    }
                }
            }
            score = score < 0.0 ? 0.0 : score;
            c.score += score;
            c.max += max;
            this.uniqScore[o].score.image.count++;
            this.uniqScore[o].score.image.value += score;
            this.uniqScore[o].score.image.max += max;
        },

        _refreshScoreText(s, prop, o, p) {
            let score = 0;
            let max = 1.0;
            const values = {
                'og:title': {
                    min: { value: 25, point: -0.5 },
                    max: { value: 95, point: -0.25 },
                    double: -0.1,
                    duplicate: -0.2,
                    duplicate_more: -0.5
                },
                'og:description': {
                    min: { value: 100, point: -0.5 },
                    max: { value: 300, point: -0.25 },
                    double: -0.1,
                    duplicate: -0.14,
                    duplicate_more: -0.5
                },
                'twitter:title': {
                    min: { value: 10, point: -0.5 },
                    max: { value: 55, point: -0.25 },
                    double: -0.1,
                    duplicate: -0.2,
                    duplicate_more: -0.5
                },
                'twitter:description': {
                    min: { value: 20, point: -0.5 },
                    max: { value: 160, point: -0.25 },
                    double: -0.1,
                    duplicate: -0.14,
                    duplicate_more: -0.5
                }
            };
            const c = s._metas[o];
            const metas = c.properties.filter(p => p.p === prop && p.c);
            if (metas.length === 0) {
                score += 0.0;
                c.msgs.push(`+0.0 ${prop}_missing`);
            } else if (metas.length) {
                score += max;
                c.msgs.push(`+1.0 ${prop}_ok`);
                // console.log(metas[0]);
                if (!_.isString(metas[0].c)) {
                    let point = _.get(values, [prop, 'min', 'point'], -0.5);
                    score += point;
                    c.msgs.push(`${point} ${prop}_nostring`);
                } else if (metas[0].c.length < _.get(values, [prop, 'min', 'value'], 999)) {
                    let point = _.get(values, [prop, 'min', 'point'], -0.5);
                    score += point;
                    c.msgs.push(`${point} ${prop}_length_min`);
                } else if (metas[0].c.length > _.get(values, [prop, 'max', 'value'], 0)) {
                    let point = _.get(values, [prop, 'max', 'point'], -0.25);
                    score += point;
                    c.msgs.push(`${point} ${prop}_length_max`);
                }
                if (metas.length > 1) {
                    let point = _.get(values, [prop, 'double'], -0.5);
                    score += point;
                    c.msgs.push(`${point} ${prop}_double_meta`);
                }
            }
            _.each(metas, () => {
                if (this.uniqScore[o][p][metas[0].c] !== 1) {
                    if (this.uniqScore[o][p][metas[0].c] > 2) {
                        let point = _.get(values, [prop, 'duplicate_more'], -0.5);
                        score += point;
                        c.msgs.push(`${point} ${prop}_duplicate_more`);
                    } else {
                        let point = _.get(values, [prop, 'duplicate'], -0.2);
                        score += point;
                        c.msgs.push(`${point} ${prop}_duplicate`);
                    }
                    return false;
                }
            });
            score = score < 0.0 ? 0.0 : score;
            c.score += score;
            c.max += max;
            this.uniqScore[o].score[p].count++;
            this.uniqScore[o].score[p].value += score;
            this.uniqScore[o].score[p].max += max;
        },

        _refreshScoreType(s, prop, o) {
            let score = 0;
            let max = 0.25;
            const values = {
                'og:type': ['website', 'article', 'product'],
                'twitter:card': ['summary', 'summary_large_image', 'player', 'app']
            };
            const c = s._metas[o];
            const metas = c.properties.filter(p => p.p === prop && p.c);
            if (metas.length === 0) {
                score += 0.0;
                c.msgs.push(`+0.0 ${prop}_missing`);
                // score += 0.25;
                // c.msgs.push(`+0.25 ${prop}_default`);
            } else if (metas.length) {
                score += max;
                c.msgs.push(`+0.25 ${prop}_ok`);
                if (metas.length > 1) {
                    score -= 0.05;
                    c.msgs.push(`-0.05 ${prop}_double_meta`);
                }
            }
            _.each(metas, m => {
                if (!_.indexOf(_.get(values, prop, []), m.c) === -1) {
                    score += -0.15;
                    c.msgs.push(`-0.15 ${prop}_notcorrect`);
                    return false;
                }
            });
            score = score < 0.0 ? 0.0 : score;
            c.score += score;
            c.max += max;
            this.uniqScore[o].score.type.count++;
            this.uniqScore[o].score.type.value += score;
            this.uniqScore[o].score.type.max += max;
        },

        _refreshScoreUrl(s, prop, o) {
            let score = 0;
            let max = 0.5;
            const c = s._metas[o];
            const metas = c.properties.filter(p => p.p === prop && p.c);
            if (metas.length === 0) {
                score += 0.0;
                c.msgs.push(`+0.0 ${prop}_missing`);
            } else if (metas.length) {
                score += max;
                c.msgs.push(`+0.5 ${prop}_ok`);
                if (metas.length > 1) {
                    score -= 0.0; // -0.2
                    c.msgs.push(`-0.0 ${prop}_double_meta`);
                }
            }
            _.each(metas, m => {
                if (s.url !== m.c) {
                    score += -0.0; // -0.2
                    // console.log('prop', prop, o);
                    c.msgs.push(`-0.0 ${prop}_notsame`);
                    return false;
                }
            });
            score = score < 0.0 ? 0.0 : score;
            c.score += score;
            c.max += max;
            this.uniqScore[o].score.url.count++;
            this.uniqScore[o].score.url.value += score;
            this.uniqScore[o].score.url.max += max;
        },

        _refreshScore(s, prop) {
            const p = prop.split(':');
            const o = p[0] === 'twitter' ? 'tw' : p[0];
            if (p[1] === 'title' || p[1] === 'description') {
                this._refreshScoreText(s, prop, o, p[1]);
            } else if (p[1] === 'url') {
                this._refreshScoreUrl(s, prop, o);
            } else if (prop === 'og:type' || prop === 'twitter:card') {
                this._refreshScoreType(s, prop, o);
            } else if (p[1] === 'image') {
                this._refreshScoreImage(s, prop, o);
            }
        },

        _refreshScoreAll(s) {
            s._metas.og.score = 0;
            s._metas.og.max = 0;
            s._metas.og.msgs = [];
            s._metas.tw.score = 0;
            s._metas.tw.max = 0;
            s._metas.tw.msgs = [];
            try {
                if (s.status === 'ready') {
                    this._refreshScore(s, 'og:title');
                    this._refreshScore(s, 'og:description');
                    this._refreshScore(s, 'og:url');
                    this._refreshScore(s, 'og:type');
                    this._refreshScore(s, 'og:image');

                    this._refreshScore(s, 'twitter:title');
                    this._refreshScore(s, 'twitter:description');
                    this._refreshScore(s, 'twitter:url');
                    this._refreshScore(s, 'twitter:card');
                    this._refreshScore(s, 'twitter:image');
                }
                s._metas.score = (s._metas.og.max ? s._metas.og.score / s._metas.og.max : 0) + (s._metas.tw.max ? s._metas.tw.score / s._metas.tw.max : 0);
            } catch (e) {
                console.log('err', e);
            }
        },

        _refreshUniqScore() {
            this.uniqScore = {
                og: {
                    title: {},
                    description: {},
                    image: {},
                    score: {
                        title: { value: 0, max: 0, count: 0 },
                        description: { value: 0, max: 0, count: 0 },
                        description_length: { value: 0, max: 0, count: 0 },
                        url: { value: 0, max: 0, count: 0 },
                        type: { value: 0, max: 0, count: 0 },
                        image: { value: 0, max: 0, count: 0 }
                    }
                },
                tw: {
                    title: {},
                    description: {},
                    image: {},
                    score: {
                        title: { value: 0, max: 0, count: 0 },
                        description: { value: 0, max: 0, count: 0 },
                        description_length: { value: 0, max: 0, count: 0 },
                        url: { value: 0, max: 0, count: 0 },
                        type: { value: 0, max: 0, count: 0 },
                        image: { value: 0, max: 0, count: 0 }
                    }
                }
            };
            _.each(this.sites, s => {
                _.each(s._metas.og.properties, m => {
                    if (m.p === `og:title` && m.c && _.isString(m.c)) {
                        this.uniqScore.og.title[m.c] = (this.uniqScore.og.title[m.c] || 0) + 1;
                    } else if (m.p === `og:description` && m.c && _.isString(m.c)) {
                        this.uniqScore.og.description[m.c] = (this.uniqScore.og.description[m.c] || 0) + 1;
                    }
                });
                _.each(s._metas.tw.properties, m => {
                    if (m.p === `twitter:title` && m.c && _.isString(m.c)) {
                        this.uniqScore.tw.title[m.c] = (this.uniqScore.tw.title[m.c] || 0) + 1;
                    } else if (m.p === `twitter:description` && m.c && _.isString(m.c)) {
                        this.uniqScore.tw.description[m.c] = (this.uniqScore.tw.description[m.c] || 0) + 1;
                    }
                });
                _.each(s._metas.og.images, m => {
                    if (m.p === `og:image` && m.c && _.isString(m.c)) {
                        this.uniqScore.og.image[m.c] = (this.uniqScore.og.image[m.c] || 0) + 1;
                    }
                });
                _.each(s._metas.tw.images, m => {
                    if (m.p === `twitter:image` && m.c && _.isString(m.c)) {
                        this.uniqScore.tw.image[m.c] = (this.uniqScore.tw.image[m.c] || 0) + 1;
                    }
                });
            });
        },

        _refreshScoresAll() {
            this._refreshUniqScore();
            _.each(this.sites, s => {
                this._refreshScoreAll(s);
            });
        },

        _refreshSite(s) {
            if (!_.isArray(_.get(s, 'meta.metas'))) {
                _.set(s, 'meta.metas', []);
            } else {
                s.meta.metas = _.orderBy(s.meta.metas, 'p', 'asc');
            }
            _.each(s.meta.metas, m => {
                m.l = _.filter(s.meta.metas, { p: m.p }).length;
            });
            s._metas = {
                score: 0,
                og: {
                    properties: [],
                    types: [],
                    images: []
                },
                tw: {
                    properties: [],
                    images: []
                },
                ip: {
                    properties: [],
                    images: []
                }
            };
            // add open graph - 3 first top
            _.each(['og:title', 'og:type', 'og:url'], p => {
                const metas = _.filter(s.meta.metas, { p });
                _.each(metas, m => {
                    s._metas.og.properties.push(m);
                });
                if (!metas.length) {
                    s._metas.og.properties.push({ p, c: null });
                }
            });
            _.each(['og:image'], p => {
                const metas = _.filter(s.meta.metas, { p });
                _.each(metas, m => {
                    s._metas.og.images.push(m);
                });
                if (!metas.length) {
                    s._metas.og.images.push({ p, c: null });
                }
            });
            _.each(s.meta.metas, m => {
                if (m.p.substring(0, 8) === 'og:image') {
                    if (m.p !== 'og:image') {
                        s._metas.og.images.push(m);
                    }
                } else if (m.p.substring(0, 3) === 'og:' && _.indexOf(['og:title', 'og:type', 'og:url'], m.p) === -1) {
                    s._metas.og.properties.push(m);
                }
            });

            // add twitter
            _.each(['twitter:title', 'twitter:description'], p => {
                const metas = _.filter(s.meta.metas, { p });
                _.each(metas, m => {
                    s._metas.tw.properties.push(m);
                });
                if (!metas.length) {
                    s._metas.tw.properties.push({ p, c: null });
                }
            });
            _.each(
                _.filter(s.meta.metas, m => _.indexOf(['twitter:image', 'twitter:image:src'], m.p) !== -1),
                m => {
                    s._metas.tw.images.push(m);
                }
            );
            if (!s._metas.tw.images.length) {
                s._metas.tw.images.push({ p: 'twitter:image', c: null });
            }
            _.each(s.meta.metas, m => {
                if (m.p.substring(0, 13) === 'twitter:image') {
                    if (m.p !== 'twitter:image' && m.p !== 'twitter:image:src') {
                        s._metas.tw.images.push(m);
                    }
                } else if (m.p.substring(0, 8) === 'twitter:' && _.indexOf(['twitter:title', 'twitter:description'], m.p) === -1) {
                    s._metas.tw.properties.push(m);
                }
            });

            // add types
            _.each(s.meta.metas, m => {
                if (m.p.substring(0, 3) !== 'og:' && m.p.substring(0, 8) !== 'twitter:') {
                    s._metas.ip.properties.push(m);
                }
            });
        },

        refreshSites() {
            return new Promise((resolve, reject) => {
                this.status.refreshing = true;
                axios
                    .post(`/api/project/${this.$route.params.projectID}/sites`)
                    .then(resp => {
                        this.status.refreshing = false;

                        this.project = { ...resp.data.project };
                        _.each(resp.data.sites, s => {
                            const site = _.find(this.sites, { siteID: s.siteID });
                            if (site) {
                                s._showDetails = site._showDetails;
                            } else {
                                if (s.status === 'ready' || _.has(s.meta, '[0].p')) {
                                    s._showDetails = true;
                                }
                            }
                            this._refreshSite(s);
                            if (s.updatedAt > this.lastAt) {
                                this.lastAt = s.updatedAt;
                            }
                        });
                        this.sites = [...resp.data.sites];
                        this._refreshScoresAll();
                        resolve();
                    })
                    .catch(error => {
                        this.status.refreshing = false;
                        reject(error);
                    });
            });
        },

        buttonStopSites() {
            this.status.stopping = true;
            axios
                .post(`/api/project/${this.$route.params.projectID}/stop`)
                .then(response => {
                    if (!_.get(response, 'data.success')) {
                        return this.$bvToast.toast(_.get(response, 'data.msg', 'unknown'), {
                            title: this.$t('globals.toast.warning'),
                            variant: 'warning',
                            solid: true
                        });
                    }
                    this.$bvToast.toast(this.$t('pages.sites.toast-stopping', { count: response.data.count }), {
                        title: this.$t('globals.toast.info'),
                        variant: 'info',
                        solid: true
                    });
                })
                .catch(e => {
                    this.status.stopping = false;
                    this.$bvToast.toast(e.toString(), {
                        title: this.$t('globals.toast.error'),
                        variant: 'danger',
                        solid: true
                    });
                });
        },

        progressStatus() {
            if (!this.timeout) {
                return;
            }
            if (this.statusing || this.status.crawlering) {
                this.timeInterval = 2;
                return;
            }
            this.status.statusing = true;
            axios.post(`/api/project/${this.$route.params.projectID}/status`, { lastAt: this.status.processing2 ? this.lastAt : undefined }).then(
                resp => {
                    this.status.statusing = false;
                    if (this.status.crawlering) {
                        // moment yet
                        this.timeInterval = 2;
                        return;
                    }
                    this.status.statusing2 = false;

                    this.status.stopping = _.get(resp, 'data.status.stopping', false);
                    this.status.processing2 = _.get(resp, 'data.status.processing', false);

                    this.pagescan.wait = _.get(resp, 'data.status.count.wait', 0);
                    this.pagescan.ready = _.get(resp, 'data.status.count.ready', 0);
                    this.pagescan.stop = _.get(resp, 'data.status.count.stop', 0);
                    this.pagescan.limit = _.get(resp, 'data.status.count.limit', 0);
                    this.pagescan.error = this.pagescan.value = this.pagescan.max = 0;

                    if (this.status.processing2) {
                        this.status.processing = true;
                        this.timeInterval = 2;
                        if (this.pagescan.wait) {
                            _.each(_.get(resp, 'data.status.count', {}), (s, status) => {
                                if (status !== 'deleted' && status !== 'stop' && status !== 'limit') {
                                    this.pagescan.max += s;
                                    if (status !== 'ready' && status !== 'wait') {
                                        this.pagescan.error += s;
                                    }
                                }
                            });
                            this.pagescan.value = this.pagescan.max - this.pagescan.wait;
                        }
                    } else if (this.status.processing && _.get(resp, 'data.status.finished')) {
                        this.status.processing = false;
                        this.status.finished = true;
                        _.each(_.get(resp, 'data.status.count', {}), (s, status) => {
                            if (status !== 'deleted' && status !== 'stop' && status !== 'limit') {
                                this.pagescan.max += s;
                                if (status !== 'ready' && status !== 'wait') {
                                    this.pagescan.error += s;
                                }
                            }
                        });
                        this.pagescan.value = this.pagescan.max - this.pagescan.wait;
                        this.timeInterval = 30;
                        this.refreshSites();
                        if (!this.pagescan.ready) {
                            this.logs = _.get(resp, 'data.status.logs', []);
                            if (this.logs.length) {
                                this.$bvModal.show('modal-crawler-warning');
                            } else {
                                return this.$bvToast.toast(this.$t('pages.sites.toast-ready-empty'), {
                                    title: this.$t('globals.toast.warning'),
                                    variant: 'warning',
                                    solid: true
                                });
                            }
                        }
                        setTimeout(() => {
                            this.pagescan.max = 0;
                            this.status.finished = false;
                        }, 10000);
                    } else {
                        this.status.processing = false;
                        this.timeInterval = 30;
                        this.pagescan.max = 0;
                        this.pagescan.ready = 0;
                        this.pagescan.error = 0;
                    }

                    if (_.get(resp, 'data.sites')) {
                        _.each(resp.data.sites, s => {
                            this._refreshSite(s);
                            const i = _.findIndex(this.sites, { siteID: s.siteID });
                            if (i !== -1) {
                                s._showDetails = true;
                                this.$set(this.sites, i, { ...this.sites[i], ...s });
                            } else {
                                s._showDetails = true;
                                this.sites.push(s);
                            }
                            if (s.updatedAt > this.lastAt) {
                                this.lastAt = s.updatedAt;
                            }
                        });
                    }
                },
                err => {
                    console.log('err', err);
                    this.status.statusing = false;
                    this.timeInterval = 5;
                }
            );
        }
    }
};
</script>
