import moment from 'moment';
import jsCookie from 'js-cookie';

export default {
    cookie: data => {
        if (!data) {
            try {
                data = JSON.parse(jsCookie.get(process.env.VUE_APP_COOKIE_AUTH));
            } catch (error) {
                data = {};
            }
            return data;
        }
        jsCookie.set(process.env.VUE_APP_COOKIE_AUTH, data);
    },

    validationEmail: email => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/.test(email),

    validationDateTime: datetime => /^(20[0-9][0-9])\.([0-9]?[0-9])\.([0-9]?[0-9]) ([0-9]?[0-9]):([0-9][0-9])$/.test(datetime) && moment(datetime, 'YYYY.MM.DD HH:mm').isValid(),
    // validationDateTime: datetime => /^(20[0-9][0-9])-([0-9]?[0-9])-([0-9]?[0-9]) ([0-9]?[0-9]):([0-9][0-9])$/.test(datetime) && moment(datetime, 'YYYY-MM-DD HH:mm').isValid(),

    validationUnsignedInteger: value => /^[0-9]+$/.test(value),

    replaceSearch: text => {
        return text.replace(/([[\\\]|().*?+^$])/g, '\\$1');
    }
};
