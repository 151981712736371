<template>
    <div>
        <h1 v-if="loading" class="mt-4">...</h1>
        <h1 v-if="!loading && !wait" class="mt-4">{{ $t(`pages.payment.title-${success ? 'success' : 'reject'}`) }}</h1>

        <h1 v-if="!loading && wait" class="mt-4">{{ $t('pages.payment.title-nothing_paid') }}</h1>
        <h5 v-if="!loading && wait">{{ $t('pages.payment.text-nothing_paid') }}</h5>

        <div v-if="loading" class="mb-3 mt-4 oggy-loading">
            <b-button>
                {{ $t('globals.button.loading') }}
                <i class="fa fa-spinner fa-spin fa-fw ml-2" />
            </b-button>
        </div>

        <b-card v-if="!loading" bg-variant="light" class="mb-3 mt-4">
            <b-form-group label-cols="3" :label="$t('pages.payment.label-plan')" label-align="right" class="m-0">
                <h5 class="mt-1">{{ $t(`pages.pricing.plan-${current.plan.id}`) }}</h5>
            </b-form-group>
            <b-form-group label-cols="3" :label="$t('pages.payment.label-valid_until')" label-align="right" class="m-0">
                <h5 class="mt-1">{{ getCurrentValidUntil }}</h5>
            </b-form-group>
            <b-form-group label-cols="3" :label="$t('pages.payment.label-max_users')" label-align="right" class="m-0">
                <h5 class="mt-1">
                    <b>{{ current.limitUsers || '-' }}</b>
                </h5>
            </b-form-group>
            <b-form-group label-cols="3" :label="$t('pages.payment.label-max_projects')" label-align="right" class="m-0">
                <h5 class="mt-1">
                    <b>{{ current.limitProjects || '-' }}</b>
                </h5>
            </b-form-group>
            <b-form-group label-cols="3" :label="$t('pages.payment.label-max_sites')" label-align="right" class="m-0">
                <h5 class="mt-1">
                    <b>{{ current.limitSites || '-' }}</b>
                    <span v-if="current.limitProjects > 1 && current.limitSites">&nbsp;{{ $t('pages.pricing.text-total', { total: current.limitProjects * current.limitSites || '-' }) }}</span>
                </h5>
            </b-form-group>
        </b-card>
    </div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import axios from 'axios';

export default {
    data() {
        return {
            loading: true,
            success: false,
            wait: false,
            current: {
                plan: _.find(this.$store.getters.getPlans(), { id: this.$store.getters.getClientPlanID() }) || {},
                validUntil: this.$store.getters.getClientValidUntil(),
                limitUsers: this.$store.getters.getClientMax('users'),
                limitProjects: this.$store.getters.getClientMax('projects'),
                limitSites: this.$store.getters.getClientMax('sites'),
            },
        };
    },
    computed: {
        getCurrentValidUntil() {
            return this.current.validUntil ? moment(this.current.validUntil).format('YYYY.MM.DD HH:mm') : '-';
        },
    },
    created() {
        const jwt = this.$route.query.jwt;

        console.log('this.$route.query', this.$route.query);

        // if (!jwt) {
        //     return this.$router.push('/plan');
        // }
        // if (this.$route.fullPath !== '/plan/retrieve') {
        //     this.$router.replace({ path: '/plan/retrieve' });
        // }

        this.loading = true;
        return axios
            .post(`/api/stripe/retrieve`, { jwt })
            .then((resp) => {
                console.log('retrieve', resp);
                if (!_.get(resp, 'data.success')) {
                    this.loading = false;
                    let message = _.get(resp, 'data.msg', 'unknown');
                    if (message === 'stripe_nothing_paid') {
                        this.wait = true;
                    }
                    return this.$bvToast.toast(this.$t(`pages.payment.net-${message}`), {
                        title: this.$t('globals.toast.warning'),
                        variant: 'warning',
                        solid: true,
                    });
                }
                // console.log('axios1');
                return this.$store.dispatch('initMe').then(() => {
                    // console.log('axios2', JSON.stringify(this.current, null, 2));
                    this.current = {
                        plan: _.find(this.$store.getters.getPlans(), { id: this.$store.getters.getClientPlanID() }) || {},
                        validUntil: this.$store.getters.getClientValidUntil(),
                        limitUsers: this.$store.getters.getClientMax('users'),
                        limitProjects: this.$store.getters.getClientMax('projects'),
                        limitSites: this.$store.getters.getClientMax('sites'),
                    };
                    // console.log('axios3', JSON.stringify(this.current, null, 2));
                    this.success = true;
                    this.loading = false;
                    this.$bvToast.toast(this.$t('pages.payment.tooltip-successed'), {
                        title: this.$t('globals.toast.success'),
                        variant: 'success',
                        solid: true,
                    });
                });
            })
            .catch((e) => {
                console.log('error', e);
                this.loading = false;
                this.$bvToast.toast(e.toString(), {
                    title: this.$t('globals.toast.error'),
                    variant: 'danger',
                    solid: true,
                });
            });
    },
};
</script>
