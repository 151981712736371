<template>
    <div>
        <b-row>
            <b-col offset-md="4" md="4">
                <b-card class="mt-5">
                    <h1>{{ $t('pages.verifyemail.title') }}</h1>

                    <div class="pt-3" v-html="$t(text)" />
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<style lang="scss" scoped>
@import '@/assets/scss/_variables';
</style>

<script>
import _ from 'lodash';
import axios from 'axios';

export default {
    data() {
        return {
            processing: false,
            text: false,
            form: {
                _state: null,
                jwt: ''
            }
        };
    },
    created() {
        // if (this.$store.getters.isMeRole()) {
        //     return this.$router.push('/');
        // }
        if (!_.get(this.$route.params, 'jwt')) {
            return this.$router.push('/');
        }
        this.form.jwt = this.$route.params.jwt;
        if (this.$route.fullPath !== '/verifyemail') {
            this.$router.replace({ path: '/verifyemail' });
        }

        this.processing = true;
        axios
            .put(`/api/auth/verify`, this.form)
            .then(resp => {
                this.processing = false;
                if (!_.get(resp, 'data.success')) {
                    let message = _.get(resp, 'data.msg', 'unknown');
                    this.text = `pages.verifyemail.net-${message}`;
                    return;
                }
                this.text = 'pages.verifyemail.text-success';
                // TODO: from /api/auth/verify (me/client) instead of initMe
                return this.$store.dispatch('initMe');
            })
            .catch(e => {
                this.processing = false;
                this.$bvToast.toast(e.toString(), {
                    title: this.$t('globals.toast.error'),
                    variant: 'danger',
                    solid: true
                });
            });
    }
};
</script>
