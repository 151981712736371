import Vue from 'vue';
import Vuex from 'vuex';

import tokenStore from './stores/token.store';
import localeStore from './stores/locale.store';
import meStore from './stores/me.store';
import clientStore from './stores/client.store';
import planStore from './stores/plan.store';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        token: tokenStore,
        locale: localeStore,
        me: meStore,
        client: clientStore,
        plan: planStore
    }
});
