import _ from 'lodash';

import Vue from 'vue';
import VueI18n from 'vue-i18n';

import en from './i18n/en.json';
import de from './i18n/de.json';

Vue.use(VueI18n);

export default new VueI18n({
    locale: process.env.VUE_APP_I18N_LOCALE || 'en',
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
    // dateTimeFormats: {
    //     en: {
    //         short: {
    //             year: 'numeric',
    //             month: 'short',
    //             day: 'numeric'
    //         },
    //         long: {
    //             // year: 'numeric',
    //             // month: 'short',
    //             // day: 'numeric',
    //             // weekday: 'short',
    //             // hour: 'numeric',
    //             // minute: 'numeric',
    //             // hour12: false
    //             year: 'numeric',
    //             month: 'numeric',
    //             day: 'numeric',
    //             format: 'YYYY.MM.DD'
    //         }
    //     }
    // },
    messages: {
        en,
        de
    },
    silentFallbackWarn: true
});
