<template>
    <div>
        <h1 class="mt-4">{{ $t('pages.users.title') }}</h1>

        <div v-if="loading" class="mb-3 mt-4 oggy-loading">
            <b-button>
                {{ $t('globals.button.loading') }}
                <i class="fa fa-spinner fa-spin fa-fw ml-2" />
            </b-button>
        </div>

        <div v-if="!loading" class="mb-3">
            <b-row class="mb-2 mt-4">
                <b-col cols="9">
                    <!-- <b-button variant="primary" @click="buttonGoHome"><i class="fa fa-home mr-2" />Home</b-button> -->
                    <span
                        v-b-tooltip.top
                        class="d-inline-block"
                        :title="isRequireVerifyMail ? $t('pages.users.tooltip-noverification') : isNoLongerValid || isLimitUser ? $t('pages.users.tooltip-limited') : ''"
                    >
                        <b-button v-if="$store.getters.isMeRole(['admin'])" :disabled="isRequireVerify" variant="primary" class="ml-2" @click="$refs.modalUser.buttonAddUser">
                            <i class="fa fa-plus mr-2" />
                            {{ $t('pages.users.button-add_user') }}
                        </b-button>
                    </span>
                </b-col>
                <b-col cols="3">
                    <b-input-group class="oggy-search mb-3">
                        <b-form-input v-model="search" trim :placeholder="$t('globals.input.placeholder-search')" />
                        <b-input-group-append>
                            <b-input-group-text><i class="fa fa-search text-secondary" /></b-input-group-text>
                        </b-input-group-append>
                    </b-input-group>
                </b-col>
            </b-row>

            <b-pagination
                v-if="users.length > perPage"
                v-model="curPage"
                class="oggy-pagination"
                :total-rows="filteredUsers.length"
                :per-page="perPage"
                align="center"
                :first-text="$t('globals.pagination.first')"
                :prev-text="$t('globals.pagination.prev')"
                :next-text="$t('globals.pagination.next')"
                :last-text="$t('globals.pagination.last')"
                limit="10"
            />

            <b-table class="oggy-table" fixed sort-icon-left :per-page="perPage" :current-page="curPage" :items="filteredUsers" :fields="fields">
                <template v-slot:head(email)>
                    {{ $t('pages.users.th-email') }}
                </template>
                <template v-slot:head(role)>
                    {{ $t('pages.users.th-role') }}
                </template>
                <template v-slot:head(actions)>
                    {{ $t('pages.users.th-actions') }}
                </template>

                <template v-slot:cell(email)="u">
                    <i v-if="u.item.disabledAt" class="fa fa-ban mr-2 text-danger" />
                    <span :class="{ 'text-strike': u.item._index >= limitUser, 'opacity-50': isRequireVerifyMail || isNoLongerValid || u.item._index >= limitUser }">
                        {{ u.item.email }}
                    </span>
                </template>

                <template v-slot:cell(actions)="u">
                    <div v-if="u.item.userID !== $store.getters.getMeUserID()" class="text-right ">
                        <b-button
                            class="ml-1"
                            variant="text"
                            :disabled="isRequireVerifyMail || isNoLongerValid || u.item._index >= limitUser"
                            @click="$refs.modalUser.buttonEditUser(u.item, $event.target)"
                        >
                            <i class="fa fa-edit" />
                        </b-button>
                        <b-button :disabled="isRequireVerifyMail" class="ml-1" variant="text" @click="$refs.modalUser.buttonDeleteUser(u.item, $event.target)"><i class="fa fa-trash" /></b-button>
                        <b-button
                            class="ml-1"
                            variant="text"
                            :disabled="u.item._processing || isRequireVerifyMail || isNoLongerValid || u.item._index >= limitUser"
                            @click="$refs.modalUser.buttonDisableUser(u.item, $event.target)"
                        >
                            <i v-if="u.item.disabledAt" class="fa fa-minus-square text-danger" />
                            <i v-if="!u.item.disabledAt" class="fa fa-check-square" />
                        </b-button>
                    </div>
                </template>
            </b-table>

            <b-pagination
                v-if="users.length > perPage"
                v-model="curPage"
                class="oggy-pagination"
                :total-rows="filteredUsers.length"
                :per-page="perPage"
                align="center"
                :first-text="$t('globals.pagination.first')"
                :prev-text="$t('globals.pagination.prev')"
                :next-text="$t('globals.pagination.next')"
                :last-text="$t('globals.pagination.last')"
                limit="10"
            />
        </div>

        <ModalUser ref="modalUser" @editedUser="onEditedUser" @addedUser="onAddedUser" @deletedUser="onDeletedUser" />
    </div>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import t from '@/tool';

import ModalUser from '@/components/modalUser.vue';

export default {
    components: {
        ModalUser
    },
    data() {
        return {
            loading: false,
            search: '',
            curPage: 1,
            perPage: 20,
            fields: [
                { key: 'email', label: 'Email', sortable: true, sortDirection: 'asc' },
                { key: 'role', label: 'Role', sortable: true, sortDirection: 'asc', thStyle: 'width: 4rem' }
            ],
            users: []
        };
    },
    computed: {
        limitUser() {
            return this.$store.getters.getClientMax('users');
        },
        isLimitUser() {
            return this.limitUser <= this.users.length; //.filter(u => !u.disabledAt).length;
        },
        isNoLongerValid() {
            return !this.$store.getters.isClientValidUntil();
        },
        isRequireVerifyMail() {
            return this.$store.getters.isMeRequireVerify();
        },
        isRequireVerify() {
            return this.isRequireVerifyMail || this.isNoLongerValid || this.isLimitUser;
        },
        filteredUsers() {
            const search = t.replaceSearch(this.search);
            return this.users.filter(u => _.toString(u.email).match(search));
        }
    },
    created() {
        if (this.$store.getters.isMeRole(['admin'])) {
            this.fields.push({ key: 'actions', thStyle: 'width: 9rem' });
        }
        this.loading = true;
        axios
            .get(`/api/users`)
            .then(response => {
                this.loading = false;
                this.users = response.data.users;
                this.refreshIndex();
            })
            .catch(e => {
                this.loading = false;
            });
    },
    methods: {
        refreshIndex() {
            this.users
                .sort((a, b) => (a.userID > b.userID ? 1 : -1))
                .map((u, i) => {
                    u._index = i;
                });
        },

        buttonGoHome() {
            this.$router.push('/');
        },

        onAddedUser(user) {
            this.users.push({ ...user });
            this.refreshIndex();
        },

        onEditedUser(user) {
            const index = _.findIndex(this.users, { userID: user.userID });
            this.$set(this.users, index, { ...this.users[index], ...user });
        },

        onDeletedUser(user) {
            this.$delete(this.users, _.findIndex(this.users, { userID: user.userID }));
            this.refreshIndex();
        }
    }
};
</script>
