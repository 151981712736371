<template>
    <div>
        <b-modal id="modal-preview-og" :title="$t('components.modal-preview-og.header-preview')" size="lg" hide-header-close>
            <div align="center">
                <b-form-group label="Select for card preview">
                    <b-form-radio-group v-model="form.type" :options="types" name="radio-options"></b-form-radio-group>
                </b-form-group>
            </div>

            <BoxPreviewOg :site="site" :type="form.type" :img-lazy="false" />

            <!-- <p>{{ site }}</p> -->

            <template slot="modal-footer" slot-scope="{ ok }">
                <!-- <b-button @click="cancel()">{{ $t('globals.button.cancel') }}</b-button> -->
                <b-button @click="ok">{{ $t('globals.button.ok') }}</b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>
// https://developer.twitter.com/en/docs/tweets/optimize-with-cards/overview/markup
// https://developer.twitter.com/en/docs/tweets/optimize-with-cards/guides/troubleshooting-cards
// https://developers.facebook.com/docs/sharing/webmasters/#markup

import BoxPreviewOg from './boxPreviewOg.vue';

export default {
    components: {
        BoxPreviewOg
    },
    data() {
        return {
            processing: false,
            types: [
                { text: 'Facebook', value: 'facebook' },
                { text: 'Twitter', value: 'twitter' }
            ],
            site: {},
            form: {
                type: 'facebook'
            }
        };
    },
    methods: {
        buttonPreview(site) {
            this.site = site;
            this.$bvModal.show('modal-preview-og');
        }
    }
};
</script>
