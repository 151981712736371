import Vue from 'vue';
import * as Sentry from '@sentry/browser';
import { Vue as VueIntegration } from '@sentry/integrations';

if (process.env.VUE_APP_SENTRY_DSN) {
    Sentry.init({
        dsn: process.env.VUE_APP_SENTRY_DSN,
        integrations: [new VueIntegration({ Vue, attachProps: true })]
    });
}

import VueLodash from 'vue-lodash';
// import VueMoment from 'vue-moment';
import BrowserDetect from 'vue-browser-detect-plugin';
import BootstrapVue from 'bootstrap-vue';
import VueMasonry from 'vue-masonry-css'; // grid-view

import lodash from 'lodash';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'font-awesome/css/font-awesome.min.css';
import './assets/scss/bootstrap.scss';
import './assets/scss/fontawesome.scss';

import router from './router';
import store from './store';
import i18n from './i18n';
import './auth';

import Layout from './layout.vue';

Vue.use(VueLodash, { lodash });

// Vue.use(VueMoment);
Vue.use(BrowserDetect);
Vue.use(BootstrapVue);
Vue.use(VueMasonry);

Vue.config.productionTip = false;

store
    .dispatch('initMe')
    .then(() => {
        return new Vue({
            router,
            store,
            i18n,
            render: h => h(Layout)
        }).$mount('#app');
    })
    .catch(error => {
        console.log('error', error);
    });
