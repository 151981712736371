<template>
    <masonry :cols="{ default: 3, 1200: 2, 800: 1 }" :gutter="{ default: '30px' }">
        <div v-for="(s, index) in filteredSites2" :key="index" class="mb-5">
            <div :class="s._processing || s.status === 'wait' ? 'opacity-25' : ''">
                <div>
                    <div class="float-right">
                        <span v-if="!s._processing && s.status === 'ready'" class="text-success font-weight-bold">
                            <i class="fa fa-check-circle-o mr-1" />
                            {{ $t('pages.sites.text-ready') }}
                        </span>
                        <span v-if="!s._processing && s.status === 'wait'" class="text-secondary font-weight-bold">
                            <i class="fa fa-clock-o fa-spin fa-fw mr-1" />
                            {{ $t('pages.sites.text-wait') }}
                        </span>
                        <span v-if="!s._processing && s.status !== 'ready' && s.status !== 'wait'" class="text-danger font-weight-bold">
                            <i class="fa fa-exclamation-circle mr-1" />
                            {{ s.status }}
                        </span>

                        <b-button
                            v-if="showUpdateSitemap"
                            class="pl-2 pr-0"
                            :class="{ 'cursor-auto opacity-25': isRequireVerify }"
                            variant="text"
                            :disabled="s._processing || isRequireVerify"
                            @click="buttonUpdateSitemap(s)"
                        >
                            <i :class="s._processing ? 'fa fa-spinner fa-spin fa-fw' : 'fa fa-refresh fa-fw'" />
                        </b-button>
                    </div>
                    <div class="text-truncate">
                        <b-button class="pl-0 pr-2" variant="text" @click="buttonPreviewOg(s)"><i class="fa fa-eye fa-fw" /></b-button>
                        <a :href="s.url" target="_blank">{{ s.url }}</a>
                    </div>
                </div>

                <BoxPreviewOg :site="s" :type="selectedLayoutGrid" />
            </div>
        </div>
    </masonry>
</template>

<style lang="scss" scoped>
.btn.btn-text:focus {
    box-shadow: none;
}
</style>

<script>
// https://developer.twitter.com/en/docs/tweets/optimize-with-cards/overview/markup
// https://developer.twitter.com/en/docs/tweets/optimize-with-cards/guides/troubleshooting-cards
// https://developers.facebook.com/docs/sharing/webmasters/#markup

import _ from 'lodash';

import BoxPreviewOg from './boxPreviewOg.vue';

export default {
    components: {
        BoxPreviewOg
    },
    filters: {
        truncate(string, len) {
            if (string.length > len) {
                return string.substring(0, len) + '…';
            }
            return string;
        },
        decodeHTML(html) {
            //return html;
            try {
                var txt = document.createElement('textarea');
                txt.innerHTML = html;
                return txt.value;
            } catch (e) {
                return html;
            }
        }
    },
    props: {
        perPage: {
            type: Number,
            required: true
        },
        curPage: {
            type: Number,
            required: true
        },
        filteredSites: {
            type: Array,
            required: true
        },
        selectedLayoutGrid: {
            type: String,
            required: true
        },
        buttonPreviewOg: {
            type: Function,
            default: () => {}
        },
        buttonUpdateSitemap: {
            type: Function,
            default: () => {}
        }
    },
    data() {
        return {
            showUpdateSitemap: false
        };
    },
    computed: {
        isRequireVerify() {
            return this.$store.getters.isMeRequireVerify() || !this.$store.getters.isClientValidUntil();
        },
        filteredSites2() {
            const start = (_.max([this.curPage, 1]) - 1) * this.perPage;
            return _.slice(this.filteredSites, start, start + this.perPage);
        }
    },
    created() {
        this.showUpdateSitemap = this.$store.getters.isMeRole(['admin', 'user']);
    }
};
</script>
