<template>
    <div>
        <b-row>
            <b-col offset-md="4" md="4">
                <b-card class="mt-5">
                    <h1>{{ $t('pages.passwordchange.title') }}</h1>

                    <b-form v-if="!sent" class="oggy-form" @keydown.enter.prevent="submit">
                        <b-form-group :label="$t('components.modal-user.label-password')" label-for="password-text">
                            <b-form-input
                                id="password-text"
                                v-model="form.password"
                                type="password"
                                :state="validationPasswordComputed"
                                :placeholder="$t('components.modal-user.placeholder-password')"
                            />
                            <b-form-invalid-feedback :state="validationPasswordComputed">{{ $t('components.modal-user.validation-password') }}</b-form-invalid-feedback>
                        </b-form-group>

                        <b-form-group :label="$t('components.modal-user.label-password2')" label-for="password2-text">
                            <b-form-input
                                id="password2-text"
                                v-model="form.password2"
                                type="password"
                                :state="validationPassword2Computed"
                                :placeholder="$t('components.modal-user.placeholder-password2')"
                            />
                            <b-form-invalid-feedback :state="validationPassword2Computed">{{ $t('components.modal-user.validation-password2') }}</b-form-invalid-feedback>
                        </b-form-group>

                        <b-form-group class="text-right oggy-loading">
                            <b-button :disabled="processing" variant="primary" @click="submit">
                                {{ $t('pages.passwordchange.button-change') }}
                                <i v-if="!processing" class="fa fa-key ml-2" />
                                <i v-if="processing" class="fa fa-spinner fa-spin fa-fw ml-2" />
                            </b-button>
                        </b-form-group>
                    </b-form>
                    <div v-else class="pt-3" v-html="$t(sent)" />
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<style lang="scss" scoped>
@import '@/assets/scss/_variables';
</style>

<script>
import _ from 'lodash';
import axios from 'axios';

export default {
    data() {
        return {
            processing: false,
            sent: false,
            form: {
                _state: null,
                jwt: '',
                password: '',
                password2: ''
            }
        };
    },
    computed: {
        validationPasswordComputed() {
            return this.validationPassword() || this.form._state;
        },
        validationPassword2Computed() {
            return this.validationPassword2() || this.form._state;
        },
        validationSubmitComputed() {
            return this.validationSubmit();
        }
    },
    created() {
        if (this.$store.getters.isMeRole()) {
            return this.$router.push('/');
        }
        if (!_.get(this.$route.params, 'jwt')) {
            return this.$router.push('/');
        }
        this.form.jwt = this.$route.params.jwt;
        if (this.$route.fullPath !== '/changepassword') {
            this.$router.replace({ path: '/changepassword' });
        }
    },
    methods: {
        validationPassword() {
            const len = _.size(_.get(this, 'form.password', ''));
            return 8 <= len && len <= 20;
        },
        validationPassword2() {
            return this.validationPassword() && _.get(this, 'form.password2', '') === _.get(this, 'form.password', '');
        },
        validationSubmit() {
            this.form._state = false;
            return this.validationPassword() && this.validationPassword2();
        },

        submit() {
            if (this.processing) {
                return;
            }

            if (!this.validationSubmit()) {
                return;
            }

            this.processing = true;
            axios
                .put(`/api/auth/passwordreset`, this.form)
                .then(resp => {
                    this.processing = false;
                    if (!_.get(resp, 'data.success')) {
                        let message = _.get(resp, 'data.msg', 'unknown');
                        if (message === 'jwt_expired') {
                            this.sent = 'pages.passwordchange.net-jwt_expired';
                            return;
                        }
                        return this.$bvToast.toast(this.$t(`pages.passwordchange.net-${message}`, { email: resp.data.email }), {
                            title: this.$t('globals.toast.warning'),
                            variant: 'warning',
                            solid: true
                        });
                    }
                    this.sent = 'pages.passwordchange.text-sent';
                })
                .catch(e => {
                    this.processing = false;
                    this.$bvToast.toast(e.toString(), {
                        title: this.$t('globals.toast.error'),
                        variant: 'danger',
                        solid: true
                    });
                });
        }
    }
};
</script>
