<template>
    <div>
        <b-row>
            <b-col offset-md="4" md="4">
                <b-card class="mt-5">
                    <h1>{{ $t('pages.signup.title') }}</h1>

                    <b-form class="oggy-form" @keydown.enter.prevent="submit">
                        <b-form-group :label="$t('components.modal-user.label-email')" label-for="email-text">
                            <b-form-input id="email-text" v-model="form.email" type="email" trim :state="validationEmailComputed" :placeholder="$t('components.modal-user.placeholder-email')" />
                            <b-form-invalid-feedback :state="validationEmailComputed">{{ $t('components.modal-user.validation-email') }}</b-form-invalid-feedback>
                        </b-form-group>

                        <b-form-group :label="$t('components.modal-user.label-password')" label-for="password-text">
                            <b-form-input
                                id="password-text"
                                v-model="form.password"
                                type="password"
                                :state="validationPasswordComputed"
                                :placeholder="$t('components.modal-user.placeholder-password')"
                            />
                            <b-form-invalid-feedback :state="validationPasswordComputed">{{ $t('components.modal-user.validation-password') }}</b-form-invalid-feedback>
                        </b-form-group>

                        <b-form-group :label="$t('components.modal-user.label-password2')" label-for="password2-text">
                            <b-form-input
                                id="password2-text"
                                v-model="form.password2"
                                type="password"
                                :state="validationPassword2Computed"
                                :placeholder="$t('components.modal-user.placeholder-password2')"
                            />
                            <b-form-invalid-feedback :state="validationPassword2Computed">{{ $t('components.modal-user.validation-password2') }}</b-form-invalid-feedback>
                        </b-form-group>

                        <b-form-group class="text-right oggy-loading">
                            <b-button :disabled="processing" variant="primary" @click="submit">
                                {{ $t('pages.signup.button-create') }}
                                <i v-if="!processing" class="fa fa-user-plus ml-2" />
                                <i v-if="processing" class="fa fa-spinner fa-spin fa-fw ml-2" />
                            </b-button>
                        </b-form-group>
                    </b-form>

                    <hr />

                    {{ $t('pages.signup.text-account') }}
                    <b-link :to="{ path: '/login', query: { redirect: $route.query.redirect } }">
                        <b>{{ $t('pages.signup.link-signin') }}</b>
                    </b-link>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<style lang="scss" scoped>
@import '@/assets/scss/_variables';
</style>

<script>
import _ from 'lodash';
import axios from 'axios';
import t from '@/tool';

export default {
    data() {
        return {
            processing: false,
            form: {
                _state: null,
                email: '',
                password: '',
                password2: '',
                remember: false
            }
        };
    },
    computed: {
        validationEmailComputed() {
            return this.validationEmail() || this.form._state;
        },
        validationPasswordComputed() {
            return this.validationPassword() || this.form._state;
        },
        validationPassword2Computed() {
            return this.validationPassword2() || this.form._state;
        },
        validationSubmitComputed() {
            return this.validationSubmit();
        }
    },
    created() {
        if (this.$store.getters.isMeRole()) {
            return this.$router.push('/');
        }
    },
    methods: {
        validationEmail() {
            const email = _.get(this, 'form.email', '');
            const len = _.size(email);
            return 5 <= len && len <= 30 && t.validationEmail(email);
        },
        validationPassword() {
            const len = _.size(_.get(this, 'form.password', ''));
            return 8 <= len && len <= 20;
        },
        validationPassword2() {
            return this.validationPassword() && _.get(this, 'form.password2', '') === _.get(this, 'form.password', '');
        },
        validationSubmit() {
            this.form._state = false;
            return this.validationEmail() && this.validationPassword() && this.validationPassword2();
        },

        submit() {
            if (this.processing) {
                return;
            }

            if (!this.validationSubmit()) {
                return;
            }

            this.processing = true;
            axios
                .post(`/api/auth/registration`, this.form)
                .then(resp => {
                    this.processing = false;
                    if (!_.get(resp, 'data.success')) {
                        let message = _.get(resp, 'data.msg', 'unknown');
                        return this.$bvToast.toast(this.$t(`components.modal-user.net-${message}`, { email: resp.data.email }), {
                            title: this.$t('globals.toast.warning'),
                            variant: 'warning',
                            solid: true
                        });
                        // return this.$bvToast.toast(this.$t('pages.signin.toast-' + _.get(resp, 'data.msg', 'unknown')), {
                        //     title: this.$t('globals.toast.warning'),
                        //     variant: 'warning',
                        //     solid: true
                        //     // noAutoHide : true
                        // });
                    }
                    if (!_.get(resp, 'headers.x-oggy-token')) {
                        return this.$bvToast.toast(this.$t('pages.signin.toast-token-missing'), {
                            title: this.$t('globals.toast.warning'),
                            variant: 'warning',
                            solid: true
                        });
                    }
                    this.$store.dispatch('commitMe', {
                        token: resp.headers['x-oggy-token'],
                        me: resp.data.me || false,
                        client: resp.data.client || false,
                        locale: _.get(resp, 'data.me.meta.locale') ? resp.data.me.meta.locale : undefined
                    });
                    // this.$store.commit('setToken', resp.headers['x-oggy-token']);
                    // this.$store.commit('setMe', resp.data.me || false);
                    // this.$store.commit('setClient', resp.data.client || false);
                    // if (_.get(resp, 'data.me.meta.locale')) {
                    //     this.$store.commit('setLocale', resp.data.me.meta.locale);
                    // }
                    this.$router.push('/projects').then(
                        () => {
                            this.$bvToast.toast(this.$t('pages.signin.toast-login', { email: resp.data.me.email }), {
                                title: this.$t('globals.toast.info'),
                                variant: 'info',
                                solid: true
                            });
                        },
                        error => {
                            console.log('error', error);
                        }
                    );
                })
                .catch(e => {
                    this.processing = false;
                    this.$bvToast.toast(e.toString(), {
                        title: this.$t('globals.toast.error'),
                        variant: 'danger',
                        solid: true
                    });
                });
        }
    }
};
</script>
