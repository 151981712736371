<template>
    <div />
</template>

<script>
import _ from 'lodash';

export default {
    created() {
        this.$store.dispatch('commitMe', {
            token: false,
            me: false,
            client: false
        });
        // this.$store.commit('setToken', false);
        // this.$store.commit('setMe', false);
        // this.$store.commit('setClient', false);

        const query = this.$route.query || {};
        delete query.token;

        const t = {
            // defective and keep translate for caching
            info: this.$t('globals.toast.info'),
            warning: this.$t('globals.toast.warning'),
            'toast-logout': this.$t('pages.logout.toast-logout'),
            'toast-expired': this.$t('pages.logout.toast-expired')
        };
        this.$router.push({ path: '/login', query }).then(() => {
            if (_.get(this.$route.query, 'token') === 'expired') {
                return this.$bvToast.toast(t['toast-expired'], {
                    title: t['warning'],
                    variant: 'warning',
                    solid: true
                });
            } else {
                this.$bvToast.toast(t['toast-logout'], {
                    title: t['info'],
                    variant: 'info',
                    solid: true
                });
            }
        }, console.error);
    }
};
</script>
