<template>
    <!-- <img :src="src" :class="iClass" :style="[iWidth ? `width: ${iWidth}` : '', iMaxHeight ? `max-height: ${iMaxHeight}` : ''].join('; ')" /> -->
    <svg class="pie-progress" viewBox="0 0 100 100">
        <circle cx="50" cy="50" :r="49.8" fill="rgba(255,255,255,0.9375)" />
        <circle cx="50" cy="50" :r="r" stroke="rgba(170,170,170,0.5)" fill="none" stroke-width="19.8" />
        <circle class="progress" cx="50" cy="50" :r="r" :stroke="stroke" fill="none" stroke-width="20" :style="`stroke-dasharray: ${st1} ${st2}; stroke-dashoffset: ${st3}`" />
        <!-- <circle class="progress" cx="50" cy="50" :r="r" :stroke="stroke" fill="none" stroke-width="20" :style="`${st1 ? `stroke-dasharray: ${st1} ${st2}` : ''}`" /> -->
        <!-- <text x="50" y="50" text-anchor="middle" alignment-baseline="central" v-html="text" /> -->
        <text x="50" dy="60" text-anchor="middle" v-html="text" />
    </svg>
</template>

<style lang="scss" scoped>
svg {
    // box-shadow: 0px 0px 5px #000;
    -webkit-filter: drop-shadow(0px 0px 5px #0007);
    filter: drop-shadow(0px 0px 5px #0007);

    text {
        font-size: 1.5rem;
        font-weight: bold;
        // tspan {
        //     font-size: 2rem;
        // }
    }
    circle.progress {
        transition: all 1s ease-in-out;
        // stroke-dasharray: 0 999;
        // stroke-dashoffset: 0;
        // stroke: #f44336;
    }
}
</style>

<script>
import _ from 'lodash';

export default {
    props: {
        iValue: {
            type: Number,
            require: true,
            default: 1
        },
        iMax: {
            type: Number,
            default: 1
        }
    },
    data() {
        return {
            r: 40,
            pi: 3.14159265,
            st1: 0,
            st2: 0,
            st3: 0,
            stroke: '#f44336',
            text: ''
        };
    },
    // computed: {
    //     computedProperty() {
    //         return [this.iValue, this.iMax];
    //     }
    // },
    watch: {
        //     // compoundProperty() {
        //     //     this.reload();
        //     // }
        //     ...mapWatchers(['iValue', 'iMax'], () => {
        //         this.reload();
        //     })
        iValue() {
            this.reload();
        },
        iMax() {
            this.reload();
        }
    },
    mounted() {
        // this.$watchAll(['iValue', 'iMax'], this.reload);
        this.refreshValue(0);
        this.reload();
    },
    methods: {
        reload() {
            setTimeout(() => {
                this.refreshValue(_.min([1, _.max([0, this.iValue / this.iMax])]));
            });
        },
        refreshValue(v) {
            const f = 2 * this.pi * this.r;
            this.st1 = f * v;
            this.st2 = f - this.st1;
            this.st3 = this.st1 + f / 2;
            if (v >= 0.8) {
                this.stroke = '#3daf2c';
            } else if (v >= 0.5) {
                this.stroke = '#ffd81a';
            } else if (v >= 0.3) {
                this.stroke = '#ff8d1a';
            } else {
                this.stroke = '#f44336';
            }
            // this.text = `${_.round(v * 100)}<tspan alignment-baseline="middle" style="font-size: 1rem">%</tspan>`;
            this.text = `${_.round(v * 100)}<tspan dy="-10" style="font-size: 1rem">%</tspan>`;
        }
    }
};
</script>
