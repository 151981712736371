<template>
    <div>
        <b-row>
            <b-col offset-md="4" md="4">
                <b-card class="mt-5">
                    <h1>{{ $t('pages.signin.title') }}</h1>

                    <b-form class="oggy-form" @keydown.enter.prevent="login">
                        <b-form-group :label="$t('components.modal-user.label-email')" label-for="email-text">
                            <b-form-input id="email-text" v-model="form.email" type="email" trim :placeholder="$t('components.modal-user.placeholder-email')" />
                            <b-form-invalid-feedback :state="form._email || validationEmailComputed">{{ $t('pages.signin.invalid-email') }}</b-form-invalid-feedback>
                        </b-form-group>

                        <b-form-group :label="$t('components.modal-user.label-password')" label-for="password-text">
                            <b-form-input id="password-text" v-model="form.password" type="password" :placeholder="$t('components.modal-user.placeholder-password')" />
                            <b-form-invalid-feedback :state="form._password || validationPasswordComputed">{{ $t('pages.signin.invalid-password') }}</b-form-invalid-feedback>
                        </b-form-group>

                        <!-- <b-form-group>
                            <b-form-checkbox v-model="form.remember">Remember</b-form-checkbox>
                        </b-form-group> -->

                        <b-form-group class="text-right oggy-loading">
                            <b-link to="/forgotpassword" class="float-left mt-2">
                                <b>{{ $t('pages.signin.link-forgot_password') }}</b>
                            </b-link>
                            <b-button :disabled="processing" variant="primary" @click="login">
                                {{ $t('pages.signin.button-login') }}
                                <i v-if="!processing" class="fa fa-sign-in ml-2" />
                                <i v-if="processing" class="fa fa-spinner fa-spin fa-fw ml-2" />
                            </b-button>
                        </b-form-group>
                    </b-form>

                    <hr />

                    {{ $t('pages.signin.text-notaccount') }}
                    <b-link to="/registration">
                        <b>{{ $t('pages.signin.link-createone') }}</b>
                    </b-link>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';

export default {
    data() {
        return {
            processing: false,
            form: {
                email: '',
                _email: true,
                password: '',
                _password: true,
                remember: false
            }
        };
    },
    computed: {
        validationEmailComputed() {
            return this.validationEmail();
        },
        validationPasswordComputed() {
            return this.validationPassword();
        }
    },
    created() {
        if (this.$store.getters.isMeRole()) {
            return this.$router.push('/');
        }
    },
    methods: {
        validationEmail() {
            const len = _.size(this.form.email);
            this.form._email = this.form._email || 5 <= len;
            return 5 <= len;
        },
        validationPassword() {
            const len = _.size(this.form.password);
            this.form._password = this.form._password || 5 <= len;
            return 5 <= len;
        },
        login() {
            if (!this.validationEmail() || !this.validationPassword()) {
                this.form._email = false;
                this.form._password = false;
                return;
            }
            if (this.processing) {
                return;
            }
            this.processing = true;

            axios
                .post('/api/auth/login', this.form)
                .then(resp => {
                    this.processing = false;
                    if (!_.get(resp, 'data.success')) {
                        return this.$bvToast.toast(this.$t('pages.signin.toast-' + _.get(resp, 'data.msg', 'unknown')), {
                            title: this.$t('globals.toast.warning'),
                            variant: 'warning',
                            solid: true
                            // noAutoHide : true
                        });
                    }
                    if (!_.get(resp, 'headers.x-oggy-token')) {
                        return this.$bvToast.toast(this.$t('pages.signin.toast-token-missing'), {
                            title: this.$t('globals.toast.warning'),
                            variant: 'warning',
                            solid: true
                        });
                    }
                    this.$store.dispatch('commitMe', {
                        token: resp.headers['x-oggy-token'],
                        me: resp.data.me || false,
                        client: resp.data.client || false,
                        ...(_.get(resp, 'data.me.meta.locale') ? { locale: resp.data.me.meta.locale } : {})
                        // locale: _.get(resp, 'data.me.meta.locale') ? resp.data.me.meta.locale : undefined
                    });
                    // this.$store.commit('setToken', resp.headers['x-oggy-token']);
                    // this.$store.commit('setMe', resp.data.me || false);
                    // this.$store.commit('setClient', resp.data.client || false);
                    // if (_.get(resp, 'data.me.meta.locale')) {
                    //     this.$store.commit('setLocale', resp.data.me.meta.locale);
                    // }
                    const redirect = _.get(this.$route.query, 'redirect') || (this.$store.getters.isMeRole('superadmin') ? '/clients' : '/projects');
                    this.$router.push(redirect).then(
                        () => {
                            this.$bvToast.toast(this.$t('pages.signin.toast-login', { email: resp.data.me.email }), {
                                title: this.$t('globals.toast.info'),
                                variant: 'info',
                                solid: true
                            });
                        },
                        error => {
                            console.log('error', error);
                        }
                    );
                })
                .catch(e => {
                    this.processing = false;
                    this.$bvToast.toast(e.toString(), {
                        title: this.$t('globals.toast.error'),
                        variant: 'danger',
                        solid: true
                    });
                });
        }
    }
};
</script>
