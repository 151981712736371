<template>
    <div>
        <b-modal id="modal-default-payment_method" :title="$t('globals.modal.warning')" hide-header-close>
            <p class="mb-4" v-html="$t('components.modal-payment_method.default-description-last4', { last4: _.get(form, 'card.last4', '???? ') })" />
            <template slot="modal-footer" slot-scope="{ cancel }">
                <b-button @click="cancel()">{{ $t('globals.button.cancel') }}</b-button>
                <b-button :disabled="processing" variant="danger" @click.prevent="defaultPaymentMethod">
                    {{ $t('components.modal-payment_method.button-default_payment_method') }}
                    <i v-if="processing" class="fa fa-spinner fa-spin fa-fw ml-2" />
                </b-button>
            </template>
        </b-modal>

        <b-modal id="modal-delete-payment_method" :title="$t('globals.modal.warning')" hide-header-close>
            <p class="mt-4">{{ $t('components.modal-payment_method.delete-description-remove') }}</p>
            <p class="mb-4" v-html="$t('components.modal-payment_method.delete-description-last4', { last4: _.get(form, 'card.last4', '???? ') })" />
            <template slot="modal-footer" slot-scope="{ cancel }">
                <b-button @click="cancel()">{{ $t('globals.button.cancel') }}</b-button>
                <b-button :disabled="processing" variant="danger" @click.prevent="deletePaymentMethod">
                    {{ $t('components.modal-payment_method.button-remove_payment_method') }}
                    <i v-if="processing" class="fa fa-spinner fa-spin fa-fw ml-2" />
                </b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import moment from 'moment';

export default {
    data() {
        return {
            modal: false,
            processing: false,
            // plans: this.$store.getters.getPlans().map(p => {
            //     p.value = p.id;
            //     p.text = this.$t(`pages.pricing.plan-${p.id}`); // refresh i18n in the computed if it is different
            //     return p;
            // }),
            // durations: [
            //     { value: 'month', text: this.$t('pages.payment.checkbox-month') },
            //     { value: 'year', text: this.$t('pages.payment.checkbox-year') },
            //     { value: 'none', text: this.$t('pages.payment.checkbox-none') }
            // ],
            form: {}
        };
    },

    methods: {
        getDateFormat: date => moment(date).format('YYYY.MM.DD HH:mm'),
        // buttonDisableSubscription(subscription) {
        //     const form = { ...subscription };
        //     form.disabledAt = !form.disabledAt;

        //     subscription._processing = true;
        //     axios
        //         .put(`/api/superadmin/subscription/${form.clientID}`, form)
        //         .then(resp => {
        //             subscription._processing = false;
        //             this.$bvModal.hide('modal-subscription');
        //             this.$emit('editedSubscription', resp.data.subscription);
        //         })
        //         .catch(e => {
        //             subscription._processing = false;
        //             this.$bvToast.toast(e.toString(), {
        //                 title: this.$t('globals.toast.error'),
        //                 variant: 'danger',
        //                 solid: true
        //             });
        //         });
        // },

        // buttonEditSubscription(subscription) {
        //     this.form = { ...subscription };
        //     this.$bvModal.show('modal-edit-subscription');
        // },

        buttonDefaultPaymentMethod(paymentMethod) {
            this.form = { ...paymentMethod };
            this.$bvModal.show('modal-default-payment_method');
        },
        async defaultPaymentMethod() {
            if (!_.get(this, 'form.paymentMethodID')) {
                return;
            }
            this.processing = true;
            try {
                const resp = await axios.patch(`/api${this.$store.getters.isMeRole('superadmin') ? `/superadmin` : '/stripe'}/paymentmethod/${this.form.paymentMethodID}`);
                this.$bvModal.hide('modal-default-payment_method');
                this.$emit('defaultPaymentMethod', resp.data.defaultPaymentMethodID);
            } catch (e) {
                this.$bvToast.toast(e.toString(), {
                    title: this.$t('globals.toast.error'),
                    variant: 'danger',
                    solid: true
                });
            }
            this.processing = false;
        },

        buttonDeletePaymentMethod(paymentMethod) {
            this.form = { ...paymentMethod };
            this.$bvModal.show('modal-delete-payment_method');
        },
        async deletePaymentMethod() {
            if (!_.get(this, 'form.paymentMethodID')) {
                return;
            }
            this.processing = true;
            try {
                const resp = await axios.delete(`/api${this.$store.getters.isMeRole('superadmin') ? `/superadmin` : '/stripe'}/paymentmethod/${this.form.paymentMethodID}`);
                this.$bvModal.hide('modal-delete-payment_method');
                this.$emit('deletedPaymentMethod', resp.data.paymentMethod);
            } catch (e) {
                this.$bvToast.toast(e.toString(), {
                    title: this.$t('globals.toast.error'),
                    variant: 'danger',
                    solid: true
                });
            }
            this.processing = false;
        }
    }
};
</script>
