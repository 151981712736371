<template>
    <b-table
        class="oggy-table"
        fixed
        :sort-compare="sortCompare"
        :sort-by="sortBy"
        :sort-desc="sortDesc"
        sort-icon-left
        :per-page="perPage"
        :current-page="curPage"
        :items="filteredSites"
        :fields="fields"
    >
        <template v-slot:head(score)>
            {{ $t('pages.sites.th-score') }}
        </template>
        <template v-slot:cell(score)="s">
            <div class="text-truncate">
                <a :href="s.item.url" target="_blank">{{ s.item.url }}</a>
                <b-button class="ml-2" variant="text" @click="buttonPreviewOg(s.item)"><i class="fa fa-eye fa-fw" /></b-button>
            </div>
        </template>

        <template v-slot:head(actions)>
            {{ $t('pages.sites.th-actions') }}
        </template>
        <template v-slot:cell(actions)="s">
            <div class="text-right">
                <span v-if="!s.item._processing && s.item.status === 'ready'" class="text-success font-weight-bold">
                    <i class="fa fa-check-circle-o mr-1" />
                    {{ $t('pages.sites.text-ready') }}
                </span>
                <span v-if="!s.item._processing && s.item.status === 'wait'" class="text-secondary font-weight-bold">
                    <i class="fa fa-clock-o fa-spin fa-fw mr-1" />
                    {{ $t('pages.sites.text-wait') }}
                </span>
                <span v-if="!s.item._processing && s.item.status !== 'ready' && s.item.status !== 'wait'" class="text-danger font-weight-bold">
                    <i class="fa fa-exclamation-circle mr-1" />
                    {{ s.item.status }}
                </span>

                <b-button class="ml-4" :class="{ 'cursor-auto opacity-25': isRequireVerify }" variant="text" :disabled="s.item._processing || isRequireVerify" @click="buttonUpdateSitemap(s.item)">
                    <i :class="s.item._processing ? 'fa fa-spinner fa-spin fa-fw' : 'fa fa-refresh fa-fw'" />
                </b-button>
            </div>
        </template>

        <template v-slot:head(status)>
            {{ $t('pages.sites.th-status') }}
        </template>
        <template v-slot:cell(status)="s">
            <div class="text-right mr-2">
                <span v-if="!s.item._processing && s.item.status === 'ready'" class="text-success font-weight-bold">
                    <i class="fa fa-check-circle-o mr-1" />
                    {{ $t('pages.sites.text-ready') }}
                </span>
                <span v-if="!s.item._processing && s.item.status === 'wait'" class="text-secondary font-weight-bold">
                    <i class="fa fa-clock-o fa-spin fa-fw mr-1" />
                    {{ $t('pages.sites.text-wait') }}
                </span>
                <span v-if="!s.item._processing && s.item.status !== 'ready' && s.item.status !== 'wait'" class="text-danger font-weight-bold">
                    <i class="fa fa-exclamation-circle mr-1" />
                    {{ s.item.status }}
                </span>
            </div>
        </template>

        <template v-slot:cell(x)="s">
            <b-link @click="s.toggleDetails"><i :class="s.detailsShowing ? 'fa fa-chevron-down' : 'fa fa-chevron-right'" /></b-link>
        </template>

        <template v-slot:row-details="s">
            <div :class="s.item._processing || s.item.status === 'wait' ? 'opacity-25' : ''">
                <div>
                    <h6 v-if="_.has(s.item, 'meta.title')" class="font-weight-bold mb-0">{{ s.item.meta.title | decodeHTML }}</h6>
                    <div v-if="_.has(s.item, 'meta.description')">
                        <small>
                            <b>description:</b>
                            {{ s.item.meta.description | decodeHTML }}
                        </small>
                    </div>
                    <div v-if="_.has(s.item, 'meta.keyboard')">
                        <small>
                            <b>keyboard:</b>
                            {{ s.item.meta.keyboard | decodeHTML }}
                        </small>
                    </div>
                </div>

                <div v-if="!_.has(s.item, 'meta.metas.[0].p')" class="mt-3">
                    <div v-if="s.item.status !== 'wait'">
                        <i class="fa fa-exclamation-triangle fa-sm text-danger" />
                        {{ $t('pages.sites.text-nodata') }}
                    </div>
                </div>

                <b-row v-if="isSelectedTableOpenGraph" class="list-line-top mt-2">
                    <b-col cols="1" class="text-right pr-0 pt-4">
                        <PieProgress :id="`score-og-${s.index}`" :i-value="_.get(s.item, '_metas.og.score', 0)" :i-max="_.get(s.item, '_metas.og.max', null)" />
                        <b-popover v-if="$store.getters.isDev()" :target="`score-og-${s.index}`" triggers="hover focus" variant="danger">
                            <template v-slot:title>
                                Open Graph score:
                                <b>
                                    {{ _.get(s.item, '_metas.og.score', 0).toFixed(2) * 1.0 }}
                                    <sub>/ {{ _.get(s.item, '_metas.og.max', 0).toFixed(2) * 1.0 }}</sub>
                                </b>
                            </template>
                            <ul>
                                <li v-for="m in s.item._metas.og.msgs" :key="m.index">{{ m }}</li>
                            </ul>
                        </b-popover>
                    </b-col>

                    <b-col cols="7" class="small">
                        <div class="list-line">
                            <b-row v-for="m in s.item._metas.og.properties" :key="m.siteID">
                                <b-col cols="2" class="text-right text-truncate pr-0">
                                    <b :class="m.l > 1 ? 'text-danger' : ''">{{ m.p }}:&nbsp;</b>
                                </b-col>
                                <b-col cols="10">
                                    <span v-if="m.c">
                                        <span v-if="m.c.substring(0, 4) === 'http'">
                                            <a :href="m.c" target="_blank">
                                                {{ m.c | decodeHTML }}
                                                <!--<i class="fa fa-external-link pl-2" />-->
                                            </a>
                                        </span>
                                        <span v-else>{{ m.c | decodeHTML }}</span>
                                    </span>
                                    <span v-else class="text-danger">{{ $t('pages.sites.text-og_nodata') }}</span>
                                </b-col>
                            </b-row>
                        </div>
                    </b-col>

                    <b-col cols="4" class="small">
                        <b-row>
                            <b-col cols="4" class="pl-0 pr-0">
                                <ImageLoaderLazy
                                    v-if="_.get(s.item, '_metas.og.images[0].c')"
                                    :i-src="s.item._metas.og.images[0].c"
                                    :o-size="s.item._metas.og.images[0]"
                                    i-class="img-thumbnail"
                                    i-width="200px"
                                    i-max-height="500px"
                                />
                                <img v-else :src="imgQuestion" class="img-thumbnail" width="200px" />
                            </b-col>
                            <b-col cols="8">
                                <div class="mt-3">
                                    <b-row v-for="m in s.item._metas.og.images" :key="m.siteID">
                                        <b-col cols="3" class="text-right text-truncate pr-0">
                                            <b :class="m.l > 1 ? 'text-danger' : ''">{{ m.p }}:&nbsp;</b>
                                        </b-col>
                                        <b-col cols="9" class="text-truncate">
                                            <span v-if="m.c">
                                                <span v-if="m.c.substring(0, 4) === 'http'">
                                                    <a :href="m.c" target="_blank">
                                                        <i v-if="m.p === 'og:image' || m.p === 'og:image:secure_url'" v-b-tooltip class="fa fa-external-link" :title="m.c" />
                                                        <span v-else>{{ m.c | decodeHTML }}</span>
                                                    </a>
                                                </span>
                                                <span v-else>{{ m.c | decodeHTML }}</span>
                                            </span>
                                            <span v-else class="text-danger">{{ $t('pages.sites.text-og_nodata') }}</span>
                                        </b-col>
                                    </b-row>
                                    <b-row class="mt-2" />
                                    <b-row v-if="_.get(s.item, '_metas.og.images[0].image.width')">
                                        <b-col cols="3" class="text-right text-truncate pr-0">
                                            <b>{{ $t('pages.sites.text-dimenson') }}</b>
                                        </b-col>
                                        <!-- <b-col cols="7" class="text-truncate">{{ s.item._metas.og.images[0].width }} x {{ s.item._metas.og.images[0].height }}</b-col> -->
                                        <b-col cols="7" class="text-truncate">
                                            {{ _.get(s.item, '_metas.og.images[0].image.width', '?') }} x
                                            {{ _.get(s.item, '_metas.og.images[0].image.height', '?') }}
                                        </b-col>
                                    </b-row>
                                    <b-row v-if="_.get(s.item, '_metas.og.images[0].image.filesize')">
                                        <b-col cols="3" class="text-right text-truncate pr-0">
                                            <b>{{ $t('pages.sites.text-filesize') }}</b>
                                        </b-col>
                                        <b-col cols="7" class="text-truncate">{{ (s.item._metas.og.images[0].image.filesize / 1024).toFixed(1) }}kB</b-col>
                                    </b-row>
                                </div>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>

                <!-- <b-row v-if="s.item._metas.tw.properties.length || s.item._metas.tw.images.length" class="list-line-top mt-2"> -->
                <b-row v-if="isSelectedTableTwitter" class="list-line-top mt-2">
                    <b-col cols="1" class="text-right pr-0 pt-4">
                        <PieProgress :id="`score-tw-${s.index}`" :i-value="_.get(s.item, '_metas.tw.score', 0)" :i-max="_.get(s.item, '_metas.tw.max', null)" />
                        <b-popover v-if="$store.getters.isDev()" :target="`score-tw-${s.index}`" triggers="hover focus" variant="danger">
                            <template v-slot:title>
                                Twitter score:
                                <b>
                                    {{ _.get(s.item, '_metas.tw.score', 0).toFixed(2) }}
                                    <sub>/ {{ _.get(s.item, '_metas.tw.max', 0).toFixed(2) * 1.0 }}</sub>
                                </b>
                            </template>
                            <ul>
                                <li v-for="m in s.item._metas.tw.msgs" :key="m.index">{{ m }}</li>
                            </ul>
                        </b-popover>
                    </b-col>

                    <b-col cols="7" class="small">
                        <div class="list-line">
                            <b-row v-for="m in s.item._metas.tw.properties" :key="m.siteID">
                                <b-col cols="2" class="text-right text-truncate pr-0">
                                    <b :class="m.l > 1 ? 'text-danger' : ''">{{ m.p }}:&nbsp;</b>
                                </b-col>
                                <b-col cols="10">
                                    <span v-if="m.c">
                                        <span v-if="m.c.substring(0, 4) === 'http'">
                                            <a :href="m.c" target="_blank">{{ m.c | decodeHTML }}</a>
                                        </span>
                                        <span v-else>{{ m.c | decodeHTML }}</span>
                                    </span>
                                    <span v-else class="text-danger">{{ $t('pages.sites.text-og_nodata') }}</span>
                                </b-col>
                            </b-row>
                        </div>
                    </b-col>

                    <b-col cols="4" class="small">
                        <b-row>
                            <b-col cols="4" class="pl-0 pr-0">
                                <ImageLoaderLazy
                                    v-if="_.get(s.item, '_metas.tw.images[0].c')"
                                    :i-src="s.item._metas.tw.images[0].c"
                                    :o-size="s.item._metas.tw.images[0]"
                                    i-class="img-thumbnail"
                                    i-width="200px"
                                    i-max-height="500px"
                                />
                                <img v-else :src="imgQuestion" class="img-thumbnail" width="200px" />
                            </b-col>
                            <b-col cols="8">
                                <div class="mt-3">
                                    <b-row v-for="m in s.item._metas.tw.images" :key="m.siteID">
                                        <b-col cols="3" class="text-right text-truncate pr-0">
                                            <b :class="m.l > 1 ? 'text-danger' : ''">{{ m.p }}:&nbsp;</b>
                                        </b-col>
                                        <b-col cols="9" class="text-truncate">
                                            <span v-if="m.c">
                                                <span v-if="m.c.substring(0, 4) === 'http'">
                                                    <a :href="m.c" target="_blank">
                                                        <i v-if="m.p === 'twitter:image' || m.p === 'twitter:image:src'" v-b-tooltip class="fa fa-external-link" :title="m.c" />
                                                        <span v-else>{{ m.c | decodeHTML }}</span>
                                                    </a>
                                                </span>
                                                <span v-else>{{ m.c | decodeHTML }}</span>
                                            </span>
                                            <span v-else class="text-danger">{{ $t('pages.sites.text-og_nodata') }}</span>
                                        </b-col>
                                    </b-row>
                                    <b-row class="mt-2" />
                                    <b-row v-if="_.get(s.item, '_metas.tw.images[0].image.width')">
                                        <b-col cols="3" class="text-right text-truncate pr-0">
                                            <b>{{ $t('pages.sites.text-dimenson') }}</b>
                                        </b-col>
                                        <!-- <b-col cols="7" class="text-truncate">{{ s.item._metas.og.images[0].width }} x {{ s.item._metas.og.images[0].height }}</b-col> -->
                                        <b-col cols="7" class="text-truncate">
                                            {{ _.get(s.item, '_metas.tw.images[0].image.width', '?') }} x
                                            {{ _.get(s.item, '_metas.tw.images[0].image.height', '?') }}
                                        </b-col>
                                    </b-row>
                                    <b-row v-if="_.get(s.item, '_metas.tw.images[0].image.filesize')">
                                        <b-col cols="3" class="text-right text-truncate pr-0">
                                            <b>{{ $t('pages.sites.text-filesize') }}</b>
                                        </b-col>
                                        <b-col cols="7" class="text-truncate">{{ (s.item._metas.tw.images[0].image.filesize / 1024).toFixed(1) }}kB</b-col>
                                    </b-row>
                                </div>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </div>
        </template>
    </b-table>
</template>

<style lang="scss" scoped>
.pie-progress {
    width: 3rem;
}
</style>

<script>
import _ from 'lodash';

import imgQuestion from '@/assets/images/question.svg';
import ImageLoaderLazy from './imageLoaderLazy.vue';
import PieProgress from './pieProgress.vue';

export default {
    components: {
        ImageLoaderLazy,
        PieProgress
    },
    filters: {
        truncate(string, len) {
            if (string.length > len) {
                return string.substring(0, len) + '…';
            }
            return string;
        },
        decodeHTML(html) {
            //return html;
            try {
                var txt = document.createElement('textarea');
                txt.innerHTML = html;
                return txt.value;
            } catch (e) {
                return html;
            }
        }
    },
    props: {
        perPage: {
            type: Number,
            required: true
        },
        curPage: {
            type: Number,
            required: true
        },
        filteredSites: {
            type: Array,
            required: true
        },
        selectedLayoutTable: {
            type: Array,
            required: true
        },
        buttonPreviewOg: {
            type: Function,
            default: () => {}
        },
        buttonUpdateSitemap: {
            type: Function,
            default: () => {}
        }
    },
    data() {
        return {
            imgQuestion,
            fields: [
                { key: 'x', label: '', thStyle: 'width: 3rem' },
                { key: 'score', sortable: true }
            ],
            sortBy: 'score',
            sortDesc: true
        };
    },
    computed: {
        isRequireVerify() {
            return this.$store.getters.isMeRequireVerify() || !this.$store.getters.isClientValidUntil();
        },
        isSelectedTableOpenGraph() {
            return _.indexOf(this.selectedLayoutTable, 'opengraph') !== -1;
        },
        isSelectedTableTwitter() {
            return _.indexOf(this.selectedLayoutTable, 'twitter') !== -1;
        }
    },
    created() {
        if (this.$store.getters.isMeRole(['admin', 'user'])) {
            this.fields.push({ key: 'actions', thStyle: 'width: 9rem' });
        } else {
            //if (this.$store.getters.isMeRole('guest')) {
            this.fields.push({ key: 'status', thStyle: 'width: 9rem' });
        }
    },
    methods: {
        sortCompare: function(a, b) {
            if (this.isSelectedTableOpenGraph && this.isSelectedTableTwitter) {
                a = _.get(a, '_metas.score', 0);
                b = _.get(b, '_metas.score', 0);
            } else if (this.isSelectedTableOpenGraph) {
                a = _.get(a, '_metas.og.score', 0);
                b = _.get(b, '_metas.og.score', 0);
            } else if (this.isSelectedTableTwitter) {
                a = _.get(a, '_metas.tw.score', 0);
                b = _.get(b, '_metas.tw.score', 0);
            } else {
                return 0;
            }
            return a < b ? -1 : a > b ? 1 : 0;
        }
    }
};
</script>
