<template>
    <svg class="pie-progress" viewBox="0 0 100 100" style="max-height: 12.5rem">
        <circle cx="50" cy="50" :r="42.5" :stroke="`${iColor}2`" fill="none" stroke-width="5" />
        <circle class="progress" cx="50" cy="50" :r="r" :stroke="`${iColor}`" fill="none" stroke-width="10" :style="`stroke-dasharray: ${st1} ${st2}; stroke-dashoffset: ${st3}`" />
        <text x="50" y="50" :fill="`${iColor}`" text-anchor="middle" alignment-baseline="central" v-html="text" />
    </svg>
</template>

<style lang="scss" scoped>
svg {
    // -webkit-filter: drop-shadow(0px 0px 5px #0007);
    // filter: drop-shadow(0px 0px 5px #0007);

    text {
        font-size: 1.5rem;
        font-weight: bold;
        // tspan {
        //     font-size: 2rem;
        // }
    }
    circle.progress {
        transition: all 1s ease-in-out;
        // stroke-dasharray: 0 999;
        // stroke-dashoffset: 0;
        // stroke: #f44336;
    }
}
</style>

<script>
import _ from 'lodash';
import axios from 'axios';
import t from '@/tool';

export default {
    props: {
        iValue: {
            type: Number,
            require: true,
            default: 1
        },
        iMax: {
            type: Number,
            default: 1
        },
        iColor: {
            type: String,
            default: '#888'
        }
    },
    data() {
        return {
            r: 40,
            pi: 3.14159265,
            st1: 0,
            st2: 0,
            st3: 0,
            text: ''
        };
    },
    watch: {
        iValue() {
            this.reload();
        },
        iMax() {
            this.reload();
        }
    },
    created() {
        this.refreshValue(0);
        this.reload();
    },
    methods: {
        reload() {
            setTimeout(() => {
                this.refreshValue(_.min([1, _.max([0, this.iValue / this.iMax])]));
            });
        },
        refreshValue(v) {
            // v = 0.75;
            const f = 2 * this.pi * this.r;
            this.st1 = f * v;
            this.st2 = f - this.st1;
            this.st3 = 0.25 * f; //this.st1 + f / 2;
            // console.log({ v, f, st1: this.st1, st2: this.st2, st3: this.st3 });
            this.text = `${_.round(v * 100)}<tspan alignment-baseline="middle" style="font-size: 1rem">%</tspan>`;
        }
    }
};
</script>
