<template>
    <div>
        <div v-if="$store.getters.isMeIniting()">INITING!!!!!</div>

        <div v-else>
            <b-navbar toggleable="lg" type="dark" :variant="$store.getters.isMeRole('superadmin') ? 'danger' : 'info'">
                <b-navbar-brand to="/">{{ $t('nav.title') }}</b-navbar-brand>

                <b-navbar-toggle target="nav-collapse" />

                <b-collapse id="nav-collapse" is-nav>
                    <b-navbar-nav>
                        <template v-if="$store.getters.isMeRole('superadmin')">
                            <b-nav-item class="ml-2" to="/superadmin/clients">{{ $t('nav.clients') }}</b-nav-item>
                            <b-nav-item v-if="!$route.params.clientID" class="ml-2" to="/superadmin/users">{{ $t('nav.users') }}</b-nav-item>
                            <b-nav-item class="ml-2" :to="`/superadmin/subscriptions`">{{ $t('nav.subscriptions') }}</b-nav-item>
                            <b-nav-item v-if="!$route.params.clientID" class="ml-2" :to="`/superadmin/invoices`">{{ $t('nav.invoices') }}</b-nav-item>

                            <template v-if="$route.params.clientID">
                                <b-nav-item class="ml-4">|</b-nav-item>
                                <b-nav-item class="ml-4" :to="`/superadmin/client/${$route.params.clientID}/projects`">{{ $t('nav.projects') }}</b-nav-item>
                                <b-nav-item class="ml-2" :to="`/superadmin/client/${$route.params.clientID}/users`">{{ $t('nav.users') }}</b-nav-item>
                                <b-nav-item class="ml-2" :to="`/superadmin/client/${$route.params.clientID}/invoices`">{{ $t('nav.invoices') }}</b-nav-item>
                            </template>
                        </template>

                        <template v-if="$store.getters.isMeRole(['admin', 'user', 'guest'])">
                            <b-nav-item class="ml-2" to="/projects">{{ $t('nav.projects') }}</b-nav-item>
                            <b-nav-item v-if="$store.getters.isMeRole('admin')" class="ml-2" to="/users">{{ $t('nav.users') }}</b-nav-item>
                            <template v-if="$route.params.projectID">
                                <b-nav-item class="ml-5" :to="`/project/${$route.params.projectID}/dashboard`">{{ $t('nav.dashboard') }}</b-nav-item>
                                <b-nav-item class="ml-2" :to="`/project/${$route.params.projectID}/sites`">{{ $t('nav.sites') }}</b-nav-item>
                            </template>
                        </template>
                    </b-navbar-nav>

                    <b-navbar-nav class="ml-auto">
                        <b-nav-item v-if="isRequireVerifyMail" v-b-tooltip.hover.left class="mr-3" :title="$t('pages.verifyemail.tooltip-noverification')" @click="showVerifyEmail">
                            <b-row>
                                <b-col cols="auto" class="pr-0">
                                    <i class="fa fa-exclamation-circle fa-lg" aria-hidden="true" />
                                </b-col>
                                <b-col>
                                    <p class="small m-0">{{ $t('pages.verifyemail.text-noverification') }}</p>
                                    <p class="small m-0">{{ $t('pages.verifyemail.text-noverification2') }}</p>
                                </b-col>
                            </b-row>
                        </b-nav-item>

                        <b-nav-text v-if="$store.getters.isMeDev()" class="mr-2">
                            <b-form-checkbox v-model="dev" switch :change="$store.commit('setDev', dev)">Developer</b-form-checkbox>
                        </b-nav-text>

                        <b-nav-item v-if="$store.getters.isMeRole('admin')" class="mr-2" to="/plan">
                            <p class="small m-0 text-right">
                                {{ $t('nav.plan') }}
                                <b-badge>{{ $t(`pages.pricing.plan-${getPlanID || 'missing'}`) }}</b-badge>
                            </p>
                            <p class="small m-0 text-right" xv-if="isPlanCanceled">
                                {{ $t('nav.valid_until') }}
                                <b-badge :variant="isNoLongerValid || isPlanCanceled ? 'warning' : ''">{{ getPlanValid || 'missing' }}</b-badge>
                            </p>
                        </b-nav-item>

                        <b-nav-item v-if="false && $store.getters.isMeRole(false)" class="mr-2" to="/pricing">{{ $t('nav.pricing') }}</b-nav-item>
                        <b-nav-item v-if="!$store.getters.isMeRole()" class="mr-2" to="/login">{{ $t('nav.signin') }}</b-nav-item>

                        <b-nav-item v-if="!$store.getters.isMeRole()" class="mr-2" to="/registration">{{ $t('nav.signup') }}</b-nav-item>
                        <!-- <b-nav-item-dropdown v-else-if="$store.getters.isMeRole('admin')" class="ml-2" :text="$t('nav.me')" right>
                            <b-dropdown-item to="/plan">{{ $t('nav.me-invoice') }}</b-dropdown-item>
                            <b-dropdown-item to="/logout">{{ $t('nav.me-logout') }}</b-dropdown-item>
                        </b-nav-item-dropdown> -->
                        <b-nav-item v-else to="/logout">{{ $t('nav.me-logout') }}</b-nav-item>

                        <b-nav-item-dropdown class="ml-2" text="Lang" right>
                            <template v-slot:button-content>
                                {{ $t('nav.lang') }}
                                <!-- <i class="fa fa-language" />-->
                            </template>
                            <b-dropdown-item @click="setLocale('en')">{{ $t('nav.lang-en') }}</b-dropdown-item>
                            <b-dropdown-item @click="setLocale('de')">{{ $t('nav.lang-de') }}</b-dropdown-item>
                            <!-- <b-dropdown-item @click="setLocale('pl')">{{ $t('nav.lang-pl') }}</b-dropdown-item>
                            <b-dropdown-item @click="setLocale('ru')">{{ $t('nav.lang-ru') }}</b-dropdown-item> -->
                        </b-nav-item-dropdown>
                    </b-navbar-nav>
                </b-collapse>
            </b-navbar>

            <b-container fluid>
                <router-view />
            </b-container>

            <b-navbar class="mt-1 pt-3 pb-5 footer" toggleable="lg" type="dark" :variant="$store.getters.isMeRole('superadmin') ? 'danger' : 'info'">
                <div class="container navbar-nav">
                    <b-link class="nav-link" to="/legal/nutzungsbedingungen">Nutzungsbedingungen</b-link>
                    <b-link class="nav-link" to="/legal/agb">AGB</b-link>
                    <b-link class="nav-link" to="/legal/datenschutz">Datenschutz</b-link>
                    <b-link class="nav-link" to="/legal/impressum">Impressum</b-link>
                </div>
            </b-navbar>
        </div>

        <b-modal id="modal-verify-email" :title="$t('pages.verifyemail.modal-title')" hide-header-close>
            <p v-if="!verify.text" class="my-4" v-html="$t('pages.verifyemail.modal-description', { email: `<b>${_.get($store.state.me, 'user.email', '-')}</b>` })" />
            <p v-if="verify.text" class="my-4" v-html="$t(verify.text, { email: `<b>${_.get($store.state.me, 'user.email', '-')}</b>` })" />

            <template slot="modal-footer" slot-scope="{ cancel, ok }">
                <b-button v-if="!verify.text" @click="cancel()">{{ $t('globals.button.cancel') }}</b-button>
                <b-button v-if="!verify.text" :disabled="verify.processing" variant="danger" @click="sendVerifyEmail">
                    {{ $t('globals.button.send') }}
                    <i v-if="verify.processing" class="fa fa-spinner fa-spin fa-fw ml-2" />
                </b-button>
                <b-button v-if="verify.text" @click="ok()">{{ $t('globals.button.ok') }}</b-button>
            </template>
        </b-modal>

        <b-modal id="modal-browser" :title="$t('globals.modal.warning')" hide-header-close>
            <p v-html="$t('components.modal-browser.text-description')" />
            <template slot="modal-footer" slot-scope="{ cancel }">
                <b-button @click="cancel()">{{ $t('globals.button.ignore') }}</b-button>
                <b-button variant="primary" href="https://browsehappy.com/?locale=en">{{ $t('components.modal-browser.button-browser') }}</b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import axios from 'axios';

export default {
    data() {
        return {
            dev: this.$store.getters.isDev(),
            verify: {
                processing: false,
                text: false,
            },
        };
    },
    computed: {
        isRequireVerifyMail() {
            return this.$store.getters.isMeRequireVerify();
        },
        isNoLongerValid() {
            return !this.$store.getters.isClientValidUntil();
        },
        isPlanCanceled() {
            console.debug('isClientPlanCanceled', this.$store.getters.isClientPlanCanceled());
            console.debug('state.client?.meta?.planCanceledAt', this.$store.getters);
            return this.$store.getters.isClientPlanCanceled();
        },
        getPlanID() {
            return this.$store.getters.getClientPlanID();
        },
        getPlanValid() {
            const valid = this.$store.getters.getClientValidUntil();
            return valid ? moment(valid).format('YYYY.MM.DD HH:mm') : '';
        },
    },
    mounted() {
        if ((this.$browserDetect.isIE || _.get(this.$browserDetect, 'meta.name') === 'IE') && _.get(this.$browserDetect, 'meta.version') <= 11) {
            this.$bvModal.show('modal-browser');
        }
        // console.log('isIE ', this.$browserDetect.isIE);
        // console.log('meta ', this.$browserDetect.meta.version);
        // console.log('meta ', this.$browserDetect.meta.name);
        // console.log('meta ', _.get(this.$browserDetect, 'meta.version') <= 18);
    },
    methods: {
        setLocale(locale) {
            this.$store.commit('setLocale', locale);
            if (this.$store.getters.isMeRole()) {
                axios.post(`/api/auth/me`, { meta: { locale } }).then((response) => {
                    if (_.get(response, 'data.success')) {
                        this.$store.commit('setMe', response.data.me);
                    }
                });
            }
        },
        showVerifyEmail() {
            if (!this.verify.processing) {
                this.verify.text = false;
            }
            if (!this.$store.getters.isMeRole()) {
                return;
            }
            this.verify.processing = true;
            axios
                .get(`/api/auth/verify`)
                .then((resp) => {
                    this.verify.processing = false;
                    if (!_.get(resp, 'data.success')) {
                        let message = _.get(resp, 'data.msg', 'unknown');
                        this.verify.text = `pages.verifyemail.net-${message}`;
                    }
                    this.$bvModal.show('modal-verify-email');
                })
                .catch((e) => {
                    this.verify.processing = false;
                    this.$bvToast.toast(e.toString(), {
                        title: this.$t('globals.toast.error'),
                        variant: 'danger',
                        solid: true,
                    });
                });
        },
        sendVerifyEmail() {
            if (!this.$store.getters.isMeRole()) {
                return;
            }
            this.verify.processing = true;
            axios
                .post(`/api/auth/verify`)
                .then((resp) => {
                    this.verify.processing = false;
                    if (!_.get(resp, 'data.success')) {
                        let message = _.get(resp, 'data.msg', 'unknown');
                        this.verify.text = `pages.verifyemail.net-${message}`;
                        return;
                    }
                    this.verify.text = 'pages.verifyemail.text-sent';
                })
                .catch((e) => {
                    this.verify.processing = false;
                    this.$bvToast.toast(e.toString(), {
                        title: this.$t('globals.toast.error'),
                        variant: 'danger',
                        solid: true,
                    });
                });
        },
    },
};
</script>
