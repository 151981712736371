import _ from 'lodash';

export default {
    state: {
        plans: []
    },
    mutations: {
        setPlans: (state, payload) => {
            state.plans = _.cloneDeep(payload);
        }
    },
    getters: {
        getPlans: state => () => _.cloneDeep(state.plans),

        getPlan: state => plan => _.cloneDeep(_.find(state.plans, { id: plan })),

        getPlanMax: state => (plan, max) => _.get(_.find(state.plans, { id: plan }), `max${_.upperFirst(max)}`, 0)
    }
};
