<template>
    <div>
        <div v-if="type === 'facebook'" class="d-flex justify-content-center mb-2 card-facebook">
            <b-card v-if="isError('facebook')">
                <b-card-text class="card-error" align="center">{{ $t('pages.sites.text-unable_render') }}</b-card-text>
            </b-card>

            <b-card v-if="!isError('facebook')" no-body class="card-image" align="left">
                <div class="pie">
                    <PieProgress :i-value="_.get(site, '_metas.og.score', 0)" :i-max="_.get(site, '_metas.og.max', null)" />
                </div>

                <div v-if="getTextComputed('og:image')">
                    <div v-if="getWarning('facebook')" v-b-tooltip.hover :title="$t('pages.sites.text-image_warning')" class="fa-stack fa-lg text-warning">
                        <i class="fa fa-square fa-stack-2x"></i>
                        <i class="fa fa-exclamation-triangle fa-stack-1x fa-inverse"></i>
                    </div>
                    <ImageLoaderLazy v-if="imgLazy" class="card-img-top" :i-src="getTextComputed('og:image')" :o-size="_.get(site, '_metas.og.images[0]')" />
                    <ImageLoader v-else class="card-img-top" :i-src="getTextComputed('og:image')" :o-size="_.get(site, '_metas.og.images[0]')" />
                </div>
                <div v-else>
                    <img :src="imgQuestion" class="card-img-top question" />
                </div>

                <b-card-body>
                    <b-card-text v-if="getTextComputed('og:url')" class="card-url">{{ getTextComputed('og:url') }}</b-card-text>
                    <b-card-text v-if="getTextComputed('og:title')" class="card-title">{{ getTextComputed('og:title') }}</b-card-text>
                    <b-card-text v-if="getTextComputed('og:description')" class="card-description">{{ getTextComputed('og:description') }}</b-card-text>
                </b-card-body>
            </b-card>
        </div>

        <div v-if="type === 'twitter'" class="d-flex justify-content-center mb-2 card-twitter">
            <b-card v-if="isError('twitter')">
                <b-card-text class="card-error" align="center">{{ $t('pages.sites.text-unable_render') }}</b-card-text>
            </b-card>

            <b-card v-if="!isError('twitter')" no-body class="card-image" align="left">
                <div class="pie">
                    <PieProgress :i-value="_.get(site, '_metas.tw.score', 0)" :i-max="_.get(site, '_metas.tw.max', null)" />
                </div>

                <div v-if="getTextComputed('twitter:image')">
                    <ImageLoader v-if="!imgLazy" class="card-img-top" :i-src="getTextComputed('twitter:image')" />
                    <ImageLoaderLazy v-if="imgLazy" class="card-img-top" :i-src="getTextComputed('twitter:image')" />
                </div>
                <div v-else>
                    <img :src="imgQuestion" class="card-img-top question" />
                </div>

                <b-card-body>
                    <b-card-text class="card-title">{{ getTextComputed('twitter:title') }}</b-card-text>
                    <b-card-text v-if="getTextComputed('twitter:description')" class="card-description">{{ getTextComputed('twitter:description') }}</b-card-text>
                    <b-card-text v-if="getTextComputed('twitter:url')" class="card-url">
                        <img :src="imgTwitterLink" style="width: 1rem; padding-bottom: 0.125rem;" />
                        {{ getTextComputed('twitter:url') }}
                    </b-card-text>
                </b-card-body>
            </b-card>
        </div>
    </div>
</template>

<style lang="scss" scoped>
@import '@/assets/scss/_variables';

.card {
    width: 514px;
    max-width: 514px;
    border-radius: 0rem;
    font-size: 1rem;
    line-height: normal;

    &.card-image {
        .pie {
            position: absolute;
            top: 1rem;
            left: 1rem;
            img,
            .pie-progress {
                width: 3rem;
            }
        }
        .fa-stack {
            position: absolute;
            top: 1rem;
            right: 1rem;
            text-shadow: 0px 0px 5px #000;
        }
        .card-img-top.question {
            background-color: $color-light;
            border-bottom: 1px solid $color-gray-white;
        }
    }

    .card-body {
        padding: 0.625rem;
        padding-bottom: calc(0.625rem - 0.25rem);

        .card-title {
            color: $color-dark-active;
            margin-bottom: 0.25rem;
            // overflow: hidden;
            // display: -webkit-box;
            // -webkit-line-clamp: 1;
            // -webkit-box-orient: vertical;
        }
        .card-description {
            color: $color-dark;
            margin-bottom: 0.25rem;
            // overflow: hidden;
            // display: -webkit-box;
            // -webkit-line-clamp: 1;
            // -webkit-box-orient: vertical;
        }
        .card-title,
        .card-description,
        .card-url {
            margin-bottom: 0.25rem;
        }
    }
}

.card-facebook .card {
    font-family: Helvetica, Arial, sans-serif;
    &.card-image .card-img-top {
        border-radius: 0;
    }
    .card-body {
        padding: 0.75rem 0.5rem;
        padding-bottom: calc(0.75rem - 0.375rem);

        .card-title,
        .card-description,
        .card-url,
        .card-error {
            color: #606770;
            margin-bottom: 0.375rem;
        }
        .card-url {
            color: #1d2129;
            text-transform: uppercase;
            font-size: 0.75rem;
        }
        .card-title {
            font-weight: bold;
        }
        .card-description {
            font-size: 0.875rem;
        }
        .card-error {
            color: $color-red;
        }
    }
}

.card-twitter .card {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, 'Helvetica Neue', sans-serif;
    font-size: 0.9375rem;
    width: 508px;
    max-width: 508px;
    border-radius: 1rem;

    &.card-image .card-img-top {
        border-top-left-radius: calc(1rem - 1px);
        border-top-right-radius: calc(1rem - 1px);
    }

    .card-body {
        padding: 0.625rem;
        padding-bottom: calc(0.625rem - 0.125rem);

        .card-title,
        .card-description,
        .card-url,
        .card-error {
            color: #657786;
            margin-bottom: 0.125rem;
        }
        .card-title {
            color: #14171a;
        }
        // .card-description {
        //     -webkit-line-clamp: 2;
        // }
        .card-error {
            color: $color-red;
        }
    }
}
</style>

<script>
import _ from 'lodash';
import t from '@/tool';

import imgTwitterLink from '@/assets/images/twitterLink.svg';
import imgQuestion from '@/assets/images/question.svg';

import ImageLoader from './imageLoader.vue';
import ImageLoaderLazy from './imageLoaderLazy.vue';
import PieProgress from './pieProgress.vue';

export default {
    components: {
        ImageLoader,
        ImageLoaderLazy,
        PieProgress
    },
    props: {
        site: {
            type: Object,
            required: true
        },
        type: {
            type: String,
            default: 'facebook'
        },
        imgLazy: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            imgTwitterLink,
            imgQuestion
        };
    },
    computed: {
        isError() {
            return type => {
                if (_.indexOf(['ready', 'stop', 'limit', 'wait'], this.site.status) === -1) {
                    return true;
                }
                return type === 'twitter' ? !this.getTextComputed('twitter:title') : false;
            };
        },
        getWarning() {
            // https://digitalessence.net/facebook-link-thumbnail-image-dimensions/
            // https://developers.facebook.com/docs/sharing/webmasters/images/
            return type => {
                const width = _.get(this.site, '_metas.og.images[0].width');
                const height = _.get(this.site, '_metas.og.images[0].height');
                if (width && height) {
                    // console.log('this.site', this.site._metas.og.images);
                    if (width < 200 || height < 200) {
                        return true;
                    }
                    if (width < 600 && height < 315) {
                        return true;
                    }
                    if (width > height * 1.9047619) {
                        return true;
                    }
                }
                return false;
            };
        },
        getTextComputed() {
            const getTextUrl = url => url.replace(/^https?:\/\//i, '').replace(/\/.*$/i, '');

            return property => {
                let value;
                if (_.has(this.site, 'meta.metas')) {
                    const meta = _.find(this.site.meta.metas, { p: property });
                    value = _.get(meta, 'c');
                }
                if (!value) {
                    if ((property === 'og:url' || property === 'twitter:url') && this.site.url) {
                        value = this.site.url;
                    }
                }
                if ((property === 'og:url' || property === 'twitter:url') && value) {
                    return getTextUrl(value);
                }
                return value || '';
            };
        }
    }
};
</script>
