<template>
    <div>
        <h1 class="mt-4">{{ $t('pages.payment.title') }}</h1>

        <b-card bg-variant="light" class="mb-3">
            <div class="card-deck mb-3 text-center">
                <!-- <div class="card mb-4 box-shadow">
                    <div class="card-header">
                        <h4 class="my-0 font-weight-normal">Free</h4>
                    </div>
                    <div class="card-body">
                        <h1 class="card-title pricing-card-title">
                            $0
                            <small class="text-muted">/ mo</small>
                        </h1>
                        <ul class="list-unstyled mt-3 mb-4">
                            <li>10 users included</li>
                            <li>2 GB of storage</li>
                            <li>Email support</li>
                            <li>Help center access</li>
                        </ul>
                        <button type="button" class="btn btn-lg btn-block btn-outline-primary">Sign up for free</button>
                    </div>
                </div> -->

                <div v-for="p in plans.filter((p) => p.show)" :key="p.index" class="card mb-4 box-shadow" :class="{ 'bg-light border-dark': p.index === currentPlan.plan.index }">
                    <div class="card-header">
                        <h4 class="my-0 font-weight-normal">{{ p.text }}</h4>
                    </div>
                    <div class="card-body">
                        <h1 class="card-title pricing-card-title">
                            € {{ p.priceM ? p.priceM.toFixed(2) : '-.--' }}
                            <small class="text-muted">/ month</small>
                        </h1>
                        <ul class="list-unstyled mt-3 mb-4">
                            <li>
                                <b>{{ p.maxUsers }}</b>
                                users included
                            </li>
                            <li>
                                <b>{{ p.maxProjects }}</b>
                                projects included
                            </li>
                            <li>
                                <b>{{ p.maxSites }}</b>
                                sites per project
                            </li>
                            <li>
                                (total:
                                <b>{{ p.maxProjects * p.maxSites }}</b>
                                sites)
                            </li>
                        </ul>
                        <!-- {{ p.id }} -->
                        <button v-if="getPlangrade(p.id) === 'downgrade'" type="button" class="btn btn-lg btn-block btn-outline-secondary" @click="buttonChangePlan(p.id)">Downgrade</button>
                        <button v-else-if="getPlangrade(p.id) === 'current' && !isPlanCanceled" type="button" class="btn btn-lg btn-block btn-secondary" @click="buttonChangePlan(p.id)">Cancel</button>
                        <button v-else-if="getPlangrade(p.id) === 'current' && isPlanCanceled" type="button" class="btn btn-lg btn-block btn-secondary" @click="buttonChangePlan(p.id)">Renew</button>
                        <button v-else-if="getPlangrade(p.id) === 'upgrade'" type="button" class="btn btn-lg btn-block btn-primary" @click="buttonChangePlan(p.id)">Upgrade</button>

                        <h5 v-if="p.periodStartAt" class="mt-5">Start your new plan on {{ getDateFormat(currentPlan.periodStartAt) }}</h5>
                        <h5 v-else-if="p.periodEndAt" class="mt-5">End your current plan on {{ getDateFormat(currentPlan.periodEndAt) }}</h5>

                        <p></p>
                    </div>
                </div>
            </div>
        </b-card>

        <b-card v-if="false" bg-variant="light" class="mb-3">
            <b-form-group label-cols="3" :label="$t('pages.payment.label-plan')" label-align="right" class="m-0">
                <h5 class="mt-1">{{ $t(`pages.pricing.plan-${currentPlan.plan.id}`) }}</h5>
            </b-form-group>
            <b-form-group label-cols="3" :label="$t('pages.payment.label-valid_until')" label-align="right" class="m-0">
                <h5 class="mt-1">{{ getCurrentValidUntil }}</h5>
            </b-form-group>
            <b-form-group label-cols="3" :label="$t('pages.payment.label-max_users')" label-align="right" class="m-0">
                <h5 class="mt-1">
                    <b>{{ currentPlan.limitUsers || '-' }}</b>
                </h5>
            </b-form-group>
            <b-form-group label-cols="3" :label="$t('pages.payment.label-max_projects')" label-align="right" class="m-0">
                <h5 class="mt-1">
                    <b>{{ currentPlan.limitProjects || '-' }}</b>
                </h5>
            </b-form-group>
            <b-form-group label-cols="3" :label="$t('pages.payment.label-max_sites')" label-align="right" class="m-0">
                <h5 class="mt-1">
                    <b>{{ currentPlan.limitSites || '-' }}</b>
                    <span v-if="currentPlan.limitProjects > 1 && currentPlan.limitSites">
                        &nbsp;{{ $t('pages.pricing.text-total', { total: currentPlan.limitProjects * currentPlan.limitSites || '-' }) }}
                    </span>
                </h5>
            </b-form-group>

            <hr />

            <b-form-group label-cols="3" :label="$t('pages.payment.label-plan')" label-align="right" class="m-0">
                <b-form-select v-model="form.plan" :disabled="isRequireVerifyMail" class="plans" :class="{ noselect: !form.plan }" :options="plans.filter((p) => p.show)">
                    <template #first>
                        <b-form-select-option :value="null" disabled>{{ $t('pages.payment.select-plan') }}</b-form-select-option>
                    </template>
                </b-form-select>
            </b-form-group>

            <b-form-group label-cols="3" :label="$t('pages.payment.label-max_users')" label-align="right" class="m-0">
                <h5 class="mt-1">
                    <b>{{ getPlanSelected.maxUsers || '-' }}</b>
                </h5>
            </b-form-group>
            <b-form-group label-cols="3" :label="$t('pages.payment.label-max_projects')" label-align="right" class="m-0">
                <h5 class="mt-1">
                    <b>{{ getPlanSelected.maxProjects || '-' }}</b>
                </h5>
            </b-form-group>
            <b-form-group label-cols="3" :label="$t('pages.payment.label-max_sites')" label-align="right">
                <h5 class="mt-1">
                    <b>{{ getPlanSelected.maxSites || '-' }}</b>
                    <span v-if="getPlanSelected.maxProjects > 1 && getPlanSelected.maxSites">
                        &nbsp;{{ $t('pages.pricing.text-total', { total: getPlanSelected.maxProjects * getPlanSelected.maxSites || '-' }) }}
                    </span>
                </h5>
            </b-form-group>

            <b-form-group label-cols="3" :label="$t('pages.payment.label-duration')" label-align="right" class="m-0">
                <b-form-radio-group v-model="form.duration" :disabled="isRequireVerifyMail" class="pt-2" :options="durations"></b-form-radio-group>
            </b-form-group>

            <b-form-group label-cols="3" :label="$t('pages.payment.label-valid_until')" label-align="right" class="m-0">
                <h5 class="m-0 mt-1">{{ getPlanValidUntil }}</h5>
            </b-form-group>

            <b-form-group label-cols="3" :label="$t('pages.payment.label-price')" label-align="right">
                <h5 class="m-0 mt-1">€ {{ getPlanPrice === false ? '-.--' : getPlanPrice.toFixed(2) }}</h5>
            </b-form-group>

            <b-form-group label-cols="3" :label="$t('pages.payment.label-payment_methods')" label-align="right" class="mb-0">
                <b-button :disabled="isRequireVerifyMail || isPlanDisabled || !getPlanPrice" @click="buttonCheckout">{{ $t('pages.payment.button-checkout') }}</b-button>
                <small class="small pl-3">
                    Powered by
                    <b-img :src="stripeLogo" style="max-width: 3rem; margin-left: -0.25rem" />
                </small>
            </b-form-group>
        </b-card>

        <h1 class="mt-4">{{ $t('pages.payment.head-payment_methods') }}</h1>
        <b-card bg-variant="light" class="mb-3">
            <div v-if="loadingPaymentMethods">
                {{ $t('Loading') }}
                <i class="fa fa-spinner fa-spin fa-fw ml-2" />
            </div>

            <b-table v-else class="oggy-table" fixed sort-icon-left :per-page="10" :current-page="curPagePaymentMethods" :items="filteredPaymentMethods" :fields="fieldsPaymentMethods">
                <template #head(createdAt)>Date</template>
                <template #cell(createdAt)="s">
                    {{ getDateFormat(s.item.createdAt) }}
                </template>

                <template #head(card)>Card</template>
                <template #cell(card)="s">
                    <b>{{ s.item.card.brand }}</b>
                    xxxx-{{ s.item.card.last4 }}

                    <div :class="{ 'text-danger': isExpiredCard(s.item) }">Expires {{ `${s.item.card.exp_month}`.padStart(2, '0') }}/{{ s.item.card.exp_year }}</div>
                    <div v-if="isExpiredCard(s.item)" class="text-danger small">{{ $t('pages.payment.text-card_has_expired') }}</div>
                </template>

                <template #head(actions)>Actions</template>
                <template #cell(actions)="s">
                    <b-button xxv-if="s.item.status === 'active'" class="ml-1" variant="text" :disabled="s.item._processing" @click="$refs.modalPaymentMethod.buttonDeletePaymentMethod(s.item)">
                        <i class="fa fa-times" />
                    </b-button>

                    <kbd v-if="s.item.paymentMethodID === defaultPaymentMethodID" class="ml-1 opacity-50">
                        <i class="fa fa-check-square" />
                        Default
                    </kbd>
                    <b-button v-else v-b-tooltip title="Set to default" class="ml-1" variant="text" :disabled="s.item._processing" @click="$refs.modalPaymentMethod.buttonDefaultPaymentMethod(s.item)">
                        <i class="fa fa-check" />
                    </b-button>
                    <!-- <b-button class="ml-1" variant="text" :disabled="s.item._processing" @click="buttonCancelSubsriptions(s.item, $event.target)"><i class="fa fa-times" /></b-button> -->
                </template>

                <template #head(data)>data</template>
                <template #cell(data)="s">
                    {{ s.item.paymentMethodID }}
                    <!-- <br /><br />{{ s.item }} -->
                </template>
            </b-table>
        </b-card>

        <h1 class="mt-4">{{ $t('pages.payment.head-active_subscriptions') }}</h1>
        <b-card bg-variant="light" class="mb-3">
            <div v-if="loadingSubscriptions">
                {{ $t('Loading') }}
                <i class="fa fa-spinner fa-spin fa-fw ml-2" />
            </div>

            <b-table v-else class="oggy-table" fixed sort-icon-left :per-page="10" :current-page="curPageSubscriptions" :items="filteredSubscriptions" :fields="fieldsSubscriptions">
                <template #head(createdAt)>Date</template>
                <template #cell(createdAt)="s">
                    {{ getDateFormat(s.item.createdAt) }}
                </template>

                <template #head(status)>
                    {{ $t('Status') }}
                </template>
                <!-- <template #cell(status)="s">{{ s.item.status }}{{ s.item.canceledAt ? ` - ${getDateFormat(s.item.canceledAt)}` : '' }}</template> -->
                <template #cell(status)="s">
                    {{ s.item.status }}
                    <div v-if="s.item.canceledAt">{{ $t('pages.payment.text-canceled_at') }} {{ getDateFormat(s.item.canceledAt) }}</div>
                </template>

                <template #head(currentPeriodEndAt)>Period end at</template>
                <template #cell(currentPeriodEndAt)="s">
                    {{ getDateFormat(s.item.currentPeriodEndAt) }}
                </template>

                <template #head(plan)>Plan</template>
                <template #cell(plan)="s">
                    {{ $t(`pages.pricing.plan-${s.item.plan}`) }}
                </template>

                <template #head(actions)>Actions</template>
                <template #cell(actions)="s">
                    <b-button
                        v-if="s.item.status === 'active'"
                        class="ml-1"
                        variant="text"
                        :disabled="s.item._processing"
                        @canceledSubscription="onCanceledSubscription"
                        @click="$refs.modalSubscription.buttonCancelSubscription(s.item)"
                    >
                        <i class="fa fa-times" />
                    </b-button>
                    <!-- <b-button class="ml-1" variant="text" :disabled="s.item._processing" @click="buttonCancelSubsriptions(s.item, $event.target)"><i class="fa fa-times" /></b-button> -->
                </template>

                <template #head(data)>data</template>
                <template #cell(data)="s">{{ s.item.subscriptionID }}<br />{{ s.item.data.latest_invoice }}</template>
            </b-table>
        </b-card>

        <h1 class="mt-4">{{ $t('pages.payment.head-billing_history') }}</h1>
        <b-card bg-variant="light" class="mb-3">
            <div v-if="loadingInvoices">
                {{ $t('Loading') }}
                <i class="fa fa-spinner fa-spin fa-fw ml-2" />
            </div>

            <b-table v-else class="oggy-table" fixed sort-icon-left :per-page="10" :current-page="curPageInvoices" :items="filteredInvoices" :fields="fieldsInvoices">
                <template #head(createdAt)>Date</template>
                <template #cell(createdAt)="s">
                    {{ getDateFormat(s.item.createdAt) }}
                </template>

                <template #head(plan)>Plan</template>
                <template #cell(plan)="s">
                    {{ $t(`pages.pricing.plan-${s.item.plan}`) }}
                </template>

                <template #head(amount)>Amount</template>
                <template #cell(amount)="s">
                    {{ getPriceFormat(s.item.amountPaid ? s.item.amountPaid : s.item.amountDue) }}
                </template>

                <template #head(actions)>Billing</template>
                <template #cell(actions)="s">
                    <b-button v-if="s.item.hostedInvoiceUrl" class="ml-1" variant="text" target="_blank" :href="s.item.hostedInvoiceUrl">
                        <i class="fa fa-file-pdf-o" />
                    </b-button>
                    <!-- <b-button v-if="s.item.hostedInvoiceUrl" class="ml-1" variant="text" @click="buttonShowInvoice(s.item)">
                        <i class="fa fa-file-pdf-o" />
                    </b-button> -->
                </template>

                <template #head(data)>data</template>
                <template #cell(data)="s">{{ s.item.invoiceID }}</template>
            </b-table>
        </b-card>

        <b-modal id="modal-change-plan" size="xl" :title="$t('pages.payment.modal-change_plan.header')" hide-header-close>
            <b-card class="mb-3" no-body>
                <b-card-header>
                    <h5>
                        Current plan: {{ currentPlan.plan.text }}
                        <b>€ {{ currentPlan.plan.priceM ? currentPlan.plan.priceM.toFixed(2) : '-.--' }}</b>
                        / month
                    </h5>
                </b-card-header>
                <b-card-body>
                    <ul class="list-unstyled">
                        <li>
                            <b>{{ currentPlan.plan.maxUsers }}</b>
                            users included
                        </li>
                        <li>
                            <b>{{ currentPlan.plan.maxProjects }}</b>
                            projects included
                        </li>
                        <li>
                            <b>{{ currentPlan.plan.maxSites }}</b>
                            sites per project
                        </li>
                        <li>
                            (total:
                            <b>{{ currentPlan.plan.maxProjects * currentPlan.plan.maxSites }}</b>
                            sites)
                        </li>
                    </ul>
                    <p>End your current plan {{ currentPlan.periodEndAt ? ` on ${getDateFormat(currentPlan.periodEndAt)}` : ' now' }}</p>
                </b-card-body>
            </b-card>

            <b-card v-if="nextPlan" no-body class="mb-3">
                <b-card-header>
                    <h5>
                        New plan: {{ nextPlan.plan.text }}
                        <b>€ {{ nextPlan.plan.priceM ? nextPlan.plan.priceM.toFixed(2) : '-.--' }}</b>
                        / month
                    </h5>
                </b-card-header>
                <b-card-body>
                    <ul class="list-unstyled">
                        <li>
                            <b>{{ nextPlan.plan.maxUsers }}</b>
                            users included
                        </li>
                        <li>
                            <b>{{ nextPlan.plan.maxProjects }}</b>
                            projects included
                        </li>
                        <li>
                            <b>{{ nextPlan.plan.maxSites }}</b>
                            sites per project
                        </li>
                        <li>
                            (total:
                            <b>{{ nextPlan.plan.maxProjects * nextPlan.plan.maxSites }}</b>
                            sites)
                        </li>
                    </ul>
                    <p v-if="isPlanCanceled">Your plan will renew as you continue</p>
                    <p v-else>Start your new plan {{ nextPlan.periodStartAt ? ` on ${getDateFormat(nextPlan.periodStartAt)}` : ' now' }}</p>
                </b-card-body>
            </b-card>

            <template slot="modal-footer" slot-scope="{ cancel }">
                <b-button @click="cancel()">{{ $t('globals.button.cancel') }}</b-button>
                <b-button v-if="isPlanCanceled" variant="danger" @click="buttonConfirmPlan()">Renew</b-button>
                <b-button v-else-if="currentPlan.periodEndAt" variant="danger" @click="buttonConfirmPlan()">End the plan on {{ getDateFormat(currentPlan.periodEndAt) }}</b-button>
                <b-button v-else variant="danger" @click="buttonConfirmPlan()">{{ $t('pages.payment.modal-change_plan.button-confirm') }}</b-button>
                <!-- <b-button :disabled="processing" variant="danger" @click.prevent="cancelSubscription">
                    {{ $t('components.modal-subscription.button-cancel_subscription') }}
                    <i v-if="processing" class="fa fa-spinner fa-spin fa-fw ml-2" />
                </b-button> -->
            </template>
        </b-modal>

        <ModalSubscription ref="modalSubscription" @canceledSubscription="onCanceledSubscription" />
        <ModalPaymentMethod ref="modalPaymentMethod" @deletedPaymentMethod="onDeletedPaymentMethod" @defaultPaymentMethod="onDefaultPaymentMethod" />
    </div>
</template>

<script>
import _ from 'lodash';
import dayjs from 'dayjs';
import axios from 'axios';

import stripeLogo from '@/assets/images/stripe/stripe_slate.svg';
import ModalSubscription from '@/components/modalSubscription.vue';
import ModalPaymentMethod from '@/components/modalPaymentMethod.vue';

var stripe = Stripe(process.env.VUE_APP_STRIPE_PUBLISHKEY || '');

export default {
    components: {
        ModalSubscription,
        ModalPaymentMethod,
    },
    data() {
        const plans = this.$store.getters.getPlans().map((p) => {
            p.value = p.id;
            p.text = this.$t(`pages.pricing.plan-${p.id}`); // refresh i18n in the computed if it is different
            return p;
        });
        // console.debug('plans', plans);
        return {
            loading: false,
            plans,
            nextPlan: null,
            durations: [
                { value: 'month', text: this.$t('pages.payment.checkbox-month') },
                // { value: 'year', text: this.$t('pages.payment.checkbox-year') }
            ],
            form: {
                plan: null,
                duration: 'month', // null,
                amount: 0,
            },
            modalInvoice: {
                url: '',
            },

            defaultPaymentMethodID: null,
            paymentMethods: [],
            loadingPaymentMethods: false,
            errorPaymentMethods: null,
            curPagePaymentMethods: 1,
            fieldsPaymentMethods: [
                { key: 'createdAt', sortable: true, sortDirection: 'asc' },
                { key: 'card', sortable: true, sortDirection: 'asc' },
                { key: 'actions', thStyle: 'width: 9rem' },
                { key: 'data' },
            ],

            invoices: [],
            loadingInvoices: false,
            errorInvoices: null,
            curPageInvoices: 1,
            fieldsInvoices: [
                { key: 'createdAt', sortable: true, sortDirection: 'asc' },
                { key: 'status', sortable: true, sortDirection: 'asc' },
                { key: 'plan' },
                { key: 'amount' },
                { key: 'actions', thStyle: 'width: 9rem' },
                // { key: 'createdAt', sortable: true, sortDirection: 'asc' },
                // { key: 'status', sortable: true, sortDirection: 'asc' }
                { key: 'data' }
            ],

            subscriptions: [],
            loadingSubscriptions: false,
            errorSubscriptions: null,
            curPageSubscriptions: 1,
            fieldsSubscriptions: [
                { key: 'createdAt', sortable: true, sortDirection: 'asc' },
                { key: 'status', sortable: true, sortDirection: 'asc' },
                { key: 'plan' },
                { key: 'currentPeriodEndAt' },
                { key: 'actions', thStyle: 'width: 9rem' },
                // { key: 'createdAt', sortable: true, sortDirection: 'asc' },
                // { key: 'status', sortable: true, sortDirection: 'asc' }
                { key: 'data' }
            ],
            stripeLogo,
        };
    },
    computed: {
        currentPlan() {
            return {
                plan: _.find(this.plans, { id: this.$store.getters.getClientPlanID() }) || {},
                nextPlan: this.$store.getters.getClientNextPlanID(),
                validUntil: this.$store.getters.getClientValidUntil(),
                limitUsers: this.$store.getters.getClientMax('users'),
                limitProjects: this.$store.getters.getClientMax('projects'),
                limitSites: this.$store.getters.getClientMax('sites'),
            };
        },
        isRequireVerifyMail() {
            return this.$store.getters.isMeRequireVerify();
        },
        isNoLongerValid() {
            return !this.$store.getters.isClientValidUntil();
        },
        isPlanCanceled() {
            return this.$store.getters.isClientPlanCanceled();
        },
        getCurrentValidUntil() {
            return this.currentPlan.validUntil ? dayjs(this.currentPlan.validUntil).format('YYYY.MM.DD HH:mm') : '-';
        },

        getPlanSelected() {
            return _.find(this.plans, { value: this.form.plan }) || {};
        },
        isPlanDisabled() {
            return this.getPlanSelected.disabled;
        },
        getPlanPrice() {
            return _.get(this.getPlanSelected, this.form.duration === 'year' ? 'priceA' : this.form.duration === 'month' ? 'priceM' : 'false', false);
        },
        getPlanValidUntil() {
            if (this.form.duration === 'year') {
                return dayjs().add(1, 'year').format('YYYY.MM.DD HH:mm');
            }
            if (this.form.duration === 'month') {
                return dayjs().add(30, 'day').format('YYYY.MM.DD HH:mm');
            }
            return '-';
        },
        // async buttonCancelSubsriptions(subscription) {
        //     const form = { ...subscription };
        //     form.cancel = !form.disabledAt;

        //     subscription._processing = true;
        //     try {
        //         const response = axios.put(`/api/`);
        //     } catch (error) {
        //         this.$bvToast.toast(error.toString(), {
        //             title: this.$t('globals.toast.error'),
        //             variant: 'danger',
        //             solid: true
        //         });
        //     }
        //     subscription._processing = false;
        // },
        filteredInvoices() {
            // const search = t.replaceSearch(this.search);
            // return this.projects.filter(p => `${p.projectName || ''}\t${p.sitemap || ''}`.match(search));
            return [...this.invoices];
        },
        filteredSubscriptions() {
            // const search = t.replaceSearch(this.search);
            // return this.projects.filter(p => `${p.projectName || ''}\t${p.sitemap || ''}`.match(search));
            return [...this.subscriptions];
        },
        filteredPaymentMethods() {
            // const search = t.replaceSearch(this.search);
            // return this.projects.filter(p => `${p.projectName || ''}\t${p.sitemap || ''}`.match(search));
            return [...this.paymentMethods];
        },
    },
    async created() {
        if (this.$route.query.plan && this.$route.query.plan !== 'free') {
            this.form.plan = this.$route.query.plan;
        }
        if (this.$route.query.duration) {
            this.form.duration = this.$route.query.duration;
        }
        if (_.has(this.$route.query, 'canceled')) {
            this.$bvToast.toast(this.$t('pages.payment.tooltip-canceled'), {
                title: this.$t('globals.toast.warning'),
                variant: 'warning',
                solid: true,
            });
        }
        if (this.$route.fullPath !== '/plan') {
            this.$router.replace({ path: '/plan' });
        }

        const planIndex = _.findIndex(this.plans, { id: this.currentPlan.plan.id });
        _.each(this.plans, (p, index) => {
            p.index = index;
            p.show = p.priceM > 0;
        });

        await Promise.all([
            (async () => {
                this.loadingInvoices = true;
                try {
                    this.invoices = (await axios.get('/api/stripe/invoices')).data.invoices;
                    this.loadingInvoices = false;
                } catch (error) {
                    this.loadingInvoices = false;
                    this.errorInvoices = error;
                    console.error(error);
                }
            })(),
            (async () => {
                this.loadingSubscriptions = true;
                try {
                    this.subscriptions = (await axios.get('/api/stripe/subscriptions')).data.subscriptions;
                    this.loadingSubscriptions = false;
                } catch (error) {
                    this.loadingSubscriptions = false;
                    this.errorSubscriptions = error;
                    console.error(error);
                }
            })(),
            (async () => {
                this.loadingPaymentMethods = true;
                try {
                    const result = (await axios.get('/api/stripe/paymentmethods')).data;
                    this.paymentMethods = result.paymentMethods;
                    this.defaultPaymentMethodID = result.defaultPaymentMethodID;
                    this.loadingPaymentMethods = false;
                } catch (error) {
                    this.loadingPaymentMethods = false;
                    this.errorPaymentMethods = error;
                    console.error(error);
                }
            })(),
        ]);
    },
    methods: {
        isExpiredCard(paymentMethod) {
            const expired = new Date(`${paymentMethod?.card?.exp_year || 2000}-${paymentMethod?.card?.exp_month || 1}-01`);
            return Date.now() >= +expired;
        },

        getPlangrade(plan) {
            const index = _.findIndex(this.plans, { id: this.currentPlan.plan.id });
            const planIndex = _.findIndex(this.plans, { id: plan });
            return planIndex < index ? 'downgrade' : planIndex > index ? 'upgrade' : index === planIndex ? 'current' : null;
        },

        getDateFormat: (date) => dayjs(date).format('YYYY.MM.DD HH:mm'),
        getPriceFormat: (price) => `€${(+price).toFixed(2)}`.replace('€-', '-€'),
        onCanceledSubscription(subscription) {
            const index = _.findIndex(this.subscriptions, { subscriptionID: subscription.subscriptionID });
            console.log('1', JSON.stringify(this.subscriptions[index]));
            this.$set(this.subscriptions, index, { ...this.subscriptions[index], ...subscription });

            console.log('2', JSON.stringify(this.subscriptions[index]));
            console.log('TODO');
            // if (subscription.canceledAt) {
            //     const index = _.findIndex(this.subscriptions, { subscriptionID: subscription.subscriptionID });
            //     // this.$set(this.subscriptions, index, { ...this.subscriptions[index], status: 'canceled' });
            //     this.$delete(this.subscriptions, index);
            // }
        },

        onDefaultPaymentMethod(defaultPaymentMethodID) {
            this.defaultPaymentMethodID = defaultPaymentMethodID;
            // const index = _.findIndex(this.paymentMethods, { paymentMethodID: paymentMethod.paymentMethodID });
            // // this.$delete(this.paymentMethods, index);
            // console.log('TODO', index, this.paymentMethods);
        },

        onDeletedPaymentMethod(paymentMethod) {
            const index = _.findIndex(this.paymentMethods, { paymentMethodID: paymentMethod.paymentMethodID });
            this.$delete(this.paymentMethods, index);
            console.log('TODO', this.paymentMethods);
        },

        buttonChangePlan(planID) {
            if (planID === this.currentPlan.plan.id && !this.isPlanCanceled) {
                planID = 'free';
            }

            const newPlan = _.find(this.plans, { id: planID });
            console.debug('newPlan', newPlan);
            if (newPlan) {
                this.nextPlan = {
                    plan: { ...newPlan },
                };
                this.currentPlan.periodEndAt = null;

                if (planID === 'free') {
                    this.nextPlan = null;
                    this.currentPlan.periodEndAt = this.currentPlan.validUntil && new Date(this.currentPlan.validUntil);
                } else if (planID === this.currentPlan.plan.id) {
                    this.currentPlan.periodEndAt = this.currentPlan.validUntil && new Date(this.currentPlan.validUntil);
                }

                this.$bvModal.show('modal-change-plan');
            } else {
                console.log('not found');
            }
        },

        async buttonConfirmPlan() {
            // await this.buttonCheckout();
            // this.$bvModal.hide('modal-change-plan');

            try {
                // console.log('this.nextPlan', this.nextPlan);
                if (this.nextPlan === null) {
                    const result = await axios.post(`/api/stripe/cancel`);
                    if (!result.data.success) {
                        console.log('stripe-uknown-result', result);
                        this.$bvToast.toast('stripe-uknown-result', {
                            title: this.$t('globals.toast.warning'),
                            variant: 'warning',
                            solid: true,
                        });
                        return;
                    }

                    this.$store.dispatch('commitMe', { client: result.data.client });
                    this.$bvToast.toast(this.$t('pages.payment.tooltip-canceled'), {
                        title: this.$t('globals.toast.success'),
                        variant: 'success',
                        solid: true,
                    });
                    this.$bvModal.hide('modal-change-plan');
                } else {
                    const result = await axios.post(`/api/stripe/change`, this.nextPlan);

                    console.log('result', result);

                    if (result.data?.session?.id) {
                        const checkout = await stripe.redirectToCheckout({ sessionId: result.data.session.id });
                        console.log('stripe-uknown-result', checkout);
                        this.$bvToast.toast('stripe-uknown-result', {
                            title: this.$t('globals.toast.warning'),
                            variant: 'warning',
                            solid: true,
                        });
                        return;
                    }

                    this.$store.dispatch('commitMe', { client: result.data.client });
                    this.$bvToast.toast(this.$t('pages.payment.tooltip-changed'), {
                        title: this.$t('globals.toast.success'),
                        variant: 'success',
                        solid: true,
                    });
                    this.$bvModal.hide('modal-change-plan');
                }
            } catch (error) {
                console.error('error', error);
                this.$bvToast.toast(error.toString(), {
                    title: this.$t('globals.toast.error'),
                    variant: 'danger',
                    solid: true,
                });
            }
        },

        async buttonCheckout() {
            try {
                this.form.amount = _.floor(this.getPlanPrice * 100);
                if (this.form.amount <= 0) {
                    return;
                }

                // TODO: axios from api: publishableKey, (and maybe startPriceId, profPriceId, fullPriceId or into server)
                const resp = await axios.post(`/api/stripe/checkout`, this.form);

                console.log('stripe-session-resp', resp);
                if (!_.get(resp.data, 'session.id')) {
                    this.$bvToast.toast('stripe-no-session', {
                        title: this.$t('globals.toast.warning'),
                        variant: 'warning',
                        solid: true,
                    });
                }

                // var stripe = Stripe(_.get(resp.data, 'publicKey') || process.env.VUE_APP_STRIPE_PUBLISHKEY || '');
                return stripe.redirectToCheckout({ sessionId: resp.data.session.id }).then((result) => {
                    console.log('stripe-uknown-result', result);
                    this.$bvToast.toast('stripe-uknown-result', {
                        title: this.$t('globals.toast.warning'),
                        variant: 'warning',
                        solid: true,
                    });
                });
            } catch (error) {
                console.log('error', error);
                this.$bvToast.toast(error.toString(), {
                    title: this.$t('globals.toast.error'),
                    variant: 'danger',
                    solid: true,
                });
            }
        },
        // buttonShowInvoice(subscription) {
        //     this.modalInvoice.url = subscription.hostedInvoiceUrl;
        //     this.$bvModal.show('modal-show-invoice');
        // }
    },
};
</script>
