<template>
    <div>
        <b-modal id="modal-add-project" :title="$t('components.modal-project.header-add_project')" size="lg" hide-header-close>
            <b-form-group :label="$t('components.modal-project.label-project')" label-for="project-name-input">
                <b-form-input id="project-name-input" v-model="form.projectName" trim :state="validationProjectNameComputed" />
                <b-form-invalid-feedback :state="validationProjectNameComputed">{{ $t('components.modal-project.validation-project') }}</b-form-invalid-feedback>
            </b-form-group>
            <b-form-group :label="$t('components.modal-project.label-crawler')" label-for="crawler-input">
                <b-form-select id="crawler-input" v-model="form.crawler" :options="crawlers" :state="validationCrawlerComputed" />
                <b-form-invalid-feedback :state="validationCrawlerComputed">{{ $t('components.modal-project.validation-crawler') }}</b-form-invalid-feedback>
            </b-form-group>
            <b-form-group :label="$t('components.modal-project.label-sitemap')" label-for="sitemap-input">
                <b-form-input id="sitemap-input" v-model="form.sitemap" trim :state="validationSitemapComputed" @blur="blurSitemap" />
                <b-form-invalid-feedback :state="validationSitemapComputed">{{ $t('components.modal-project.validation-sitemap') }}</b-form-invalid-feedback>
            </b-form-group>

            <template slot="modal-footer" slot-scope="{ cancel }">
                <b-button @click="cancel()">{{ $t('globals.button.cancel') }}</b-button>
                <b-button :disabled="processing" variant="info" @click="addProject">
                    {{ $t('globals.button.add') }}
                    <i v-if="processing" class="fa fa-spinner fa-spin fa-fw ml-2" />
                </b-button>
            </template>
        </b-modal>

        <b-modal id="modal-edit-project" :title="$t('components.modal-project.header-edit_project')" size="lg" hide-header-close>
            <b-form-group :label="$t('components.modal-project.label-project')" label-for="project-name-input">
                <b-form-input id="project-name-input" v-model="form.projectName" trim :state="validationProjectNameComputed" />
                <b-form-invalid-feedback :state="validationProjectNameComputed">{{ $t('components.modal-project.validation-project') }}</b-form-invalid-feedback>
            </b-form-group>
            <b-form-group :label="$t('components.modal-project.label-crawler')" label-for="crawler-input">
                <b-form-select id="crawler-input" v-model="form.crawler" :options="crawlers" :state="validationCrawlerComputed" />
                <b-form-invalid-feedback :state="validationCrawlerComputed">{{ $t('components.modal-project.validation-crawler') }}</b-form-invalid-feedback>
            </b-form-group>
            <b-form-group :label="$t('components.modal-project.label-sitemap')" label-for="sitemap-input">
                <b-form-input id="sitemap-input" v-model="form.sitemap" trim :state="validationSitemapComputed" />
                <b-form-invalid-feedback :state="validationSitemapComputed">{{ $t('components.modal-project.validation-sitemap') }}</b-form-invalid-feedback>
            </b-form-group>

            <template slot="modal-footer" slot-scope="{ cancel }">
                <b-button @click="cancel()">{{ $t('globals.button.cancel') }}</b-button>
                <b-button :disabled="processing" variant="info" @click="editProject">
                    {{ $t('globals.button.save') }}
                    <i v-if="processing" class="fa fa-spinner fa-spin fa-fw ml-2" />
                </b-button>
            </template>
        </b-modal>

        <b-modal id="modal-delete-project" :title="$t('globals.modal.warning')" hide-header-close>
            <p class="my-4" v-html="$t('components.modal-project.delete-description', { projectName: `<b class=&quot;text-info&quot;>${form.projectName}</b>` })" />

            <template slot="modal-footer" slot-scope="{ cancel }">
                <b-button @click="cancel()">{{ $t('globals.button.cancel') }}</b-button>
                <b-button :disabled="processing" variant="danger" @click="deleteProject">
                    {{ $t('globals.button.delete') }}
                    <i v-if="processing" class="fa fa-spinner fa-spin fa-fw ml-2" />
                </b-button>
            </template>
        </b-modal>

        <b-modal id="modal-sitemap" :title="$t('globals.modal.warning')" hide-header-close>
            <p
                class="my-4"
                v-html="
                    $t('components.modal-project.redirect-description1', {
                        sitemap: `<b class=&quot;text-info&quot;>${form.sitemap}</b>`,
                        redirect: `<b class=&quot;text-info&quot;>${form._sitemap}</b>`
                    })
                "
            />
            <p class="small mt-2">{{ $t('components.modal-project.redirect-description2') }}</p>

            <template slot="modal-footer" slot-scope="{ cancel }">
                <b-button @click="cancel()">{{ $t('globals.button.cancel') }}</b-button>
                <b-button :disabled="processing" variant="danger" @click="setSitemap">
                    {{ $t('components.modal-project.button-redirect-change') }}
                    <i v-if="processing" class="fa fa-spinner fa-spin fa-fw ml-2" />
                </b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
// import t from '@/tool';

export default {
    data() {
        return {
            processing: false,
            check: false,
            start: false,
            crawlers: {
                website: 'Website HTML',
                sitemap: 'Sitemap XML'
            },
            form: {
                projectName: '',
                crawler: '',
                sitemap: 'https://'
            }
        };
    },
    computed: {
        validationProjectNameComputed() {
            return this.validationProjectName();
        },
        validationSitemapComputed() {
            return this.validationSitemap();
        },
        validationCrawlerComputed() {
            return this.validationCrawler();
        },
        validationSaveComputed() {
            return this.validationSave();
        }
    },
    methods: {
        validationCrawler() {
            return _.has(this.crawlers, this.form.crawler);
        },
        validationProjectName() {
            const len = _.size(_.get(this, 'form.projectName', ''));
            return 5 <= len && len <= 100;
        },
        validationSitemap() {
            // const len = _.size(_.get(this, 'form.sitemap', ''));
            // return 10 <= len &&
            return /^https?:\/\/([a-z0-9-]+\.)+[a-z0-9-]{2,5}(\/.*|$)/.test(_.get(this, 'form.sitemap', ''));
        },
        validationSave() {
            // console.log(this.form);
            return this.validationProjectName() && this.validationSitemap() && this.validationCrawler();
        },

        blurSitemap() {
            if (!this.validationCrawler() && this.validationSitemap()) {
                if (/\.xml$/i.test(_.get(this, 'form.sitemap', ''))) {
                    this.form.crawler = 'sitemap';
                } else {
                    this.form.crawler = 'website';
                }
            }
        },

        buttonAddProject({ project, check, start }) {
            this.form = {
                projectID: null,
                projectName: _.get(project, 'projectName', ''),
                crawler: _.get(project, 'crawler', ''),
                sitemap: _.get(project, 'sitemap', 'https://')
            };
            this.check = !!check;
            this.start = !!start;
            this.$bvModal.show('modal-add-project');
        },
        addProject(bvModalEvt) {
            bvModalEvt.preventDefault();

            if (!this.validationSave()) {
                return;
            }

            this.processing = true;
            axios
                .post(`/api/project`, { form: this.form, check: this.check, start: this.start })
                .then(resp => {
                    this.processing = false;
                    if (!_.get(resp, 'data.success')) {
                        let message = _.get(resp, 'data.msg', 'unknown');
                        if ((message === 'http_301' || message === 'http_302') && resp.data.location) {
                            this.$set(this.form, '_sitemap', resp.data.location.trim());
                            return this.$bvModal.show('modal-sitemap');
                        }
                        return this.$bvToast.toast(this.$t(`pages.sites.net-${message}`, { url: resp.data.url }), {
                            title: this.$t('globals.toast.warning'),
                            variant: 'warning',
                            solid: true
                        });
                    }
                    this.$bvModal.hide('modal-add-project');
                    this.$emit('addedProject', resp.data.project);
                })
                .catch(e => {
                    this.processing = false;
                    this.$bvToast.toast(e.toString(), {
                        title: this.$t('globals.toast.error'),
                        variant: 'danger',
                        solid: true
                    });
                });
        },

        buttonEditProject({ project, check, start }) {
            this.form = { ...project };
            this.check = !!check;
            this.start = !!start;
            this.$bvModal.show('modal-edit-project');
        },
        editProject(bvModalEvt) {
            bvModalEvt.preventDefault();

            if (!this.validationSave()) {
                return;
            }

            this.processing = true;
            axios
                .put(`/api/project/${this.form.projectID}`, { form: this.form, check: this.check, start: this.start })
                .then(resp => {
                    this.processing = false;
                    if (!_.get(resp, 'data.success')) {
                        let message = _.get(resp, 'data.msg', 'unknown');
                        if ((message === 'http_301' || message === 'http_302') && resp.data.location) {
                            this.$set(this.form, '_sitemap', resp.data.location.trim());
                            return this.$bvModal.show('modal-sitemap');
                        }
                        return this.$bvToast.toast(this.$t(`pages.sites.net-${message}`, { url: resp.data.url }), {
                            title: this.$t('globals.toast.warning'),
                            variant: 'warning',
                            solid: true
                        });
                    }
                    this.$bvModal.hide('modal-edit-project');
                    this.$emit('editedProject', resp.data.project);
                })
                .catch(e => {
                    this.processing = false;
                    this.$bvToast.toast(e.toString(), {
                        title: this.$t('globals.toast.error'),
                        variant: 'danger',
                        solid: true
                    });
                });
        },

        setSitemap(bvModalEvt) {
            this.form.sitemap = this.form._sitemap;
            this.$bvModal.hide('modal-sitemap');
            if (this.form.projectID) {
                this.editProject(bvModalEvt);
            } else {
                this.addProject(bvModalEvt);
            }
        },

        buttonDeleteProject({ project }) {
            this.form = { ...project };
            this.$bvModal.show('modal-delete-project');
        },
        deleteProject(bvModalEvt) {
            bvModalEvt.preventDefault();

            if (!_.get(this, 'form.projectID')) {
                return;
            }
            this.processing = true;
            axios
                .delete(`/api/project/${this.form.projectID}`)
                .then(resp => {
                    this.processing = false;
                    this.$bvModal.hide('modal-delete-project');
                    this.$emit('deletedProject', resp.data.project);
                })
                .catch(e => {
                    this.processing = false;
                    this.$bvToast.toast(e.toString(), {
                        title: this.$t('globals.toast.error'),
                        variant: 'danger',
                        solid: true
                    });
                });
        }
    }
};
</script>
