<template>
    <div>
        <b-modal id="modal-add-user" :title="$t('components.modal-user.header-add_user')" size="lg" hide-header-close>
            <b-form-group :label="$t('components.modal-user.label-email')" label-for="email-input">
                <b-form-input id="email-input" v-model="form.email" trim :state="isValidateEmail" />
                <b-form-invalid-feedback :state="isValidateEmail">{{ $t('components.modal-user.validation-email') }}</b-form-invalid-feedback>
            </b-form-group>

            <b-form-group :label="$t('components.modal-user.label-password')" label-for="password-input">
                <b-form-input id="password-input" v-model="form.password" type="password" :state="isValidatePassword" />
                <b-form-invalid-feedback :state="isValidatePassword">{{ $t('components.modal-user.validation-password') }}</b-form-invalid-feedback>
            </b-form-group>

            <b-form-group :label="$t('components.modal-user.label-password2')" label-for="password2-input">
                <b-form-input id="password2-input" v-model="form.password2" type="password" :state="isValidatePassword2" />
                <b-form-invalid-feedback :state="isValidatePassword2">{{ $t('components.modal-user.validation-password2') }}</b-form-invalid-feedback>
            </b-form-group>

            <b-form-group :label="$t('components.modal-user.label-role')" label-for="role-input">
                <b-form-select id="role-input" v-model="form.role" :options="roles" :state="isValidateRole" />
                <b-form-invalid-feedback :state="isValidateRole">{{ $t('components.modal-user.validation-role') }}</b-form-invalid-feedback>
            </b-form-group>

            <b-form-group v-if="$store.getters.isMeRole(['superadmin'])" label-for="role-requireVerify">
                <b-form-checkbox id="role-requireVerify" v-model="form.requireVerify">
                    {{ $t('components.modal-user.label-require_verify') }}
                    <span v-if="form.requireVerify" class="fa-stack pl-2">
                        <i class="fa fa-envelope fa-stack-1x" />
                        <i class="fa fa-ban fa-stack-2x text-danger" />
                    </span>
                </b-form-checkbox>
            </b-form-group>

            <b-form-group v-if="$store.getters.isMeRole(['superadmin'])" label-for="role-developer">
                <b-form-checkbox id="role-developer" v-model="form.developer">
                    {{ $t('components.modal-user.label-developer') }}
                    <i v-if="form.developer" class="fa fa-user-md text-info" />
                </b-form-checkbox>
            </b-form-group>

            <template slot="modal-footer" slot-scope="{ cancel }">
                <b-button @click="cancel()">{{ $t('globals.button.cancel') }}</b-button>
                <b-button :disabled="processing || !isValidateSave" variant="info" @click.prevent="addUser">
                    {{ $t('globals.button.add') }}
                    <i v-if="processing" class="fa fa-spinner fa-spin fa-fw ml-2" />
                </b-button>
            </template>
        </b-modal>

        <b-modal id="modal-edit-user" :title="$t('components.modal-user.header-edit_user')" size="lg" hide-header-close>
            <b-form-group :label="$t('components.modal-user.label-email')" label-for="email-input">
                <b-form-input id="email-input" v-model="form.email" trim :state="isValidateEmail" />
                <b-form-invalid-feedback :state="isValidateEmail">{{ $t('components.modal-user.validation-email') }}</b-form-invalid-feedback>
            </b-form-group>

            <b-form-group :label="$t('components.modal-user.label-password')" label-for="password-input">
                <b-form-input id="password-input" v-model="form.password" type="password" :state="(form.password || form.password2) && isValidatePassword" />
                <b-form-invalid-feedback :state="(form.password || form.password2) && isValidatePassword">{{ $t('components.modal-user.validation-password') }}</b-form-invalid-feedback>
            </b-form-group>

            <b-form-group :label="$t('components.modal-user.label-password2')" label-for="password2-input">
                <b-form-input id="password2-input" v-model="form.password2" type="password" :state="(form.password || form.password2) && isValidatePassword2" />
                <b-form-invalid-feedback :state="(form.password || form.password2) && isValidatePassword2">{{ $t('components.modal-user.validation-password2') }}</b-form-invalid-feedback>
            </b-form-group>

            <b-form-group :label="$t('components.modal-user.label-role')" label-for="role-input">
                <b-form-select id="role-input" v-model="form.role" :options="roles" :state="isValidateRole" />
                <b-form-invalid-feedback :state="isValidateRole">{{ $t('components.modal-user.validation-role') }}</b-form-invalid-feedback>
            </b-form-group>

            <b-form-group v-if="$store.getters.isMeRole(['superadmin'])" label-for="role-requireVerify">
                <b-form-checkbox id="role-requireVerify" v-model="form.requireVerify">
                    {{ $t('components.modal-user.label-require_verify') }}
                    <span v-if="form.requireVerify" class="fa-stack pl-2">
                        <i class="fa fa-envelope fa-stack-1x" />
                        <i class="fa fa-ban fa-stack-2x text-danger" />
                    </span>
                </b-form-checkbox>
            </b-form-group>

            <b-form-group v-if="$store.getters.isMeRole(['superadmin'])" label-for="role-developer">
                <b-form-checkbox id="role-developer" v-model="form.developer">
                    {{ $t('components.modal-user.label-developer') }}
                    <i v-if="form.developer" class="fa fa-user-md text-info" />
                </b-form-checkbox>
            </b-form-group>

            <template slot="modal-footer" slot-scope="{ cancel }">
                <b-button @click="cancel()">{{ $t('globals.button.cancel') }}</b-button>
                <b-button :disabled="processing || !isValidateEdit" variant="info" @click.prevent="editUser">
                    {{ $t('globals.button.save') }}
                    <i v-if="processing" class="fa fa-spinner fa-spin fa-fw ml-2" />
                </b-button>
            </template>
        </b-modal>

        <b-modal id="modal-delete-user" :title="$t('globals.modal.warning')" hide-header-close>
            <p class="my-4" v-html="$t('components.modal-user.delete-description', { email: `<b class=&quot;text-info&quot;>${form.email}</b>` })" />

            <template slot="modal-footer" slot-scope="{ cancel }">
                <b-button @click="cancel()">{{ $t('globals.button.cancel') }}</b-button>
                <b-button :disabled="processing" variant="danger" @click.prevent="deleteUser">
                    {{ $t('globals.button.delete') }}
                    <i v-if="processing" class="fa fa-spinner fa-spin fa-fw ml-2" />
                </b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import t from '@/tool';

export default {
    props: {
        client: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            processing: false,
            roles: {
                // TODO: refresh if change i18n
                admin: this.$t('globals.role.admin'),
                user: this.$t('globals.role.user'),
                guest: this.$t('globals.role.guest')
            },
            form: {
                email: '',
                password: '',
                password2: '',
                role: '',
                requireVerify: false,
                developer: false
            }
        };
    },
    computed: {
        isValidateEmail() {
            const email = _.get(this, 'form.email', '');
            const len = _.size(email);
            return 5 <= len && len <= 30 && t.validationEmail(email);
        },
        isValidatePassword() {
            const len = _.size(_.get(this, 'form.password', ''));
            return 8 <= len && len <= 20;
        },
        isValidatePassword2() {
            return this.isValidatePassword && _.get(this, 'form.password2', '') === _.get(this, 'form.password', '');
        },
        isValidateRole() {
            return _.has(this.roles, this.form.role);
        },
        isValidateSave() {
            return this.isValidateEmail && this.isValidatePassword && this.isValidateRole && this.isValidatePassword2;
        },
        isValidateEdit() {
            return (
                _.get(this, 'form.userID') &&
                this.isValidateEmail &&
                this.isValidateRole &&
                ((_.get(this, 'form.password', '') === '' && _.get(this, 'form.password2', '') === '') || (this.isValidatePassword && this.isValidatePassword2))
            );
        }
    },
    methods: {
        buttonAddUser() {
            this.form = {
                userID: null,
                email: '',
                password: '',
                password2: '',
                role: '',
                requireVerify: true,
                developer: false
            };
            this.$bvModal.show('modal-add-user');
        },
        addUser() {
            if (!this.isValidateSave) {
                return;
            }

            this.processing = true;
            axios
                .post(`/api${this.client ? `/superadmin/client/${this.client}` : ''}/user`, this.form)
                .then(resp => {
                    this.processing = false;
                    if (!_.get(resp, 'data.success')) {
                        let message = _.get(resp, 'data.msg', 'user_unknown');
                        return this.$bvToast.toast(this.$t(`components.modal-user.net-${message}`, { email: resp.data.email }), {
                            title: this.$t('globals.toast.warning'),
                            variant: 'warning',
                            solid: true
                        });
                    }
                    this.$bvModal.hide('modal-add-user');
                    this.$emit('addedUser', resp.data.user);
                })
                .catch(e => {
                    this.processing = false;
                    this.$bvToast.toast(e.toString(), {
                        title: this.$t('globals.toast.error'),
                        variant: 'danger',
                        solid: true
                    });
                });
        },

        buttonDisableUser(user) {
            const form = { ...user };
            form.disabledAt = !form.disabledAt;

            user._processing = true;
            axios
                .put(`/api${this.client ? `/superadmin/client/${this.client}` : ''}/user/${form.userID}`, form)
                .then(resp => {
                    user._processing = false;
                    this.$emit('editedUser', resp.data.user);
                })
                .catch(e => {
                    user._processing = false;
                    this.$bvToast.toast(e.toString(), {
                        title: this.$t('globals.toast.error'),
                        variant: 'danger',
                        solid: true
                    });
                });
        },

        buttonEditUser(user) {
            this.form = { ...user, ...{ password: '', password2: '', requireVerify: !!_.get(user, 'meta.requireVerify'), developer: !!_.get(user, 'meta.developer') } };
            this.$bvModal.show('modal-edit-user');
        },
        editUser() {
            if (!this.isValidateEdit) {
                return;
            }
            this.processing = true;
            axios
                .put(`/api${this.client ? `/superadmin/client/${this.client}` : ''}/user/${this.form.userID}`, this.form)
                .then(resp => {
                    this.processing = false;
                    if (!_.get(resp, 'data.success')) {
                        return this.$bvToast.toast(_.get(resp, 'data.msg', 'user_unknown'), {
                            title: this.$t('globals.toast.warning'),
                            variant: 'warning',
                            solid: true
                        });
                    }
                    this.$bvModal.hide('modal-edit-user');
                    this.$emit('editedUser', resp.data.user);
                })
                .catch(e => {
                    this.processing = false;
                    this.$bvToast.toast(e.toString(), {
                        title: this.$t('globals.toast.error'),
                        variant: 'danger',
                        solid: true
                    });
                });
        },

        buttonDeleteUser(user) {
            this.form = { ...user };
            this.$bvModal.show('modal-delete-user');
        },
        deleteUser() {
            if (!_.get(this, 'form.userID')) {
                return;
            }
            this.processing = true;
            axios
                .delete(`/api${this.client ? `/superadmin/client/${this.client}` : ''}/user/${this.form.userID}`)
                .then(resp => {
                    this.processing = false;
                    this.$bvModal.hide('modal-delete-user');
                    this.$emit('deletedUser', resp.data.user);
                })
                .catch(e => {
                    this.processing = false;
                    this.$bvToast.toast(e.toString(), {
                        title: this.$t('globals.toast.error'),
                        variant: 'danger',
                        solid: true
                    });
                });
        }
    }
};
</script>
