import _ from 'lodash';
import axios from 'axios';
import router from './router';
import store from './store';

axios.interceptors.response.use(
    response => {
        if (_.get(response, 'headers.x-oggy-token')) {
            store.commit('setToken', response.headers['x-oggy-token']);
        }
        return response;
    },
    error => {
        if (error.response.status === 401) {
            router.push({ path: '/logout', query: { token: 'expired' } });
        }
        return Promise.reject(error);
    }
);
